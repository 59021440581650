<ng-container *ngIf="editor && contentControl">
    <div
        *ngIf="toolbarPosition === 'TOP'"
        class="editor-toolbar topbar"
        [style.top.px]="stickyTop || 0"
    >
        <ng-container *ngTemplateOutlet="headerActions"></ng-container>
    </div>
    <tiptap-editor
        class="tt-editor"
        [editor]="editor"
        [formControl]="contentControl"
    ></tiptap-editor>

    <ng-content select="[footerContent]"></ng-content>

    <tiptap-bubble-menu
        *ngIf="toolbarPosition === 'BUBBLE'"
        [editor]="editor"
        class="editor-floating border br3 fac"
    >
        <ng-container *ngTemplateOutlet="headerActions"></ng-container>
    </tiptap-bubble-menu>

    <app-spinner *ngIf="isLoading" class="editor-image-loading"></app-spinner>

    <ng-template #headerActions>
        <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'SIMPLE'">
                <ng-container *ngTemplateOutlet="simpleFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container
                    *ngTemplateOutlet="hyperlinkFormats"
                ></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'COMMENT'">
                <ng-container *ngTemplateOutlet="simpleFormats"></ng-container>
                <ng-container *ngTemplateOutlet="codeFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="listFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container
                    *ngTemplateOutlet="hyperlinkFormats"
                ></ng-container>
                <ng-container *ngTemplateOutlet="imageFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="colorsFormats"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'HUBSPOT'">
                <div
                    class="btn-action menu-toggle"
                    mat-button
                    #tHeadersMenuItem="matMenuTrigger"
                    [matMenuTriggerFor]="headersMenuItem"
                >
                    {{
                        editor.isActive("heading")
                            ? "Heading " +
                              editor.getAttributes("heading")["level"]
                            : "Text"
                    }}
                </div>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="simpleFormats"></ng-container>
                <ng-container *ngTemplateOutlet="codeFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="listFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="imageFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="colorsFormats"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div
                    class="btn-action menu-toggle"
                    mat-button
                    #tHeadersMenuItem="matMenuTrigger"
                    [matMenuTriggerFor]="headersMenuItem"
                >
                    {{
                        editor.isActive("heading")
                            ? "Heading " +
                              editor.getAttributes("heading")["level"]
                            : "Text"
                    }}
                </div>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="simpleFormats"></ng-container>
                <ng-container *ngTemplateOutlet="codeFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="listFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container
                    *ngTemplateOutlet="hyperlinkFormats"
                ></ng-container>
                <ng-container *ngTemplateOutlet="imageFormats"></ng-container>
                <div class="action-separator"></div>
                <ng-container *ngTemplateOutlet="colorsFormats"></ng-container>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #simpleFormats>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('bold')"
            (click)="editor.chain().focus().toggleBold().run()"
        >
            <i class="icon-bold"></i>
        </div>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('italic')"
            (click)="editor.chain().focus().toggleItalic().run()"
        >
            <i class="icon-italic"></i>
        </div>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('underline')"
            (click)="editor.chain().focus().toggleUnderline().run()"
        >
            <i class="icon-underline"></i>
        </div>
    </ng-template>

    <ng-template #imageFormats>
        <div
            *ngIf="withImageUpload"
            class="btn-action btn-action-icon"
            mat-button
            #tImageMenuItem="matMenuTrigger"
            [matMenuTriggerFor]="imageMenuItem"
        >
            <i class="icon-image"></i>
        </div>
    </ng-template>

    <ng-template #codeFormats>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('code')"
            (click)="editor.chain().focus().toggleCode().run()"
        >
            <i class="iconhx8-code-inline"></i>
        </div>
    </ng-template>

    <ng-template #colorsFormats>
        <div
            class="btn-action menu-toggle"
            mat-button
            #tColorsMenuItem="matMenuTrigger"
            [matMenuTriggerFor]="colorsMenuItem"
        >
            <span
                class="bold fs6"
                [style.color]="editor.getAttributes('textStyle')['color']"
            >
                A
            </span>
        </div>
    </ng-template>

    <ng-template #listFormats>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('bulletList')"
            (click)="editor.chain().focus().toggleBulletList().run()"
        >
            <i class="icon-list"></i>
        </div>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('orderedList')"
            (click)="editor.chain().focus().toggleOrderedList().run()"
        >
            <i class="iconhx8-list-numbered"></i>
        </div>
        <div class="action-separator"></div>
        <div
            class="btn-action btn-action-icon"
            [class.selected]="editor.isActive('blockquote')"
            (click)="editor.chain().focus().toggleBlockquote().run()"
        >
            <i class="iconhx8-blockquote"></i>
        </div>
    </ng-template>

    <ng-template #hyperlinkFormats>
        <div
            class="btn-action"
            *ngIf="editor.isActive('link'); else elseSetLink"
            [class.selected]="true"
            mat-button
            #tLinkEditMenu="matMenuTrigger"
            [matMenuTriggerFor]="linkEditMenu"
        >
            <i class="icon-link"></i>
        </div>
        <ng-template #elseSetLink>
            <div class="btn-action" (click)="setLink()">
                <i class="icon-link"></i>
            </div>
        </ng-template>
    </ng-template>

    <mat-menu #linkEditMenu="matMenu" class="hx-menu">
        <div mat-menu-item (click)="setLink()" (keydown.enter)="setLink()">
            Edit link
        </div>
        <div
            mat-menu-item
            (click)="editor.chain().focus().unsetLink().run()"
            (keydown.enter)="editor.chain().focus().unsetLink().run()"
        >
            Remove link
        </div>
    </mat-menu>

    <mat-menu #imageMenuItem="matMenu" class="hx-menu">
        <div
            mat-menu-item
            (click)="onEmbedImageLink()"
            (keydown.enter)="onEmbedImageLink()"
        >
            Embed link
        </div>
        <ng-container
            *ngIf="!disableImageUploadExternalWindow; else elseTemplate"
        >
            <div
                mat-menu-item
                (click)="imageUploaderInput.click()"
                (keydown.enter)="imageUploaderInput.click()"
            >
                Upload image
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div mat-menu-item disabled>
                Upload image (not supported, use clipboard copy/paste)
            </div>
        </ng-template>
    </mat-menu>

    <mat-menu #headersMenuItem="matMenu" class="hx-menu">
        <div
            *ngFor="let i of [1, 2, 3]"
            mat-menu-item
            class="line"
            (click)="onHeaderSwitch(i)"
            (keydown.enter)="onHeaderSwitch(i)"
        >
            <span class="menu-line-icon">
                <i
                    *ngIf="editor.isActive('heading', { level: i })"
                    class="secondary-text icon-check"
                ></i>
            </span>
            <span class="ellipsis"> Header {{ i }} </span>
        </div>
        <div
            mat-menu-item
            class="line"
            (click)="onHeaderCancel()"
            (keydown.enter)="onHeaderCancel()"
        >
            <span class="menu-line-icon">
                <i
                    *ngIf="!editor.isActive('heading')"
                    class="secondary-text icon-check"
                ></i>
            </span>
            <span class="ellipsis"> Text</span>
        </div>
    </mat-menu>

    <mat-menu #colorsMenuItem="matMenu" class="hx-menu">
        <div
            *ngFor="let colorSelectionEl of colorSelectionEls"
            mat-menu-item
            class="line"
            (click)="onEditorColor(colorSelectionEl.el?.name)"
            (keydown.enter)="onEditorColor(colorSelectionEl.el?.name)"
        >
            <span class="menu-line-icon">
                <i
                    *ngIf="
                        editor.isActive('textStyle', {
                            color: colorSelectionEl?.el?.hex
                        })
                    "
                    class="secondary-text icon-check"
                ></i>
            </span>
            <div [hxColor]="colorSelectionEl.icon?.color" class="bold mr2">
                A
            </div>
            {{ colorSelectionEl.value }}
        </div>
        <div mat-menu-item (click)="onEditorColor()">
            <span class="menu-line-icon">
                <i
                    *ngIf="!editor.getAttributes('textStyle')?.['color']"
                    class="secondary-text icon-check"
                ></i>
            </span>
            <div class="bold mr2">A</div>
            None
        </div>
    </mat-menu>
</ng-container>
<input
    #imageUploaderInput
    class="ghost-input"
    type="file"
    id="upload-file"
    name="upload-file"
    [accept]="acceptedImageUploadMimeTypes"
    ngf-max-size="2MB"
    (change)="
        $event.preventDefault();
        $event.stopPropagation();
        fileUploaded.emit($event)
    "
/>
