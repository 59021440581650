<div
    [hxMenu]
    (selectionChanges)="onTabIndexSelect($event)"
    tabindex="0"
    class="menu-container"
>
    <ng-content select="[menuHeader]"></ng-content>
    <div
        *ngIf="searchable"
        class="hx-dropdown-header"
        [style.width.px]="dropdownWidth"
        (click)="$event.stopPropagation()"
    >
        <div
            class="hx-tags-holder"
            *ngIf="selectionAsTag && showSelected; else elseRegularInput"
        >
            <div
                *ngFor="let el of selectedEls; trackBy: trackById"
                class="hx-tag rounded tag-close"
                [hxTagColor]="el?.color"
            >
                {{ el?.value }}
                <i class="icon-x" (click)="onTagSelect(el, false)"></i>
            </div>
            <input
                class="hx-dropdown-input"
                [(ngModel)]="query"
                [placeholder]="placeholder"
                [hxMenuInput]
            />
        </div>
        <ng-template #elseRegularInput>
            <input
                class="hx-dropdown-input"
                [(ngModel)]="query"
                [placeholder]="placeholder"
                [hxMenuInput]
            />
        </ng-template>
    </div>
    <div
        *ngIf="!loading; else elseLoadingTemplate"
        class="dropdown-body hx-lines-menu noheight"
        [style.height.px]="height"
        [style.maxHeight.px]="maxHeight"
        [style.width.px]="dropdownWidth"
        (click)="$event.stopPropagation()"
    >
        <cdk-virtual-scroll-viewport
            [itemSize]="lineHeightPx"
            class="virtualscroll-viewport"
        >
            <div
                *ngIf="!displayData.length && !hideEmptyResultPlaceholder"
                class="flex-center"
                [style.height.px]="lineHeightPx"
            >
                No result
            </div>
            <div
                [hxMenuLine]
                *cdkVirtualFor="
                    let el of displayData;
                    let i = index;
                    trackBy: trackById
                "
                [tabIndex]="el.tabIndex"
                [matTooltip]="el.tooltip || ''"
                matTooltipShowDelay="500"
                class="ellipsis line"
                [class.cardline]="lineAsCards"
                [class.hx-inner-border-top]="el.separatorTop"
                [class.disable]="el.disable"
                [class.hx-readonly]="el.disable"
                (click)="onLineSelect(el)"
                (keydown.enter)="onLineSelect(el)"
            >
                <ng-container [ngSwitch]="el.displayType">
                    <!-- ? CREATE LINE -->
                    <ng-container *ngSwitchCase="'CREATE'">
                        <span class="menu-line-icon">
                            <i class="icon-plus"></i>
                        </span>
                        <span class="text ellipsis">
                            {{ createLineCustomLabel }}
                            <span class="secondary-text ellipsis">{{
                                query
                            }}</span>
                        </span>
                    </ng-container>
                    <!-- ? PERSON LINE -->
                    <ng-container *ngSwitchCase="'PERSON'">
                        <span class="menu-line-icon" *ngIf="showSelected">
                            <i
                                *ngIf="selectedElsMap.get(el.id)"
                                class="secondary-text"
                                [class]="{
                                    'icon-check': !partialSelectedIdsMap.get(
                                        el.id
                                    ),
                                    'icon-minus-square':
                                        partialSelectedIdsMap.get(el.id)
                                }"
                            ></i>
                        </span>
                        <div class="left-aligned ellipsis">
                            <span class="menu-line-icon mr3" *ngIf="el.icon">
                                <i
                                    [ngClass]="el.icon.name"
                                    [hxColor]="el.icon.color"
                                ></i>
                            </span>

                            <hx-profile-picture
                                size="inline"
                                [person]="forceProfilePictureData(el)"
                                class="mr2"
                            ></hx-profile-picture>
                            <span class="ellipsis {{ el.cssColor }}">
                                {{ el.value }}
                            </span>
                            <span
                                class="secondary-text ml1 ellipsis"
                                *ngIf="el.secondaryValue?.length"
                            >
                                {{ el.secondaryValue }}
                            </span>
                        </div>
                        <div class="right-aligned f0">
                            <span
                                class="hx-tag tag-transparent ttc"
                                *ngIf="el.isCollaborator"
                            >
                                {{ el.isCollaborator }}
                            </span>
                        </div>
                    </ng-container>
                    <!-- ? NORMAL LINE -->
                    <ng-container *ngSwitchDefault>
                        <span class="menu-line-icon" *ngIf="showSelected">
                            <i
                                *ngIf="selectedElsMap.get(el.id)"
                                class="secondary-text"
                                [class]="{
                                    'icon-check': !partialSelectedIdsMap.get(
                                        el.id
                                    ),
                                    'icon-minus-square':
                                        partialSelectedIdsMap.get(el.id)
                                }"
                            ></i>
                        </span>
                        <span class="menu-line-icon" *ngIf="el.icon">
                            <i
                                [ngClass]="el.icon.name"
                                [hxColor]="el.icon.color"
                            ></i>
                        </span>
                        <span class="line-primary ellipsis {{ el.cssColor }}">
                            {{ el.value }}
                        </span>
                        <span
                            class="line-secondary secondary-text ml1 ellipsis"
                            *ngIf="el.secondaryValue?.length"
                        >
                            {{ el.secondaryValue }}
                        </span>
                    </ng-container>
                </ng-container>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
    <ng-template #elseLoadingTemplate>
        <div
            class="dropdown-body"
            style="height: 220px"
            [style.width.px]="dropdownWidth"
        >
            <div class="flex-center fill-space">Loading...</div>
        </div>
    </ng-template>
    <ng-content select="[menuFooter]"></ng-content>
</div>
