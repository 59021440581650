import {
    ChangeDetectorRef,
    Directive,
    Host,
    HostListener,
    Input,
    OnDestroy
} from "@angular/core";
import { ImageUploadAbstractServicePorts } from "@harvestr-client/shared/ng/util-injector-environment";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { Editor } from "@tiptap/core";
import { catchError, EMPTY, tap } from "rxjs";
import { HxWysiwygComponent } from "../hx-wysiwyg/hx-wysiwyg.component";

interface RenderOptions {
    coordinates?:
        | {
              pos: number;
              inside: number;
          }
        | null
        | undefined;
}

@Directive({
    selector: "[hxWysiwygUploadImages]"
})
export class WysiwyUploadImagesDirective implements OnDestroy {
    @Input({ required: true }) hxWysiwygUploadImages: undefined;

    private subs = new SubSink();

    @HostListener("window:paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        const files = event?.clipboardData?.files;
        const view = this.wysiwyg.editor?.view;
        const images = this._filterImageFiles(files);
        if (view && images.length) {
            event.preventDefault();
            this._onPasteImages(view, images);
        }
    }

    @HostListener("drop", ["$event"]) ondrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        const files = event?.dataTransfer?.files;
        const view = this.wysiwyg.editor?.view;
        const images = this._filterImageFiles(files);
        if (view && images.length) {
            const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
            });
            this._onPasteImages(view, images, { coordinates });
        }
    }

    private _filterImageFiles(files: FileList | null | undefined) {
        if (!files) {
            return [];
        }
        return Array.from(files).filter(item =>
            this.wysiwyg.acceptedImageUploadMimeTypes.includes(item.type)
        );
    }

    constructor(
        @Host() private wysiwyg: HxWysiwygComponent,
        private imageUploadService: ImageUploadAbstractServicePorts,
        private cd: ChangeDetectorRef
    ) {
        this.wysiwyg.withImageUpload = true;
        const watchOnFileUploadedFromToolbar$ = this.wysiwyg.fileUploaded.pipe(
            tap(event => {
                const files = (event?.target as HTMLInputElement)?.files;
                const images = this._filterImageFiles(files);
                const view = this.wysiwyg.editor?.view;
                if (view && images.length) {
                    event.preventDefault();
                    this._onPasteImages(view, images);
                }
            })
        );
        this.subs.add(watchOnFileUploadedFromToolbar$.subscribe());
    }

    private _renderImage(
        view: Editor["view"],
        imageUrl: string,
        options?: RenderOptions
    ) {
        const node = view.state.schema.nodes["image"].create({
            src: imageUrl
        });
        if (options?.coordinates) {
            const transaction = view.state.tr.insert(
                options.coordinates.pos,
                node
            );
            view.dispatch(transaction);
        } else {
            const transaction = view.state.tr.replaceSelectionWith(node);
            view.dispatch(transaction);
        }
    }

    private _onPasteImages(
        view: Editor["view"],
        imagesList: File[],
        options?: RenderOptions
    ) {
        const reader = new FileReader();
        reader.onload = event => {
            const imageUrl = event.target?.result;
            if (typeof imageUrl === "string") {
                this._renderImage(view, imageUrl, options);
            }
        };
        for (const imageFile of imagesList) {
            this.wysiwyg.isLoading = true;
            this.imageUploadService
                .uploadImage$(imageFile)
                .pipe(
                    tap(uploadResp => {
                        // reader.readAsDataURL(item);
                        this._renderImage(view, uploadResp.url, options);
                        this.wysiwyg.isLoading = false;
                        this.cd.markForCheck();
                    }),
                    catchError(_e => {
                        this.wysiwyg.isLoading = false;
                        this.cd.markForCheck();
                        return EMPTY;
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
