export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  JSON: Record<string, any>;
};

export enum Account_Blocked_Reason {
  Churn = 'CHURN',
  NotPayed = 'NOT_PAYED',
  TrialEnded = 'TRIAL_ENDED',
  UnsufficientPlan = 'UNSUFFICIENT_PLAN'
}

export enum Attribute_Applies_To {
  Organization = 'ORGANIZATION',
  Person = 'PERSON'
}

export enum Authorization_Status {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  OldAccount = 'OLD_ACCOUNT',
  Paused = 'PAUSED',
  Trial = 'TRIAL'
}

export type Account = {
  __typename: 'Account';
  avatarUrl?: Maybe<Scalars['String']>;
  customInboxViews: Array<CustomInbox>;
  digestFrequency: Digest_Frequency;
  digestOnDiscoveriesActions: Scalars['Boolean'];
  digestOnDiscoverystateChange: Scalars['Boolean'];
  digestOnMentionned: Scalars['Boolean'];
  digestOnMessageLinked: Scalars['Boolean'];
  digestOnSetAsOwner: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  need_onboarding: Scalars['Boolean'];
  newNotifications: Scalars['Boolean'];
  person: Person;
  personalAccessToken?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  project: Project;
  seatType: Seat_Type;
  username: Scalars['String'];
};


export type AccountCustomInboxViewsArgs = {
  orderBy?: InputMaybe<CustomInboxOrderByInput>;
};

export type AccountCreateManyWithoutSubscribed_DiscoveriesInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
};

export type AccountCreateOneInput = {
  connect?: InputMaybe<AccountWhereUniqueInput>;
};

export type AccountCreateOneWithoutPersonInput = {
  connect?: InputMaybe<AccountWhereUniqueInput>;
};

export enum AccountOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UsernameAsc = 'username_ASC',
  UsernameDesc = 'username_DESC'
}

export type AccountUpdateDataInput = {
  digestFrequency?: InputMaybe<Digest_Frequency>;
  digestOnDiscoveriesActions?: InputMaybe<Scalars['Boolean']>;
  digestOnDiscoverystateChange?: InputMaybe<Scalars['Boolean']>;
  digestOnMentionned?: InputMaybe<Scalars['Boolean']>;
  digestOnMessageLinked?: InputMaybe<Scalars['Boolean']>;
  digestOnSetAsOwner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  integration_panel_open?: InputMaybe<Scalars['Boolean']>;
  need_onboarding?: InputMaybe<Scalars['Boolean']>;
  newNotifications?: InputMaybe<Scalars['Boolean']>;
  person?: InputMaybe<PersonUpdateWithoutAccountDataInput>;
};

export type AccountUpdateManyWithoutCustomInboxViewsInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<AccountWhereUniqueInput>>;
};

export type AccountWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AccountWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Activity = {
  __typename: 'Activity';
  account?: Maybe<Account>;
  changelogs: Array<Changelog>;
  createdAt: Scalars['DateTime'];
  discovery?: Maybe<ActivityDiscovery>;
  id: Scalars['ID'];
  initiator: ActivityInitiator;
  message?: Maybe<ActivityMessage>;
  mutation: ActivityObjectMutation;
  project?: Maybe<Project>;
  roadmapProject?: Maybe<ActivityRoadmapProject>;
  trackable_type: ActivitySource;
};

export enum ActivityAttributeMutation {
  Connect = 'CONNECT',
  Create = 'CREATE',
  Delete = 'DELETE',
  Deletemany = 'DELETEMANY',
  Disconnect = 'DISCONNECT',
  Set = 'SET',
  Update = 'UPDATE',
  Updatemany = 'UPDATEMANY',
  Upsert = 'UPSERT'
}

export type ActivityConnection = {
  __typename: 'ActivityConnection';
  edges: Array<ActivityConnectionEdge>;
  pageInfo: ActivityConnectionPageInfo;
};

export type ActivityConnectionEdge = {
  __typename: 'ActivityConnectionEdge';
  node: Activity;
};

export type ActivityConnectionPageInfo = {
  __typename: 'ActivityConnectionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type ActivityDiscovery = {
  __typename: 'ActivityDiscovery';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export enum ActivityInitiator {
  Automation = 'AUTOMATION',
  Backend = 'BACKEND',
  InternalAccount = 'INTERNAL_ACCOUNT'
}

export type ActivityMessage = {
  __typename: 'ActivityMessage';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export enum ActivityObjectMutation {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum ActivityResourceType {
  Discovery = 'DISCOVERY',
  RoadmapProject = 'ROADMAP_PROJECT'
}

export type ActivityRoadmapProject = {
  __typename: 'ActivityRoadmapProject';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export enum ActivitySource {
  Account = 'ACCOUNT',
  ApiToken = 'API_TOKEN',
  Chunk = 'CHUNK',
  Comment = 'COMMENT',
  CustomInbox = 'CUSTOM_INBOX',
  Discovery = 'DISCOVERY',
  Discoveryfield = 'DISCOVERYFIELD',
  Discoverystate = 'DISCOVERYSTATE',
  Filterview = 'FILTERVIEW',
  Invite = 'INVITE',
  Message = 'MESSAGE',
  MessageLabel = 'MESSAGE_LABEL',
  Organization = 'ORGANIZATION',
  Organizationgroup = 'ORGANIZATIONGROUP',
  Person = 'PERSON',
  Project = 'PROJECT',
  RoadmapProject = 'ROADMAP_PROJECT',
  RoadmapView = 'ROADMAP_VIEW',
  Tag = 'TAG',
  Usergroup = 'USERGROUP'
}

export type ApiToken = {
  __typename: 'ApiToken';
  appId?: Maybe<Scalars['String']>;
  appSecret?: Maybe<Scalars['String']>;
  chromeExtDiscoveryRequired?: Maybe<Scalars['Boolean']>;
  chromeExtLabelsRequired?: Maybe<Scalars['Boolean']>;
  chromeExtRequesterRequired?: Maybe<Scalars['Boolean']>;
  chromeExtTitleRequired?: Maybe<Scalars['Boolean']>;
  connected_user_id?: Maybe<Scalars['String']>;
  consumer_key?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultMessageTemplate?: Maybe<MessageTemplate>;
  displayName?: Maybe<Scalars['String']>;
  github_accessToken?: Maybe<Scalars['String']>;
  host_url?: Maybe<Scalars['String']>;
  hubspot_accessToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration_access_token?: Maybe<Scalars['String']>;
  integration_trigger?: Maybe<TokenTrigger>;
  integration_webhook_id?: Maybe<Scalars['String']>;
  integration_workspace_id?: Maybe<Scalars['String']>;
  isDatasyncSetup?: Maybe<Scalars['Boolean']>;
  jira_customFieldId_company_initiative?: Maybe<Scalars['String']>;
  jira_customFieldId_epic?: Maybe<Scalars['String']>;
  jira_customFieldId_project_dependencies?: Maybe<Scalars['String']>;
  jira_customFieldId_scorePoint?: Maybe<Scalars['String']>;
  jira_customFieldId_sprint?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  notificationRules?: Maybe<Array<NotificationRule>>;
  optAlwaysCopy?: Maybe<Scalars['Boolean']>;
  optAutoSyncPersonsAndOrganizations?: Maybe<Scalars['Boolean']>;
  optEnableDiscoveryLink?: Maybe<Scalars['Boolean']>;
  optFeedbackDiscoveryReq?: Maybe<Scalars['Boolean']>;
  optFeedbackLabelsReq?: Maybe<Scalars['Boolean']>;
  optFeedbackRequesterReq?: Maybe<Scalars['Boolean']>;
  optFeedbackTitleReq?: Maybe<Scalars['Boolean']>;
  optSlackDisableBotAck?: Maybe<Scalars['Boolean']>;
  optSyncOrganizations?: Maybe<Scalars['Boolean']>;
  optTagIntegrationSourceMessageOnReceive?: Maybe<Scalars['Boolean']>;
  optVirtualContributorSearch?: Maybe<Scalars['Boolean']>;
  private_key?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  sfOauthIsSandbox?: Maybe<Scalars['Boolean']>;
  slackTeamName?: Maybe<Scalars['String']>;
  source: Token_Source;
  sourceDisplay?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  syncConfig?: Maybe<DatasyncConfig>;
  syncIdentifiers?: Maybe<Array<SyncIdentifier>>;
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  widgetFeedbackCollector?: Maybe<WidgetFeedbackCollector>;
  zendesk_apiToken?: Maybe<Scalars['String']>;
  zendesk_widgetToken?: Maybe<Scalars['String']>;
};

export type ApiTokenCreateInput = {
  source: Token_Source;
};

export type ApiTokenCreateOneInput = {
  connect?: InputMaybe<ApiTokenWhereUniqueInput>;
};

export enum ApiTokenOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type ApiTokenSubscriptionPayload = {
  __typename: 'ApiTokenSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<ApiToken>;
};

export type ApiTokenUpdateDataInput = {
  appId?: InputMaybe<Scalars['String']>;
  appSecret?: InputMaybe<Scalars['String']>;
  chromeExtDiscoveryRequired?: InputMaybe<Scalars['Boolean']>;
  chromeExtLabelsRequired?: InputMaybe<Scalars['Boolean']>;
  chromeExtRequesterRequired?: InputMaybe<Scalars['Boolean']>;
  chromeExtTitleRequired?: InputMaybe<Scalars['Boolean']>;
  defaultMessageTemplate?: InputMaybe<MessageTemplateUpdateOneWithoutApiTokensInput>;
  displayName?: InputMaybe<Scalars['String']>;
  hubspot_accessToken?: InputMaybe<Scalars['String']>;
  integration_access_token?: InputMaybe<Scalars['String']>;
  integration_trigger?: InputMaybe<TokenTriggerUpdateOneWithoutApiTokenInput>;
  optAlwaysCopy?: InputMaybe<Scalars['Boolean']>;
  optAutoSyncPersonsAndOrganizations?: InputMaybe<Scalars['Boolean']>;
  optEnableDiscoveryLink?: InputMaybe<Scalars['Boolean']>;
  optFeedbackDiscoveryReq?: InputMaybe<Scalars['Boolean']>;
  optFeedbackLabelsReq?: InputMaybe<Scalars['Boolean']>;
  optFeedbackRequesterReq?: InputMaybe<Scalars['Boolean']>;
  optFeedbackTitleReq?: InputMaybe<Scalars['Boolean']>;
  optSlackDisableBotAck?: InputMaybe<Scalars['Boolean']>;
  optSyncOrganizations?: InputMaybe<Scalars['Boolean']>;
  optTagIntegrationSourceMessageOnReceive?: InputMaybe<Scalars['Boolean']>;
  optVirtualContributorSearch?: InputMaybe<Scalars['Boolean']>;
  sfOauthIsSandbox?: InputMaybe<Scalars['Boolean']>;
  slackTeamName?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  syncIdentifiers?: InputMaybe<SyncIdentifierUpdateManyInput>;
};

export type ApiTokenWhereInput = {
  source?: InputMaybe<Token_Source>;
  source_in?: InputMaybe<Array<InputMaybe<Token_Source>>>;
};

export type ApiTokenWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  integration_target_id?: InputMaybe<Scalars['String']>;
  integration_trigger_id?: InputMaybe<Scalars['String']>;
  integration_webhook_id?: InputMaybe<Scalars['String']>;
  salesforce_access_token?: InputMaybe<Scalars['String']>;
};

export type Attachment = {
  __typename: 'Attachment';
  cid?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  filename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  loading?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type AttachmentCreateManyWithoutAttached_CommentsInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
};

export type AttachmentCreateManyWithoutAttached_DiscoveriesInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
};

export type AttachmentCreateManyWithoutAttached_Roadmap_CardsInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
};

export enum AttachmentOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type AttachmentUpdateManyWithoutAttached_Roadmap_CardsInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
};

export type AttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Authorizations = {
  __typename: 'Authorizations';
  blockedReason?: Maybe<Account_Blocked_Reason>;
  cf_feat_advanced_rights_management?: Maybe<Scalars['Boolean']>;
  cf_feat_automation?: Maybe<Scalars['Boolean']>;
  cf_feat_bulk_feedback_actions?: Maybe<Scalars['Boolean']>;
  cf_feat_computed_fields?: Maybe<Scalars['Boolean']>;
  cf_feat_computed_fields_limit?: Maybe<Scalars['String']>;
  cf_feat_contributors_limit?: Maybe<Scalars['String']>;
  cf_feat_customer_profile?: Maybe<Scalars['Boolean']>;
  cf_feat_datasync?: Maybe<Scalars['Boolean']>;
  cf_feat_devtools_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_discovery_limit?: Maybe<Scalars['String']>;
  cf_feat_editors_limit?: Maybe<Scalars['String']>;
  cf_feat_freshdesk_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_hubspot_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_intercom_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_merlin_categorizer?: Maybe<Scalars['Boolean']>;
  cf_feat_merlin_summarizer?: Maybe<Scalars['Boolean']>;
  cf_feat_rest_api?: Maybe<Scalars['Boolean']>;
  cf_feat_roadmap?: Maybe<Scalars['Boolean']>;
  cf_feat_roadmap_theme?: Maybe<Scalars['Boolean']>;
  cf_feat_roadmap_views_limit?: Maybe<Scalars['String']>;
  cf_feat_salesforce_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_saml_auth?: Maybe<Scalars['Boolean']>;
  cf_feat_scoring_fields_limit?: Maybe<Scalars['String']>;
  cf_feat_slack_integration?: Maybe<Scalars['Boolean']>;
  cf_feat_suggestion_accepted_limit: Scalars['String'];
  cf_feat_webhooks?: Maybe<Scalars['Boolean']>;
  cf_feat_zendesk_integration?: Maybe<Scalars['Boolean']>;
  featureCustomInboxes?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  status: Authorization_Status;
  trialEndAt?: Maybe<Scalars['DateTime']>;
  trialExtended?: Maybe<Scalars['Boolean']>;
};

export enum AuthorizationsOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export enum AutomationWorkflowStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Disabled = 'DISABLED'
}

export type BulkMutationResponseElement = {
  __typename: 'BulkMutationResponseElement';
  elementId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  status: BulkMutationResponseElementStatus;
};

export enum BulkMutationResponseElementStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export type BulkMutationResponseLegacy = {
  __typename: 'BulkMutationResponseLegacy';
  count?: Maybe<Scalars['Int']>;
};

export enum Channel {
  ChromeWidget = 'CHROME_WIDGET',
  Form = 'FORM',
  Freshdesk = 'FRESHDESK',
  Hfc = 'HFC',
  Hubspot = 'HUBSPOT',
  Intercom = 'INTERCOM',
  Mail = 'MAIL',
  Note = 'NOTE',
  PublicApi = 'PUBLIC_API',
  Salesforce = 'SALESFORCE',
  Sheet = 'SHEET',
  Slack = 'SLACK',
  Zendesk = 'ZENDESK'
}

export enum Cloud_Identity_Provider {
  GoogleCloudIdentity = 'GOOGLE_CLOUD_IDENTITY',
  Harvestr = 'HARVESTR',
  MicrosoftCloud = 'MICROSOFT_CLOUD'
}

export enum Color {
  Azure = 'AZURE',
  Black = 'BLACK',
  Blue = 'BLUE',
  Green = 'GREEN',
  Grey = 'GREY',
  Lblue = 'LBLUE',
  Lgreen = 'LGREEN',
  Lpink = 'LPINK',
  Lpurple = 'LPURPLE',
  Lyellow = 'LYELLOW',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export enum Column_Type {
  CalculatedField = 'CALCULATED_FIELD',
  Integration = 'INTEGRATION',
  Main = 'MAIN',
  UserField = 'USER_FIELD'
}

export enum Conjunction_Type {
  And = 'AND',
  Or = 'OR'
}

export enum Context_Type {
  Backend = 'BACKEND',
  IntegrationGithub = 'INTEGRATION_GITHUB',
  IntegrationGitlab = 'INTEGRATION_GITLAB',
  IntegrationIntercom = 'INTEGRATION_INTERCOM',
  IntegrationJira = 'INTEGRATION_JIRA',
  IntegrationMail = 'INTEGRATION_MAIL',
  IntegrationSalesforce = 'INTEGRATION_SALESFORCE',
  IntegrationSlack = 'INTEGRATION_SLACK',
  IntegrationTrello = 'INTEGRATION_TRELLO',
  IntegrationZapier = 'INTEGRATION_ZAPIER',
  IntegrationZendeskApi = 'INTEGRATION_ZENDESK_API',
  IntegrationZendeskWidget = 'INTEGRATION_ZENDESK_WIDGET',
  Public = 'PUBLIC',
  User = 'USER'
}

export enum Contributor_Attribute_Source {
  IntegrationHubspot = 'INTEGRATION_HUBSPOT',
  IntegrationIntercom = 'INTEGRATION_INTERCOM',
  IntegrationPipedrive = 'INTEGRATION_PIPEDRIVE',
  IntegrationSalesforce = 'INTEGRATION_SALESFORCE',
  None = 'NONE'
}

export enum Contributor_Attribute_Type {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  List = 'LIST',
  Null = 'NULL',
  Numeric = 'NUMERIC',
  Object = 'OBJECT',
  Rating = 'RATING',
  Text = 'TEXT',
  Url = 'URL'
}

export enum Css_Position_Unit {
  Em = 'EM',
  Percent = 'PERCENT',
  Px = 'PX',
  Rem = 'REM',
  Vh = 'VH',
  Vmax = 'VMAX',
  Vmin = 'VMIN',
  Vw = 'VW'
}

export enum Custom_Inbox_Filter_Chunks {
  NoChunks = 'NO_CHUNKS',
  SomeChunks = 'SOME_CHUNKS'
}

export enum Custom_Inbox_Filter_Suggestion {
  None = 'NONE',
  Some = 'SOME'
}

export type Changelog = {
  __typename: 'Changelog';
  attribute: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mentionnedYou: Scalars['Boolean'];
  mutation: ActivityAttributeMutation;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['JSON']>;
};

export type Chunk = {
  __typename: 'Chunk';
  companySegments: Array<CompanySegment>;
  createdAt: Scalars['DateTime'];
  discovery: Discovery;
  id: Scalars['ID'];
  linkedFromIntegration?: Maybe<Scalars['Boolean']>;
  message: Message;
  messageCreatedAt: Scalars['DateTime'];
  score: Scalars['Float'];
  selectionOffset?: Maybe<Array<TextSelection>>;
  starred: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userSegments: Array<Usergroup>;
};

export type ChunkCreateInput = {
  discovery: DiscoveryCreateOneWithoutChunksInput;
  id?: InputMaybe<Scalars['ID']>;
  linkedFromIntegration?: InputMaybe<Scalars['Boolean']>;
  message: MessageCreateOneWithoutChunksInput;
  score?: InputMaybe<Scalars['Int']>;
  selectionOffset?: InputMaybe<TextSelectionCreateManyInput>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type ChunkCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<ChunkWhereUniqueInput>>;
  create?: InputMaybe<Array<ChunkCreateWithoutDiscoveryInput>>;
};

export type ChunkCreateWithoutDiscoveryInput = {
  id?: InputMaybe<Scalars['ID']>;
  linkedFromIntegration?: InputMaybe<Scalars['Boolean']>;
  message: MessageCreateOneWithoutChunksInput;
  score?: InputMaybe<Scalars['Int']>;
  selectionOffset?: InputMaybe<TextSelectionCreateManyInput>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type ChunkCreateWithoutMessageInput = {
  discovery: DiscoveryCreateOneWithoutChunksInput;
  id?: InputMaybe<Scalars['ID']>;
  linkedFromIntegration?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['Int']>;
  selectionOffset?: InputMaybe<TextSelectionCreateManyInput>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type ChunkMoveManyDataInput = {
  discovery?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export enum ChunkOrderByInput {
  MessageCreatedAtAsc = 'messageCreatedAt_ASC',
  MessageCreatedAtDesc = 'messageCreatedAt_DESC',
  ScoreAsc = 'score_ASC',
  ScoreDesc = 'score_DESC'
}

export type ChunkSubscriptionPayload = {
  __typename: 'ChunkSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Chunk>;
  previousValue?: Maybe<ChunkSubscriptionPayloadPreviousValue>;
};

export type ChunkSubscriptionPayloadPreviousValue = {
  __typename: 'ChunkSubscriptionPayloadPreviousValue';
  discoveryId?: Maybe<Scalars['String']>;
};

export type ChunkUpdateDataInput = {
  discovery?: InputMaybe<DiscoveryUpdateOneRequiredWithoutChunksInput>;
  linkedFromIntegration?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['Int']>;
  selectionOffset?: InputMaybe<TextSelectionUpdateManyInput>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type ChunkWhereInput = {
  OR?: InputMaybe<Array<ChunkWhereInput>>;
  discovery?: InputMaybe<MessageWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<MessageWhereInput>;
  msgRequester?: InputMaybe<PersonWhereInput>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type ChunkWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Comment = {
  __typename: 'Comment';
  attachments: Array<Attachment>;
  commenter: Person;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  discovery?: Maybe<Discovery>;
  id: Scalars['ID'];
  mentions: Array<Person>;
  message?: Maybe<Message>;
  reactions: Array<CommentReaction>;
  roadmapProject?: Maybe<Person>;
  updatedAt: Scalars['DateTime'];
  zendeskInternalNote?: Maybe<Scalars['Boolean']>;
};


export type CommentReactionsArgs = {
  orderBy?: InputMaybe<CommentReactionOrderByInput>;
};

export type CommentCreateInput = {
  content: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  mentions?: InputMaybe<PersonCreateManyWithoutMentioned_CommentsInput>;
  reactions?: InputMaybe<CommentReactionCreateManyWithoutCommentInput>;
  zendeskInternalNote?: InputMaybe<Scalars['Boolean']>;
};

export type CommentCreateManyWithoutCommenterInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
};

export type CommentCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutDiscoveryInput>>;
};

export type CommentCreateManyWithoutMentionsInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
};

export type CommentCreateWithoutDiscoveryInput = {
  attachments?: InputMaybe<AttachmentCreateManyWithoutAttached_CommentsInput>;
  commenter: PersonCreateOneWithoutCommentsInput;
  content: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  mentions?: InputMaybe<PersonCreateManyWithoutMentioned_CommentsInput>;
  message?: InputMaybe<MessageCreateOneWithoutCommentsInput>;
  reactions?: InputMaybe<CommentReactionCreateManyWithoutCommentInput>;
  roadmapProject?: InputMaybe<RoadmapProjectCreateOneWithoutCommentsInput>;
  zendeskInternalNote?: InputMaybe<Scalars['Boolean']>;
};

export enum CommentOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type CommentReaction = {
  __typename: 'CommentReaction';
  comment: Comment;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  person: Person;
  reaction: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CommentReactionCreateManyWithoutCommentInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  create?: InputMaybe<Array<CommentReactionCreateWithoutCommentInput>>;
};

export type CommentReactionCreateManyWithoutPersonInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
};

export type CommentReactionCreateWithoutCommentInput = {
  id?: InputMaybe<Scalars['ID']>;
  reaction: Scalars['String'];
};

export enum CommentReactionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ReactionAsc = 'reaction_ASC',
  ReactionDesc = 'reaction_DESC'
}

export type CommentReactionUpdateManyWithoutCommentInput = {
  create?: InputMaybe<Array<CommentReactionCreateWithoutCommentInput>>;
  delete?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
};

export type CommentReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CommentUpdateWithoutDiscoveryDataInput = {
  content?: InputMaybe<Scalars['String']>;
  reactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentInput>;
};

export type CommentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Company = {
  __typename: 'Company';
  attributeValues?: Maybe<Array<ContributorAttributeValue>>;
  chunks_number: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  defaultPerson: Person;
  details?: Maybe<Scalars['String']>;
  discoveries_number: Scalars['Float'];
  domains?: Maybe<Array<Scalars['String']>>;
  externalUid?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<CompanySegment>>;
  id: Scalars['ID'];
  integrationSyncs?: Maybe<Array<IntegrationSync>>;
  messages_number: Scalars['Float'];
  name: Scalars['String'];
  persons?: Maybe<Array<Person>>;
  persons_number: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type CompanyConnection = {
  __typename: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  pageInfo: PageInfo;
};

export type CompanyCreateInput = {
  details?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  externalUid?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<CompanySegmentCreateManyWithoutOrganizationsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CompanyCreateManyWithoutGroupsInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
};

export type CompanyCreateOneWithoutPersonsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyEdge = {
  __typename: 'CompanyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Company>;
};

export enum CompanyOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CompanySegment = {
  __typename: 'CompanySegment';
  companiesCount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  organizations: Array<Company>;
  updatedAt: Scalars['DateTime'];
};

export type CompanySegmentCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CompanySegmentCreateManyInput = {
  connect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
  create?: InputMaybe<Array<CompanySegmentCreateInput>>;
};

export type CompanySegmentCreateManyWithoutOrganizationsInput = {
  connect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
};

export enum CompanySegmentOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CompanySegmentUpdateDataInput = {
  name?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<CompanyUpdateManyWithoutGroupsInput>;
};

export type CompanySegmentUpdateManyInput = {
  connect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
};

export type CompanySegmentUpdateManyWithoutOrganizationsInput = {
  connect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<CompanySegmentWhereUniqueInput>>;
};

export type CompanySegmentWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanySegmentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CompanyUpdateDataInput = {
  details?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  groups?: InputMaybe<CompanySegmentUpdateManyWithoutOrganizationsInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateManyWithoutGroupsInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
};

export type CompanyWhereInput = {
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  defaultPerson?: InputMaybe<PersonWhereInput>;
  details?: InputMaybe<Scalars['String']>;
  groups_some?: InputMaybe<CompanySegmentWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  nameSearchIndex?: InputMaybe<Scalars['String']>;
  nameSearchIndex_contains?: InputMaybe<Scalars['String']>;
  persons_some?: InputMaybe<PersonWhereInput>;
};

export type CompanyWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ComponentWhereInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
};

export type ContributorAttribute = {
  __typename: 'ContributorAttribute';
  appliesTo: Attribute_Applies_To;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  project: Project;
  source: Contributor_Attribute_Source;
  sourceElementName?: Maybe<Scalars['String']>;
  sourceKey?: Maybe<Scalars['String']>;
  type: Contributor_Attribute_Type;
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  values?: Maybe<Array<Maybe<ContributorAttributeValue>>>;
};

export type ContributorAttributeCreateInput = {
  appliesTo?: InputMaybe<Attribute_Applies_To>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position: Scalars['Float'];
  source: Contributor_Attribute_Source;
  sourceElementName?: InputMaybe<Scalars['String']>;
  sourceKey?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Contributor_Attribute_Type>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ContributorAttributeCreateManyInput = {
  connect?: InputMaybe<Array<ContributorAttributeWhereUniqueInput>>;
  create?: InputMaybe<Array<ContributorAttributeCreateInput>>;
};

export type ContributorAttributeCreateOneInput = {
  connect?: InputMaybe<ContributorAttributeWhereUniqueInput>;
};

export enum ContributorAttributeOrderByInput {
  AppliesToAsc = 'appliesTo_ASC',
  AppliesToDesc = 'appliesTo_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HiddenAsc = 'hidden_ASC',
  HiddenDesc = 'hidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SourceElementNameAsc = 'sourceElementName_ASC',
  SourceElementNameDesc = 'sourceElementName_DESC',
  SourceKeyAsc = 'sourceKey_ASC',
  SourceKeyDesc = 'sourceKey_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

export type ContributorAttributeUpdateDataInput = {
  data: ContributorAttributeUpdateInput;
  where: ContributorAttributeWhereUniqueInput;
};

export type ContributorAttributeUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ContributorAttributeUpdateOneInput = {
  connect?: InputMaybe<ContributorAttributeWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type ContributorAttributeValue = {
  __typename: 'ContributorAttributeValue';
  attribute: ContributorAttribute;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  tVal: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContributorAttributeValueCreateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ContributorAttributeValueWhereUniqueInput>>;
  create?: InputMaybe<Array<ContributorAttributeValueCreateWithoutOrganizationInput>>;
};

export type ContributorAttributeValueCreateManyWithoutPersonInput = {
  connect?: InputMaybe<Array<ContributorAttributeValueWhereUniqueInput>>;
};

export type ContributorAttributeValueCreateWithoutOrganizationInput = {
  tVal: Scalars['String'];
};

export type ContributorAttributeValueCreatelistValueInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ContributorAttributeValueUpdateInput = {
  tVal?: InputMaybe<Scalars['String']>;
};

export type ContributorAttributeValueUpdateManyWithoutOrganizationInput = {
  create?: InputMaybe<Array<ContributorAttributeValueCreateWithoutOrganizationInput>>;
  update?: InputMaybe<Array<ContributorAttributeValueUpdateWithWhereUniqueWithoutOrganizationInput>>;
};

export type ContributorAttributeValueUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ContributorAttributeValueUpdateWithoutOrganizationDataInput;
  where: ContributorAttributeValueWhereUniqueInput;
};

export type ContributorAttributeValueUpdateWithoutOrganizationDataInput = {
  tVal?: InputMaybe<Scalars['String']>;
};

export type ContributorAttributeValueUpdatelistValueInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ContributorAttributeValueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ContributorAttributeWhereInput = {
  appliesTo?: InputMaybe<Attribute_Applies_To>;
};

export type ContributorAttributeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CreateLinearIssueInput = {
  id?: InputMaybe<Scalars['ID']>;
  lin_assigneeId?: InputMaybe<Scalars['String']>;
  lin_description?: InputMaybe<Scalars['String']>;
  lin_labelIds: Array<Scalars['String']>;
  lin_priority: Scalars['Float'];
  lin_projectId?: InputMaybe<Scalars['String']>;
  lin_stateId?: InputMaybe<Scalars['String']>;
  lin_teamId: Scalars['String'];
  lin_title: Scalars['String'];
};

export type CustomInbox = {
  __typename: 'CustomInbox';
  after?: Maybe<Scalars['DateTime']>;
  assigneeIds: Array<Scalars['String']>;
  before?: Maybe<Scalars['DateTime']>;
  channels: Array<Channel>;
  chunks?: Maybe<Custom_Inbox_Filter_Chunks>;
  color: Color;
  contentConjunction: Conjunction_Type;
  contents: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messageLabelBehaviour: Labels_Search_Behaviour;
  messageLabelConjunction: Conjunction_Type;
  messageLabels: Array<Scalars['String']>;
  messageLabelsIncludes?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationIds: Array<Scalars['String']>;
  owner: Account;
  project: Project;
  requesterIds: Array<Scalars['String']>;
  submitterIds: Array<Scalars['String']>;
  subscribers: Array<Person>;
  suggestionFilter?: Maybe<Custom_Inbox_Filter_Suggestion>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CustomInboxCreateInput = {
  after?: InputMaybe<Scalars['DateTime']>;
  assigneeIds?: InputMaybe<CustomInboxCreateassigneeIdsInput>;
  before?: InputMaybe<Scalars['DateTime']>;
  channels?: InputMaybe<CustomInboxCreatechannelsInput>;
  chunks?: InputMaybe<Custom_Inbox_Filter_Chunks>;
  color?: InputMaybe<Color>;
  contentConjunction?: InputMaybe<Conjunction_Type>;
  contents?: InputMaybe<CustomInboxCreatecontentsInput>;
  id?: InputMaybe<Scalars['ID']>;
  messageLabelBehaviour?: InputMaybe<Labels_Search_Behaviour>;
  messageLabelConjunction?: InputMaybe<Conjunction_Type>;
  messageLabels?: InputMaybe<CustomInboxCreatemessageLabelsInput>;
  messageLabelsIncludes?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationIds?: InputMaybe<CustomInboxCreateorganizationIdsInput>;
  requesterIds?: InputMaybe<CustomInboxCreaterequesterIdsInput>;
  submitterIds?: InputMaybe<CustomInboxCreatesubmitterIdsInput>;
  suggestionFilter?: InputMaybe<Custom_Inbox_Filter_Suggestion>;
  title?: InputMaybe<Scalars['String']>;
};

export type CustomInboxCreateassigneeIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxCreatechannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type CustomInboxCreatecontentsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxCreatemessageLabelsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxCreateorganizationIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxCreaterequesterIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxCreatesubmitterIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export enum CustomInboxOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CustomInboxUpdateInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutCustomInboxViewsInput>;
  after?: InputMaybe<Scalars['DateTime']>;
  assigneeIds?: InputMaybe<CustomInboxUpdateassigneeIdsInput>;
  before?: InputMaybe<Scalars['DateTime']>;
  channels?: InputMaybe<CustomInboxUpdatechannelsInput>;
  chunks?: InputMaybe<Custom_Inbox_Filter_Chunks>;
  color?: InputMaybe<Color>;
  contentConjunction?: InputMaybe<Conjunction_Type>;
  contents?: InputMaybe<CustomInboxUpdatecontentsInput>;
  messageLabelBehaviour?: InputMaybe<Labels_Search_Behaviour>;
  messageLabelConjunction?: InputMaybe<Conjunction_Type>;
  messageLabels?: InputMaybe<CustomInboxUpdatemessageLabelsInput>;
  messageLabelsIncludes?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationIds?: InputMaybe<CustomInboxUpdateorganizationIdsInput>;
  requesterIds?: InputMaybe<CustomInboxUpdaterequesterIdsInput>;
  submitterIds?: InputMaybe<CustomInboxUpdatesubmitterIdsInput>;
  suggestionFilter?: InputMaybe<Custom_Inbox_Filter_Suggestion>;
  title?: InputMaybe<Scalars['String']>;
};

export type CustomInboxUpdateassigneeIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxUpdatechannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type CustomInboxUpdatecontentsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxUpdatemessageLabelsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxUpdateorganizationIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxUpdaterequesterIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxUpdatesubmitterIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomInboxWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomInboxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Digest_Frequency {
  FiveMinute = 'FIVE_MINUTE',
  Immediate = 'IMMEDIATE',
  Never = 'NEVER',
  OneDay = 'ONE_DAY',
  OneHour = 'ONE_HOUR'
}

export enum Discoveryscore_Type {
  Boolean = 'BOOLEAN',
  Numerical = 'NUMERICAL',
  String = 'STRING'
}

export enum Discoverystate_Type {
  Closed = 'CLOSED',
  Finished = 'FINISHED',
  Started = 'STARTED',
  Unstarted = 'UNSTARTED'
}

export enum Discovery_Field_Score_Calculation_Method {
  Ponderated = 'PONDERATED',
  Rice = 'RICE'
}

export enum Discovery_Type {
  Component = 'COMPONENT',
  Discovery = 'DISCOVERY'
}

export type DatasyncConfig = {
  __typename: 'DatasyncConfig';
  id?: Maybe<Scalars['ID']>;
  isConnected?: Maybe<SyncConnectionState>;
  replicationStartDate?: Maybe<Scalars['String']>;
};

export type Discovery = {
  __typename: 'Discovery';
  archived: Scalars['Boolean'];
  assignee?: Maybe<Person>;
  attachments: Array<Attachment>;
  calculatedScore?: Maybe<Scalars['Int']>;
  canSummarize: Scalars['Boolean'];
  children: Array<Discovery>;
  chunks: Array<Chunk>;
  chunks_number?: Maybe<Scalars['Float']>;
  comments: Array<Comment>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  discoveryScores: Array<DiscoveryScore>;
  discoverystate?: Maybe<Discoverystate>;
  duplicateOfDiscovery?: Maybe<Discovery>;
  duplicatedByDiscoveries?: Maybe<Array<Discovery>>;
  externalLinks: Array<ExternalLink>;
  feedbackCounts: DiscoveryFeedbackCountData;
  flagged_chunks: Array<Chunk>;
  githubIssues: Array<GithubIssue>;
  gitlabIssues: Array<GitlabIssue>;
  id: Scalars['ID'];
  isSubDiscovery: Scalars['Boolean'];
  jiraIssues: Array<JiraIssue>;
  lastDiscoverystateUpdatedAt?: Maybe<Scalars['DateTime']>;
  linearIssues: Array<LinearIssue>;
  linkedCustomFieldOptions?: Maybe<Array<DiscoveryCustomFieldOption>>;
  linkedProjects?: Maybe<Array<RoadmapProject>>;
  mostRecentMessageDate?: Maybe<Scalars['DateTime']>;
  parentId?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  public_link?: Maybe<PublicLink>;
  relatedDiscoveries?: Maybe<Array<Discovery>>;
  starred: Scalars['Boolean'];
  summary: Scalars['String'];
  summaryPending: Scalars['Boolean'];
  tags: Array<Tag>;
  title: Scalars['String'];
  trelloCards: Array<TrelloCard>;
  type: Discovery_Type;
  updatedAt: Scalars['DateTime'];
  watching: Scalars['Boolean'];
};


export type DiscoveryAttachmentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttachmentOrderByInput>;
};


export type DiscoveryChunksArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ChunkOrderByInput>;
  where?: InputMaybe<ChunkWhereInput>;
};


export type DiscoveryCommentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentOrderByInput>;
};


export type DiscoveryExternalLinksArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalLinkOrderByInput>;
};


export type DiscoveryFlagged_ChunksArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ChunkOrderByInput>;
};


export type DiscoveryGithubIssuesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GithubIssueOrderByInput>;
};


export type DiscoveryGitlabIssuesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GitlabIssueOrderByInput>;
};


export type DiscoveryJiraIssuesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<JiraIssueOrderByInput>;
};


export type DiscoveryLinearIssuesArgs = {
  orderBy?: InputMaybe<LinearIssueOrderByInput>;
};


export type DiscoveryTagsArgs = {
  orderBy?: InputMaybe<TagOrderByInput>;
};


export type DiscoveryTrelloCardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TrelloCardOrderByInput>;
};

export type DiscoveryConnectRelationInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
  disconnect?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export type DiscoveryCreateInput = {
  assignee?: InputMaybe<PersonCreateOneInput>;
  description?: InputMaybe<Scalars['String']>;
  discoverystate?: InputMaybe<DiscoverystateConnectRelationInput>;
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<DiscoveryConnectRelationInput>;
  position?: InputMaybe<Scalars['Float']>;
  tags?: InputMaybe<TagConnectManyInput>;
  title: Scalars['String'];
  type: Discovery_Type;
};

export type DiscoveryCreateManyInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryCreateManyWithoutDiscoverystateInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryCreateManyWithoutParentInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryCreateManyWithoutTagsInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryCreateOneWithoutChildrenInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export type DiscoveryCreateOneWithoutChunksInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export type DiscoveryCreateOneWithoutDiscoveryScoresInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export type DiscoveryCustomFieldOption = {
  __typename: 'DiscoveryCustomFieldOption';
  color: Color;
  customField: Discoveryfield;
  customFieldPosition: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
};

export type DiscoveryCustomFieldOptionConnectManyInput = {
  connect?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
};

export type DiscoveryCustomFieldOptionCreateInput = {
  color: Color;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
};

export enum DiscoveryCustomFieldOptionOrderBy {
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

export type DiscoveryCustomFieldOptionUpdateDataInput = {
  color?: InputMaybe<Color>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
};

export type DiscoveryCustomFieldOptionUpdateInput = {
  data: DiscoveryCustomFieldOptionUpdateDataInput;
  where: DiscoveryCustomFieldOptionWhereUniqueInput;
};

export type DiscoveryCustomFieldOptionUpdateManyInput = {
  connect?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
};

export type DiscoveryCustomFieldOptionWhereUniqueInput = {
  id: Scalars['String'];
};

export type DiscoveryFeedbackCountData = {
  __typename: 'DiscoveryFeedbackCountData';
  feedbackPonderatedCount: Scalars['Int'];
  paginationTotalFeedbackCount: Scalars['Int'];
};

export enum DiscoveryOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LastChunkAtAsc = 'lastChunkAt_ASC',
  LastChunkAtDesc = 'lastChunkAt_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  StarredAsc = 'starred_ASC',
  StarredDesc = 'starred_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DiscoveryRelationshipMutationResponse = {
  __typename: 'DiscoveryRelationshipMutationResponse';
  success: Scalars['Boolean'];
};

export type DiscoveryScore = {
  __typename: 'DiscoveryScore';
  boolValue?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  discoveryfield: Discoveryfield;
  id: Scalars['ID'];
  numValue?: Maybe<Scalars['Float']>;
  stringValue?: Maybe<Scalars['String']>;
  type: Discoveryscore_Type;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type DiscoveryScoreCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<DiscoveryScoreWhereUniqueInput>>;
  create?: InputMaybe<Array<DiscoveryScoreCreateWithoutDiscoveryInput>>;
};

export type DiscoveryScoreCreateManyWithoutDiscoveryfieldInput = {
  connect?: InputMaybe<Array<DiscoveryScoreWhereUniqueInput>>;
  create?: InputMaybe<Array<DiscoveryScoreCreateWithoutDiscoveryfieldInput>>;
};

export type DiscoveryScoreCreateWithoutDiscoveryInput = {
  boolValue?: InputMaybe<Scalars['Boolean']>;
  discoveryfield: DiscoveryfieldCreateOneWithoutDiscoveryScoresInput;
  id?: InputMaybe<Scalars['ID']>;
  numValue?: InputMaybe<Scalars['Float']>;
  stringValue?: InputMaybe<Scalars['String']>;
};

export type DiscoveryScoreCreateWithoutDiscoveryfieldInput = {
  discovery: DiscoveryCreateOneWithoutDiscoveryScoresInput;
  id?: InputMaybe<Scalars['ID']>;
};

export type DiscoveryScoreUpdateManyWithoutDiscoveryInput = {
  create?: InputMaybe<Array<DiscoveryScoreCreateWithoutDiscoveryInput>>;
  update?: InputMaybe<Array<DiscoveryScoreUpdateWithWhereUniqueWithoutDiscoveryInput>>;
};

export type DiscoveryScoreUpdateWithWhereUniqueWithoutDiscoveryInput = {
  data: DiscoveryScoreUpdateWithoutDiscoveryDataInput;
  where: DiscoveryScoreWhereUniqueInput;
};

export type DiscoveryScoreUpdateWithoutDiscoveryDataInput = {
  boolValue?: InputMaybe<Scalars['Boolean']>;
  numValue?: InputMaybe<Scalars['Float']>;
  stringValue?: InputMaybe<Scalars['String']>;
};

export type DiscoveryScoreWhereUniqueInput = {
  id: Scalars['ID'];
};

export type DiscoverySubscriptionPayload = {
  __typename: 'DiscoverySubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Discovery>;
};

export type DiscoverySummaryStreamSubscriptionPayload = {
  __typename: 'DiscoverySummaryStreamSubscriptionPayload';
  id: Scalars['String'];
  summary: Scalars['String'];
};

export type DiscoveryUpdateInput = {
  assignee?: InputMaybe<PersonWhereUniqueInput>;
  description?: InputMaybe<Scalars['String']>;
  discoveryScores?: InputMaybe<DiscoveryScoreUpdateManyWithoutDiscoveryInput>;
  discoverystate?: InputMaybe<DiscoverystateUpdateOneWithoutDiscoveriesInput>;
  externalLinks?: InputMaybe<ExternalLinkUpdateManyWithoutDicoveryInput>;
  githubIssues?: InputMaybe<GithubIssueUpdateManyWithOptionsInput>;
  gitlabIssues?: InputMaybe<GitlabIssueUpdateManyWithOptionsInput>;
  jiraIssues?: InputMaybe<JiraIssueUpdateManyWithOptionsInput>;
  linkedCustomFieldOptions?: InputMaybe<DiscoveryUpdateLinkedCustomFieldOptions>;
  parent?: InputMaybe<DiscoveryUpdateOneWithoutChildrenInput>;
  position?: InputMaybe<Scalars['Float']>;
  public_link?: InputMaybe<PublicLinkUpdateOneWithoutDiscoveryInput>;
  summary?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<TagUpdateManyWithoutTagged_DiscoveriesInput>;
  title?: InputMaybe<Scalars['String']>;
  trelloCards?: InputMaybe<TrelloCardUpdateManyWithOptionsInput>;
};

export type DiscoveryUpdateLinkedCustomFieldOptions = {
  connect?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
};

export type DiscoveryUpdateManyDataInput = {
  discoverystate?: InputMaybe<DiscoverystateUpdateOneWithoutDiscoveriesInput>;
  parent?: InputMaybe<DiscoveryUpdateOneWithoutChildrenInput>;
  tags?: InputMaybe<TagUpdateManyWithoutTagged_DiscoveriesInput>;
};

export type DiscoveryUpdateManyInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryUpdateManyWithoutTagsInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export type DiscoveryUpdateOneRequiredWithoutChunksInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
};

export type DiscoveryUpdateOneWithoutChildrenInput = {
  connect?: InputMaybe<DiscoveryWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type DiscoveryWhereInput = {
  chunks_some?: InputMaybe<ChunkWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  older?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Discovery_Type>;
};

export type DiscoveryWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Discoveryfield = {
  __typename: 'Discoveryfield';
  calculatedScoreCalculationMethod?: Maybe<Discovery_Field_Score_Calculation_Method>;
  calculatedScoreConfigurations?: Maybe<Array<DiscoveryfieldCalculatedScoreConfiguration>>;
  calculatedScoreExcludeDiscoverystateTypes?: Maybe<Array<Discoverystate_Type>>;
  calculationRule?: Maybe<Field_Caclulation_Rule>;
  columnType: Column_Type;
  conjunction: Conjunction_Type;
  contributorAttribute?: Maybe<ContributorAttribute>;
  createdAt: Scalars['DateTime'];
  customFieldOptions?: Maybe<Array<DiscoveryCustomFieldOption>>;
  default?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fieldType: Field_Type;
  filters?: Maybe<Array<DiscoveryfieldFilter>>;
  id: Scalars['ID'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  position: Scalars['Float'];
  scoreRiceConfidenceDiscoveryfield?: Maybe<Discoveryfield>;
  scoreRiceEffortDiscoveryfield?: Maybe<Discoveryfield>;
  scoreRiceImpactDiscoveryfield?: Maybe<Discoveryfield>;
  scoreRiceReachDiscoveryfield?: Maybe<Discoveryfield>;
  unit?: Maybe<Scalars['String']>;
  unitPosition?: Maybe<Unit_Position>;
  updatedAt: Scalars['DateTime'];
};


export type DiscoveryfieldCustomFieldOptionsArgs = {
  orderBy?: InputMaybe<DiscoveryCustomFieldOptionOrderBy>;
};

export type DiscoveryfieldCalculatedScoreConfiguration = {
  __typename: 'DiscoveryfieldCalculatedScoreConfiguration';
  id: Scalars['ID'];
  inverted: Scalars['Boolean'];
  targetDiscoveryfield: Discoveryfield;
  weight: Scalars['Int'];
};

export type DiscoveryfieldCalculatedScoreConfigurationCreateInput = {
  id: Scalars['ID'];
  inverted: Scalars['Boolean'];
  targetDiscoveryfield: DiscoveryfieldConnectOneInput;
  weight: Scalars['Int'];
};

export type DiscoveryfieldCalculatedScoreConfigurationUpdateDataInput = {
  inverted?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type DiscoveryfieldCalculatedScoreConfigurationUpdateInput = {
  data: DiscoveryfieldCalculatedScoreConfigurationUpdateDataInput;
  where: DiscoveryfieldCalculatedScoreConfigurationWhereUniqueInput;
};

export type DiscoveryfieldCalculatedScoreConfigurationWhereUniqueInput = {
  id: Scalars['String'];
};

export type DiscoveryfieldConnectOneInput = {
  connect: DiscoveryfieldWhereUniqueInput;
};

export type DiscoveryfieldCreateInput = {
  calculatedScoreCalculationMethod?: InputMaybe<Discovery_Field_Score_Calculation_Method>;
  calculatedScoreExcludeDiscoverystateTypes?: InputMaybe<SetScoreExcludeDiscoverystateTypes>;
  calculationRule?: InputMaybe<Field_Caclulation_Rule>;
  columnType?: InputMaybe<Column_Type>;
  conjunction?: InputMaybe<Conjunction_Type>;
  contributorAttribute?: InputMaybe<ContributorAttributeCreateOneInput>;
  default?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discoveryScores?: InputMaybe<DiscoveryScoreCreateManyWithoutDiscoveryfieldInput>;
  fieldCalculatedScore?: InputMaybe<DiscoveryfieldCreateManyDiscoveryfieldCalculatedScoreConfigurations>;
  fieldOptions?: InputMaybe<DiscoveryfieldCreateManyDiscoveryCustomFieldOptions>;
  fieldType: Field_Type;
  filters?: InputMaybe<DiscoveryfieldCreateManyDiscoveryfieldFilters>;
  id: Scalars['ID'];
  importId?: InputMaybe<Scalars['String']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  position: Scalars['Float'];
  scoreRiceConfidenceDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  scoreRiceEffortDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  scoreRiceImpactDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  scoreRiceReachDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  unit?: InputMaybe<Scalars['String']>;
  unitPosition?: InputMaybe<Unit_Position>;
};

export type DiscoveryfieldCreateManyDiscoveryCustomFieldOptions = {
  create?: InputMaybe<Array<DiscoveryCustomFieldOptionCreateInput>>;
};

export type DiscoveryfieldCreateManyDiscoveryfieldCalculatedScoreConfigurations = {
  create?: InputMaybe<Array<InputMaybe<DiscoveryfieldCalculatedScoreConfigurationCreateInput>>>;
};

export type DiscoveryfieldCreateManyDiscoveryfieldFilters = {
  create?: InputMaybe<Array<InputMaybe<DiscoveryfieldFilterCreateInput>>>;
};

export type DiscoveryfieldCreateManyInput = {
  connect?: InputMaybe<Array<DiscoveryfieldWhereUniqueInput>>;
  create?: InputMaybe<Array<DiscoveryfieldCreateInput>>;
};

export type DiscoveryfieldCreateOneInput = {
  connect?: InputMaybe<DiscoveryfieldWhereUniqueInput>;
};

export type DiscoveryfieldCreateOneWithoutDiscoveryScoresInput = {
  connect?: InputMaybe<DiscoveryfieldWhereUniqueInput>;
};

export type DiscoveryfieldFilter = {
  __typename: 'DiscoveryfieldFilter';
  createdAt: Scalars['DateTime'];
  discoveryfield: Discoveryfield;
  id: Scalars['ID'];
  operator: Operator_Type;
  sensitive: Scalars['Boolean'];
  stringValue: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DiscoveryfieldFilterCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  operator: Operator_Type;
  sensitive: Scalars['Boolean'];
  stringValue: Scalars['String'];
};

export type DiscoveryfieldFilterUpdateDataInput = {
  operator?: InputMaybe<Operator_Type>;
  sensitive?: InputMaybe<Scalars['Boolean']>;
  stringValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DiscoveryfieldFilterUpdateInput = {
  data: DiscoveryfieldFilterUpdateDataInput;
  where: DiscoveryfieldFilterWhereUniqueInput;
};

export type DiscoveryfieldFilterWhereUniqueInput = {
  id: Scalars['String'];
};

export type DiscoveryfieldMutateManyDiscoveryCustomFieldOptions = {
  create?: InputMaybe<Array<DiscoveryCustomFieldOptionCreateInput>>;
  delete?: InputMaybe<Array<DiscoveryCustomFieldOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DiscoveryCustomFieldOptionUpdateInput>>;
};

export type DiscoveryfieldMutateManyDiscoveryfieldCalculatedScoreConfigurations = {
  create?: InputMaybe<Array<DiscoveryfieldCalculatedScoreConfigurationCreateInput>>;
  delete?: InputMaybe<Array<DiscoveryfieldCalculatedScoreConfigurationWhereUniqueInput>>;
  update?: InputMaybe<Array<DiscoveryfieldCalculatedScoreConfigurationUpdateInput>>;
};

export enum DiscoveryfieldOrderByInput {
  ColumnTypeAsc = 'columnType_ASC',
  ColumnTypeDesc = 'columnType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  FieldTypeAsc = 'fieldType_ASC',
  FieldTypeDesc = 'fieldType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DiscoveryfieldSyncState = {
  __typename: 'DiscoveryfieldSyncState';
  affectedDiscoveryfieldIds: Array<Scalars['String']>;
  underSync: Scalars['Boolean'];
};

export type DiscoveryfieldSyncStateSubscriptionPayload = {
  __typename: 'DiscoveryfieldSyncStateSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<DiscoveryfieldSyncState>;
};

export type DiscoveryfieldUpdateDataInput = {
  calculatedScoreCalculationMethod?: InputMaybe<Discovery_Field_Score_Calculation_Method>;
  calculatedScoreExcludeDiscoverystateTypes?: InputMaybe<SetScoreExcludeDiscoverystateTypes>;
  calculationRule?: InputMaybe<Field_Caclulation_Rule>;
  conjunction?: InputMaybe<Conjunction_Type>;
  contributorAttribute?: InputMaybe<ContributorAttributeUpdateOneInput>;
  description?: InputMaybe<Scalars['String']>;
  fieldCalculatedScore?: InputMaybe<DiscoveryfieldMutateManyDiscoveryfieldCalculatedScoreConfigurations>;
  fieldOptions?: InputMaybe<DiscoveryfieldMutateManyDiscoveryCustomFieldOptions>;
  fieldType?: InputMaybe<Field_Type>;
  filters?: InputMaybe<DiscoveryfieldUpdateManyDiscoveryfieldFilters>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  scoreRiceConfidenceDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  scoreRiceEffortDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  scoreRiceImpactDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  scoreRiceReachDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  unit?: InputMaybe<Scalars['String']>;
  unitPosition?: InputMaybe<Unit_Position>;
};

export type DiscoveryfieldUpdateManyDiscoveryfieldFilters = {
  create?: InputMaybe<Array<DiscoveryfieldFilterCreateInput>>;
  delete?: InputMaybe<Array<DiscoveryfieldFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<DiscoveryfieldFilterUpdateInput>>;
};

export type DiscoveryfieldUpdateManyInput = {
  connect?: InputMaybe<Array<DiscoveryfieldWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryfieldWhereUniqueInput>>;
};

export type DiscoveryfieldUpdateOneInput = {
  connect?: InputMaybe<DiscoveryfieldWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type DiscoveryfieldWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DiscoveryfieldWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Discoverystate = {
  __typename: 'Discoverystate';
  color: Color;
  createdAt: Scalars['DateTime'];
  default: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  type: Discoverystate_Type;
  updatedAt: Scalars['DateTime'];
};

export type DiscoverystateConnectRelationInput = {
  connect?: InputMaybe<DiscoverystateWhereUniqueInput>;
  disconnect?: InputMaybe<DiscoverystateWhereUniqueInput>;
};

export type DiscoverystateCreateInput = {
  color?: InputMaybe<Color>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Discoverystate_Type>;
};

export type DiscoverystateCreateManyInput = {
  connect?: InputMaybe<Array<DiscoverystateWhereUniqueInput>>;
  create?: InputMaybe<Array<DiscoverystateCreateInput>>;
};

export type DiscoverystateCreateOneWithoutDiscoveriesInput = {
  connect?: InputMaybe<DiscoverystateWhereUniqueInput>;
};

export enum DiscoverystateOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DiscoverystateSubscriptionPayload = {
  __typename: 'DiscoverystateSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Discoverystate>;
};

export type DiscoverystateUpdateDataInput = {
  color?: InputMaybe<Color>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Discoverystate_Type>;
};

export type DiscoverystateUpdateManyInput = {
  connect?: InputMaybe<Array<DiscoverystateWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoverystateWhereUniqueInput>>;
};

export type DiscoverystateUpdateOneWithoutDiscoveriesInput = {
  connect?: InputMaybe<DiscoverystateWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type DiscoverystateWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DiscoverystateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ExternalLink = {
  __typename: 'ExternalLink';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type ExternalLinkCreateWithoutDicoveryInput = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum ExternalLinkOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type ExternalLinkUpdateInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type ExternalLinkUpdateManyWithoutDicoveryInput = {
  create?: InputMaybe<Array<ExternalLinkCreateWithoutDicoveryInput>>;
  delete?: InputMaybe<Array<ExternalLinkWhereUniqueInput>>;
};

export type ExternalLinkWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum Feedback_Cell_Display_Filter {
  DateRange = 'DATE_RANGE',
  OrgGroups = 'ORG_GROUPS',
  ScoreFeedback = 'SCORE_FEEDBACK',
  UserGroups = 'USER_GROUPS'
}

export enum Field_Caclulation_Rule {
  Avg = 'AVG',
  Count = 'COUNT',
  Max = 'MAX',
  Mean = 'MEAN',
  Med = 'MED',
  Min = 'MIN',
  Sum = 'SUM'
}

export enum Field_Type {
  Assignee = 'ASSIGNEE',
  Boolean = 'BOOLEAN',
  CalculatedScore = 'CALCULATED_SCORE',
  ContributorAttrAggregate = 'CONTRIBUTOR_ATTR_AGGREGATE',
  CreatedAt = 'CREATED_AT',
  Details = 'DETAILS',
  DiscoverystateLastUpdatedAt = 'DISCOVERYSTATE_LAST_UPDATED_AT',
  ExternalLink = 'EXTERNAL_LINK',
  Feedback = 'FEEDBACK',
  Github = 'GITHUB',
  Gitlab = 'GITLAB',
  Jira = 'JIRA',
  LastFeedback = 'LAST_FEEDBACK',
  Linear = 'LINEAR',
  Numerical = 'NUMERICAL',
  OrganizationGroups = 'ORGANIZATION_GROUPS',
  Parent = 'PARENT',
  Projects = 'PROJECTS',
  Rating = 'RATING',
  SelectMulti = 'SELECT_MULTI',
  SelectSingle = 'SELECT_SINGLE',
  State = 'STATE',
  String = 'STRING',
  Tags = 'TAGS',
  Trello = 'TRELLO',
  UpdatedAt = 'UPDATED_AT',
  Url = 'URL',
  UserGroups = 'USER_GROUPS'
}

export enum Filterdate_Type {
  DaysAgo = 'DAYS_AGO',
  Exact = 'EXACT',
  LastFifteenDay = 'LAST_FIFTEEN_DAY',
  LastNinetyDay = 'LAST_NINETY_DAY',
  LastSevenDay = 'LAST_SEVEN_DAY',
  LastThirtyDay = 'LAST_THIRTY_DAY',
  OneMonthAgo = 'ONE_MONTH_AGO',
  OneWeekAgo = 'ONE_WEEK_AGO',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY'
}

export enum Filterrow_Type {
  Assignee = 'ASSIGNEE',
  CustomField = 'CUSTOM_FIELD',
  DateCreated = 'DATE_CREATED',
  DateFeedback = 'DATE_FEEDBACK',
  DateUpdated = 'DATE_UPDATED',
  Discoverystates = 'DISCOVERYSTATES',
  FieldBoolean = 'FIELD_BOOLEAN',
  FieldNumeric = 'FIELD_NUMERIC',
  FieldString = 'FIELD_STRING',
  Github = 'GITHUB',
  Gitlab = 'GITLAB',
  Jira = 'JIRA',
  Linear = 'LINEAR',
  OrganizationGroups = 'ORGANIZATION_GROUPS',
  ParentDiscovery = 'PARENT_DISCOVERY',
  Projects = 'PROJECTS',
  ScoreFeedback = 'SCORE_FEEDBACK',
  Tags = 'TAGS',
  Trello = 'TRELLO',
  Usergroups = 'USERGROUPS'
}

export enum Filterview_Type {
  Kanban = 'KANBAN',
  Table = 'TABLE'
}

export type FilterRow = {
  __typename: 'FilterRow';
  assignees?: Maybe<Array<Person>>;
  boolValue?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  currentUserAssignee?: Maybe<Scalars['Boolean']>;
  customFieldOptions: Array<DiscoveryCustomFieldOption>;
  dateType?: Maybe<Filterdate_Type>;
  dateValueAbsolute?: Maybe<Scalars['DateTime']>;
  dateValueRelative?: Maybe<Scalars['Float']>;
  devtoolQuery?: Maybe<Scalars['String']>;
  discoverystates?: Maybe<Array<Discoverystate>>;
  id: Scalars['ID'];
  numValue?: Maybe<Scalars['Float']>;
  operator: Operator_Type;
  organizationGroups?: Maybe<Array<CompanySegment>>;
  parentDiscoveries?: Maybe<Array<Discovery>>;
  projects?: Maybe<Array<RoadmapProject>>;
  scoreDiscoveryfield?: Maybe<Discoveryfield>;
  selectDiscoveryfield?: Maybe<Discoveryfield>;
  stringValue?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  type: Filterrow_Type;
  updatedAt: Scalars['DateTime'];
  user_groups?: Maybe<Array<Usergroup>>;
};

export type FilterRowCreateInput = {
  assignees?: InputMaybe<PersonCreateManyInput>;
  boolValue?: InputMaybe<Scalars['Boolean']>;
  currentUserAssignee?: InputMaybe<Scalars['Boolean']>;
  customFieldOptions?: InputMaybe<DiscoveryCustomFieldOptionConnectManyInput>;
  dateType?: InputMaybe<Filterdate_Type>;
  dateValueAbsolute?: InputMaybe<Scalars['DateTime']>;
  dateValueRelative?: InputMaybe<Scalars['Int']>;
  devtoolQuery?: InputMaybe<Scalars['String']>;
  discoverystates?: InputMaybe<DiscoverystateCreateManyInput>;
  id?: InputMaybe<Scalars['ID']>;
  numValue?: InputMaybe<Scalars['Float']>;
  operator?: InputMaybe<Operator_Type>;
  organizationGroups?: InputMaybe<CompanySegmentCreateManyInput>;
  parentDiscoveries?: InputMaybe<DiscoveryUpdateManyInput>;
  projects?: InputMaybe<RoadmapProjectCreateManyInput>;
  scoreDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  selectDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  stringValue?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<TagCreateManyInput>;
  type?: InputMaybe<Filterrow_Type>;
  user_groups?: InputMaybe<UsergroupCreateManyInput>;
};

export type FilterRowCreateManyWithoutFilterviewInput = {
  connect?: InputMaybe<Array<FilterRowWhereUniqueInput>>;
  create?: InputMaybe<Array<FilterRowCreateInput>>;
};

export enum FilterRowOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OperatorAsc = 'operator_ASC',
  OperatorDesc = 'operator_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FilterRowUpdateManyWithoutFilterviewInput = {
  create?: InputMaybe<Array<FilterRowCreateInput>>;
  delete?: InputMaybe<Array<FilterRowWhereUniqueInput>>;
  update?: InputMaybe<Array<FilterRowUpdateWithWhereUniqueWithoutFilterviewInput>>;
};

export type FilterRowUpdateWithWhereUniqueWithoutFilterviewInput = {
  data: FilterRowUpdateWithoutFilterviewDataInput;
  where: FilterRowWhereUniqueInput;
};

export type FilterRowUpdateWithoutFilterviewDataInput = {
  assignees?: InputMaybe<PersonUpdateManyInput>;
  boolValue?: InputMaybe<Scalars['Boolean']>;
  currentUserAssignee?: InputMaybe<Scalars['Boolean']>;
  customFieldOptions?: InputMaybe<DiscoveryCustomFieldOptionUpdateManyInput>;
  dateType?: InputMaybe<Filterdate_Type>;
  dateValueAbsolute?: InputMaybe<Scalars['DateTime']>;
  dateValueRelative?: InputMaybe<Scalars['Int']>;
  devtoolQuery?: InputMaybe<Scalars['String']>;
  discoverystates?: InputMaybe<DiscoverystateUpdateManyInput>;
  numValue?: InputMaybe<Scalars['Float']>;
  operator?: InputMaybe<Operator_Type>;
  organizationGroups?: InputMaybe<CompanySegmentUpdateManyInput>;
  parentDiscoveries?: InputMaybe<DiscoveryUpdateManyInput>;
  projects?: InputMaybe<RoadmapProjectUpdateManyInput>;
  scoreDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  selectDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  stringValue?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<TagUpdateManyInput>;
  type?: InputMaybe<Filterrow_Type>;
  user_groups?: InputMaybe<UsergroupUpdateManyInput>;
};

export type FilterRowWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FilterRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Filterview = {
  __typename: 'Filterview';
  boolValue?: Maybe<Scalars['Boolean']>;
  columns: Array<Discoveryfield>;
  conjunction: Conjunction_Type;
  createdAt: Scalars['DateTime'];
  feedbackCellDisplayFilters: Array<Feedback_Cell_Display_Filter>;
  filterRows: Array<FilterRow>;
  groupBy: Group_By;
  id: Scalars['ID'];
  name: Scalars['String'];
  numValue?: Maybe<Scalars['Float']>;
  position: Scalars['Float'];
  showEmptyGroups?: Maybe<Scalars['Boolean']>;
  showSubEls?: Maybe<Scalars['Boolean']>;
  sortBy: Sort_By;
  sortDirection: Sort_Direction;
  sortDiscoveryfield?: Maybe<Discoveryfield>;
  stringValue?: Maybe<Scalars['String']>;
  type: Filterview_Type;
  updatedAt: Scalars['DateTime'];
};

export type FilterviewCreateInput = {
  columns?: InputMaybe<DiscoveryfieldCreateManyInput>;
  conjunction?: InputMaybe<Conjunction_Type>;
  feedbackCellDisplayFilters?: InputMaybe<Array<Feedback_Cell_Display_Filter>>;
  filterRows?: InputMaybe<FilterRowCreateManyWithoutFilterviewInput>;
  groupBy?: InputMaybe<Group_By>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Float']>;
  showEmptyGroups?: InputMaybe<Scalars['Boolean']>;
  showSubEls?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Sort_By>;
  sortDirection?: InputMaybe<Sort_Direction>;
  sortDiscoveryfield?: InputMaybe<DiscoveryfieldCreateOneInput>;
  type?: InputMaybe<Filterview_Type>;
};

export enum FilterviewOrderByInput {
  ConjunctionAsc = 'conjunction_ASC',
  ConjunctionDesc = 'conjunction_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FilterviewUpdateDataInput = {
  columns?: InputMaybe<DiscoveryfieldUpdateManyInput>;
  conjunction?: InputMaybe<Conjunction_Type>;
  feedbackCellDisplayFilters?: InputMaybe<Array<Feedback_Cell_Display_Filter>>;
  filterRows?: InputMaybe<FilterRowUpdateManyWithoutFilterviewInput>;
  groupBy?: InputMaybe<Group_By>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  showEmptyGroups?: InputMaybe<Scalars['Boolean']>;
  showSubEls?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Sort_By>;
  sortDirection?: InputMaybe<Sort_Direction>;
  sortDiscoveryfield?: InputMaybe<DiscoveryfieldUpdateOneInput>;
  type?: InputMaybe<Filterview_Type>;
};

export type FilterviewWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Group_By {
  Assignee = 'ASSIGNEE',
  Component = 'COMPONENT',
  None = 'NONE',
  Parent = 'PARENT',
  State = 'STATE'
}

export type GithubIssue = {
  __typename: 'GithubIssue';
  createdAt: Scalars['DateTime'];
  githubAssigneeAvatarUrl?: Maybe<Scalars['String']>;
  githubAssigneeId?: Maybe<Scalars['String']>;
  githubAssigneeLogin?: Maybe<Scalars['String']>;
  githubColumnId?: Maybe<Scalars['String']>;
  githubColumnName?: Maybe<Scalars['String']>;
  githubColumnPosition?: Maybe<Scalars['String']>;
  githubCommentsCount?: Maybe<Scalars['Int']>;
  githubDescription?: Maybe<Scalars['String']>;
  githubId?: Maybe<Scalars['String']>;
  githubLabels?: Maybe<Scalars['JSON']>;
  githubLocked: Scalars['Boolean'];
  githubMilestoneId?: Maybe<Scalars['String']>;
  githubMilestoneNumber?: Maybe<Scalars['String']>;
  githubMilestoneTitle?: Maybe<Scalars['String']>;
  githubNumber?: Maybe<Scalars['String']>;
  githubOwnerName?: Maybe<Scalars['String']>;
  githubProjectId?: Maybe<Scalars['String']>;
  githubRepositoryName?: Maybe<Scalars['String']>;
  githubRepositoryUrl?: Maybe<Scalars['String']>;
  githubStateName: Scalars['String'];
  githubTitle: Scalars['String'];
  githubUrl?: Maybe<Scalars['String']>;
  githubUserId: Scalars['String'];
  githubUserLogin: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type GithubIssueCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<GithubIssueWhereUniqueInput>>;
  create?: InputMaybe<Array<GithubIssueCreateWithoutDiscoveryInput>>;
};

export type GithubIssueCreateWithOptionsInput = {
  copyAttachments?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<GithubIssueCreateWithoutDiscoveryInput>;
};

export type GithubIssueCreateWithoutDiscoveryInput = {
  githubAssigneeAvatarUrl?: InputMaybe<Scalars['String']>;
  githubAssigneeId?: InputMaybe<Scalars['String']>;
  githubAssigneeLogin?: InputMaybe<Scalars['String']>;
  githubColumnId?: InputMaybe<Scalars['String']>;
  githubColumnName?: InputMaybe<Scalars['String']>;
  githubCommentsCount?: InputMaybe<Scalars['Int']>;
  githubDescription?: InputMaybe<Scalars['String']>;
  githubId?: InputMaybe<Scalars['String']>;
  githubLabels?: InputMaybe<Scalars['JSON']>;
  githubLocked: Scalars['Boolean'];
  githubMilestoneId?: InputMaybe<Scalars['String']>;
  githubMilestoneNumber?: InputMaybe<Scalars['String']>;
  githubMilestoneTitle?: InputMaybe<Scalars['String']>;
  githubNumber?: InputMaybe<Scalars['String']>;
  githubOwnerName?: InputMaybe<Scalars['String']>;
  githubProjectId?: InputMaybe<Scalars['String']>;
  githubRepositoryName?: InputMaybe<Scalars['String']>;
  githubRepositoryUrl?: InputMaybe<Scalars['String']>;
  githubStateName: Scalars['String'];
  githubTitle: Scalars['String'];
  githubUrl?: InputMaybe<Scalars['String']>;
  githubUserId: Scalars['String'];
  githubUserLogin: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export enum GithubIssueOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type GithubIssueUpdateManyWithOptionsInput = {
  create?: InputMaybe<GithubIssueCreateWithOptionsInput>;
  delete?: InputMaybe<GithubIssueWhereUniqueInput>;
};

export type GithubIssueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type GitlabIssue = {
  __typename: 'GitlabIssue';
  createdAt: Scalars['DateTime'];
  gitlabAssigneeAvatarUrl?: Maybe<Scalars['String']>;
  gitlabAssigneeId?: Maybe<Scalars['Float']>;
  gitlabAssigneeName?: Maybe<Scalars['String']>;
  gitlabAssigneeUrl?: Maybe<Scalars['String']>;
  gitlabAssigneeUsername?: Maybe<Scalars['String']>;
  gitlabAuthorId?: Maybe<Scalars['Float']>;
  gitlabAuthorName?: Maybe<Scalars['String']>;
  gitlabAuthorUsername?: Maybe<Scalars['String']>;
  gitlabBoardId?: Maybe<Scalars['Float']>;
  gitlabBoardName?: Maybe<Scalars['String']>;
  gitlabBoardStateName?: Maybe<Scalars['String']>;
  gitlabDescription: Scalars['String'];
  gitlabGroupId?: Maybe<Scalars['Float']>;
  gitlabHasTasks?: Maybe<Scalars['Boolean']>;
  gitlabId: Scalars['Float'];
  gitlabIid: Scalars['Float'];
  gitlabLabels?: Maybe<Scalars['JSON']>;
  gitlabListId?: Maybe<Scalars['Float']>;
  gitlabListName?: Maybe<Scalars['String']>;
  gitlabListPosition?: Maybe<Scalars['Float']>;
  gitlabMilestoneDescription?: Maybe<Scalars['String']>;
  gitlabMilestoneId?: Maybe<Scalars['Float']>;
  gitlabMilestoneIid?: Maybe<Scalars['Float']>;
  gitlabMilestoneTitle?: Maybe<Scalars['String']>;
  gitlabProjectId: Scalars['Float'];
  gitlabProjectName?: Maybe<Scalars['String']>;
  gitlabStateName: Scalars['String'];
  gitlabTimeEstimate: Scalars['Float'];
  gitlabTitle: Scalars['String'];
  gitlabTotalTimeSpent: Scalars['Float'];
  gitlabUrl: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type GitlabIssueCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<GitlabIssueWhereUniqueInput>>;
  create?: InputMaybe<Array<GitlabIssueCreateWithoutDiscoveryInput>>;
};

export type GitlabIssueCreateWithOptionsInput = {
  copyAttachments?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<GitlabIssueCreateWithoutDiscoveryInput>;
};

export type GitlabIssueCreateWithoutDiscoveryInput = {
  gitlabAssigneeAvatarUrl?: InputMaybe<Scalars['String']>;
  gitlabAssigneeId?: InputMaybe<Scalars['Int']>;
  gitlabAssigneeName?: InputMaybe<Scalars['String']>;
  gitlabAssigneeUrl?: InputMaybe<Scalars['String']>;
  gitlabAssigneeUsername?: InputMaybe<Scalars['String']>;
  gitlabAuthorId?: InputMaybe<Scalars['Int']>;
  gitlabAuthorName?: InputMaybe<Scalars['String']>;
  gitlabAuthorUsername?: InputMaybe<Scalars['String']>;
  gitlabBoardId?: InputMaybe<Scalars['Int']>;
  gitlabBoardName?: InputMaybe<Scalars['String']>;
  gitlabBoardStateName?: InputMaybe<Scalars['String']>;
  gitlabDescription: Scalars['String'];
  gitlabGroupId?: InputMaybe<Scalars['Int']>;
  gitlabHasTasks?: InputMaybe<Scalars['Boolean']>;
  gitlabId: Scalars['Int'];
  gitlabIid: Scalars['Int'];
  gitlabLabels?: InputMaybe<Scalars['JSON']>;
  gitlabListId?: InputMaybe<Scalars['Int']>;
  gitlabListName?: InputMaybe<Scalars['String']>;
  gitlabListPosition?: InputMaybe<Scalars['Float']>;
  gitlabMilestoneDescription?: InputMaybe<Scalars['String']>;
  gitlabMilestoneId?: InputMaybe<Scalars['Int']>;
  gitlabMilestoneIid?: InputMaybe<Scalars['Int']>;
  gitlabMilestoneTitle?: InputMaybe<Scalars['String']>;
  gitlabProjectId: Scalars['Int'];
  gitlabProjectName?: InputMaybe<Scalars['String']>;
  gitlabStateName: Scalars['String'];
  gitlabTimeEstimate: Scalars['Float'];
  gitlabTitle: Scalars['String'];
  gitlabTotalTimeSpent: Scalars['Float'];
  gitlabUrl: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export enum GitlabIssueOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type GitlabIssueUpdateManyWithOptionsInput = {
  create?: InputMaybe<GitlabIssueCreateWithOptionsInput>;
  delete?: InputMaybe<GitlabIssueWhereUniqueInput>;
};

export type GitlabIssueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Hfc_Launcher_Style {
  FloatingCircle = 'FLOATING_CIRCLE',
  None = 'NONE',
  SideVignette = 'SIDE_VIGNETTE',
  SideVignetteLight = 'SIDE_VIGNETTE_LIGHT'
}

export enum Hfc_Privacy {
  EmailRequired = 'EMAIL_REQUIRED'
}

export enum Hfc_Widget_Install_Type {
  BasicJs = 'BASIC_JS',
  Sdk = 'SDK'
}

export enum Hfc_Widget_Open_Style {
  FullScreen = 'FULL_SCREEN',
  Menu = 'MENU',
  Modal = 'MODAL'
}

export enum Hfc_Widget_Position {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export enum Identity_Provider_Protocol {
  Default = 'DEFAULT',
  Saml = 'SAML'
}

export enum Integration_Sync_Type {
  Freshdesk = 'FRESHDESK',
  Hfc = 'HFC',
  Hubspot = 'HUBSPOT',
  Intercom = 'INTERCOM',
  PublicApi = 'PUBLIC_API',
  Salesforce = 'SALESFORCE',
  Zendesk = 'ZENDESK'
}

export type IntegrationLinkCreateInput = {
  integrationId: Scalars['String'];
  source: Channel;
};

export type IntegrationSync = {
  __typename: 'IntegrationSync';
  createdAt: Scalars['DateTime'];
  elementApiUrl?: Maybe<Scalars['String']>;
  elementId: Scalars['String'];
  elementProjectId?: Maybe<Scalars['String']>;
  elementType?: Maybe<Scalars['String']>;
  elementUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration_type: Integration_Sync_Type;
  lastUpdateAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type IntegrationSyncCreateInput = {
  elementApiUrl?: InputMaybe<Scalars['String']>;
  elementId: Scalars['String'];
  elementProjectId?: InputMaybe<Scalars['String']>;
  elementType?: InputMaybe<Scalars['String']>;
  elementUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  integration_type: Integration_Sync_Type;
  lastUpdateAt?: InputMaybe<Scalars['DateTime']>;
};

export type IntegrationSyncCreateManyInput = {
  connect?: InputMaybe<Array<IntegrationSyncWhereUniqueInput>>;
  create?: InputMaybe<Array<IntegrationSyncCreateInput>>;
};

export type IntegrationSyncWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Invite = {
  __typename: 'Invite';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  project: Project;
  right: Right;
  seatType?: Maybe<Seat_Type>;
  teammateRole?: Maybe<TeammateRole>;
  updatedAt: Scalars['DateTime'];
};

export type InviteCreateInput = {
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  right: Right;
  seatType?: InputMaybe<Seat_Type>;
  teammateRole?: InputMaybe<TeammateRoleConnectOnlyInput>;
};

export enum InviteOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RightAsc = 'right_ASC',
  RightDesc = 'right_DESC',
  SeatTypeAsc = 'seatType_ASC',
  SeatTypeDesc = 'seatType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type InviteWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type JiraIssue = {
  __typename: 'JiraIssue';
  createdAt: Scalars['DateTime'];
  discovery: Discovery;
  id: Scalars['ID'];
  jiraAssigneeFullname?: Maybe<Scalars['String']>;
  jiraAssigneeId?: Maybe<Scalars['String']>;
  jiraAttachments?: Maybe<Scalars['JSON']>;
  jiraComments?: Maybe<Scalars['JSON']>;
  jiraDescription?: Maybe<Scalars['String']>;
  jiraEpicName?: Maybe<Scalars['String']>;
  jiraId: Scalars['String'];
  jiraKey: Scalars['String'];
  jiraLabels?: Maybe<Scalars['JSON']>;
  jiraParentKey?: Maybe<Scalars['String']>;
  jiraPriorityId?: Maybe<Scalars['String']>;
  jiraPriorityName?: Maybe<Scalars['String']>;
  jiraProgressOnIssue: Scalars['String'];
  jiraProgressTotal: Scalars['String'];
  jiraProjectAvatarUrls: Scalars['JSON'];
  jiraProjectId: Scalars['String'];
  jiraProjectName: Scalars['String'];
  jiraProjectUrl: Scalars['String'];
  jiraReporterFullname: Scalars['String'];
  jiraReporterId: Scalars['String'];
  jiraSprintEndDate?: Maybe<Scalars['DateTime']>;
  jiraSprintId?: Maybe<Scalars['Float']>;
  jiraSprintName?: Maybe<Scalars['String']>;
  jiraSprintStartDate?: Maybe<Scalars['DateTime']>;
  jiraStateId: Scalars['String'];
  jiraStateName: Scalars['String'];
  jiraStatePosition: Scalars['Float'];
  jiraStoryPoints?: Maybe<Scalars['Float']>;
  jiraSubtasksCount: Scalars['Float'];
  jiraTitle: Scalars['String'];
  jiraTypeId: Scalars['String'];
  jiraTypeName: Scalars['String'];
  jiraUrl: Scalars['String'];
  jiraWebhookId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type JiraIssueCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<JiraIssueWhereUniqueInput>>;
  create?: InputMaybe<Array<JiraIssueCreateWithoutDiscoveryInput>>;
};

export type JiraIssueCreateWithOptionsInput = {
  copyAttachments?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<JiraIssueCreateWithoutDiscoveryInput>;
};

export type JiraIssueCreateWithoutDiscoveryInput = {
  id?: InputMaybe<Scalars['ID']>;
  jiraAssigneeFullname?: InputMaybe<Scalars['String']>;
  jiraAssigneeId?: InputMaybe<Scalars['String']>;
  jiraAttachments?: InputMaybe<Scalars['JSON']>;
  jiraComments?: InputMaybe<Scalars['JSON']>;
  jiraDescription?: InputMaybe<Scalars['String']>;
  jiraEpicName?: InputMaybe<Scalars['String']>;
  jiraId: Scalars['ID'];
  jiraKey: Scalars['String'];
  jiraLabels?: InputMaybe<Scalars['JSON']>;
  jiraParentKey?: InputMaybe<Scalars['String']>;
  jiraPriorityId?: InputMaybe<Scalars['String']>;
  jiraPriorityName?: InputMaybe<Scalars['String']>;
  jiraProgressOnIssue: Scalars['Float'];
  jiraProgressTotal: Scalars['Float'];
  jiraProjectAvatarUrls: Scalars['JSON'];
  jiraProjectId: Scalars['String'];
  jiraProjectName: Scalars['String'];
  jiraProjectUrl: Scalars['String'];
  jiraReporterFullname: Scalars['String'];
  jiraReporterId: Scalars['String'];
  jiraSprintId?: InputMaybe<Scalars['Float']>;
  jiraSprintName?: InputMaybe<Scalars['String']>;
  jiraStateId: Scalars['String'];
  jiraStateName: Scalars['String'];
  jiraStatePosition: Scalars['Float'];
  jiraStoryPoints?: InputMaybe<Scalars['Float']>;
  jiraSubtasksCount: Scalars['Int'];
  jiraTitle: Scalars['String'];
  jiraTypeId: Scalars['String'];
  jiraTypeName: Scalars['String'];
  jiraUrl: Scalars['String'];
  jiraWebhookId?: InputMaybe<Scalars['String']>;
};

export enum JiraIssueOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type JiraIssueUpdateManyWithOptionsInput = {
  create?: InputMaybe<JiraIssueCreateWithOptionsInput>;
  delete?: InputMaybe<JiraIssueWhereUniqueInput>;
};

export type JiraIssueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Labels_Search_Behaviour {
  Exact = 'EXACT',
  ExactNot = 'EXACT_NOT',
  Include = 'INCLUDE',
  IsEmpty = 'IS_EMPTY'
}

export type LinearIssue = {
  __typename: 'LinearIssue';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lin_assignee?: Maybe<Scalars['String']>;
  lin_description?: Maybe<Scalars['String']>;
  lin_identifier: Scalars['String'];
  lin_labels: Array<Scalars['String']>;
  lin_priority: Scalars['String'];
  lin_project?: Maybe<Scalars['String']>;
  lin_state?: Maybe<Scalars['String']>;
  lin_team: Scalars['String'];
  lin_title: Scalars['String'];
  lin_url?: Maybe<Scalars['String']>;
};

export enum LinearIssueOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export enum Message_Label_Default_Creation_Behaviour {
  VisibleInHarvestr = 'VISIBLE_IN_HARVESTR'
}

export enum Message_Label_Integration_Access {
  ChromeWidget = 'CHROME_WIDGET',
  Harvestr = 'HARVESTR',
  Hubspot = 'HUBSPOT',
  Salesforce = 'SALESFORCE',
  Slack = 'SLACK'
}

export enum Message_Label_Source {
  Freshdesk = 'FRESHDESK',
  Intercom = 'INTERCOM',
  Salesforce = 'SALESFORCE',
  Zendesk = 'ZENDESK'
}

export enum Message_Template_Type {
  Form = 'FORM',
  Text = 'TEXT'
}

export enum Message_Type {
  Message = 'MESSAGE',
  Note = 'NOTE'
}

export type MergeDiscoveriesPropsInput = {
  attachments: Scalars['Boolean'];
  comments: Scalars['Boolean'];
  externalLinks: Scalars['Boolean'];
  feedback: Scalars['Boolean'];
  githubIssues: Scalars['Boolean'];
  gitlabIssues: Scalars['Boolean'];
  jiraIssues: Scalars['Boolean'];
  projects: Scalars['Boolean'];
  relatedDiscoveries: Scalars['Boolean'];
  subDiscoveries: Scalars['Boolean'];
  tags: Scalars['Boolean'];
  trelloIssues: Scalars['Boolean'];
};

export type MergedDiscoveryResult = {
  __typename: 'MergedDiscoveryResult';
  deletetionSkipReason?: Maybe<Scalars['String']>;
  discoveryId: Scalars['String'];
  state: MergedDiscoveryState;
};

export enum MergedDiscoveryState {
  MergedAndDeleted = 'MERGED_AND_DELETED',
  MergedOnly = 'MERGED_ONLY',
  Untouched = 'UNTOUCHED'
}

export type Message = {
  __typename: 'Message';
  archived: Scalars['Boolean'];
  assignee?: Maybe<Person>;
  attachments?: Maybe<Array<Attachment>>;
  canSummarize: Scalars['Boolean'];
  caption?: Maybe<Scalars['String']>;
  channel: Channel;
  chunks?: Maybe<Array<Chunk>>;
  comments?: Maybe<Array<Comment>>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integration_id?: Maybe<Scalars['String']>;
  integration_url?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<MessageLabel>>;
  processed: Scalars['Boolean'];
  read: Scalars['Boolean'];
  requester: Person;
  sub_messages?: Maybe<Array<Submessage>>;
  submessageBeforeUpdateId?: Maybe<Scalars['String']>;
  submitter: Person;
  suggestedChunks: Array<SuggestedChunk>;
  suggestedThemes: Array<SuggestedTheme>;
  summary?: Maybe<Scalars['String']>;
  summaryPending: Scalars['Boolean'];
  title: Scalars['String'];
  updated: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type MessageAttachmentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttachmentOrderByInput>;
};


export type MessageCommentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentOrderByInput>;
};


export type MessageLabelsArgs = {
  orderBy?: InputMaybe<MessageLabelOrderByInput>;
};


export type MessageSub_MessagesArgs = {
  orderBy?: InputMaybe<SubmessageOrderByInput>;
};

export type MessageConnection = {
  __typename: 'MessageConnection';
  edges: Array<Maybe<MessageEdge>>;
  pageInfo: PageInfo;
};

export type MessageCreateInput = {
  channel?: InputMaybe<Channel>;
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  labels?: InputMaybe<MessageLabelCreateManyWithoutMessagesInput>;
  processed?: InputMaybe<Scalars['Boolean']>;
  requester?: InputMaybe<PersonCreateOneWithoutRequested_MessagesInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type MessageCreateMany = {
  message: MessageCreateInput;
  requester?: InputMaybe<PersonCreateInput>;
};

export type MessageCreateManyWithoutCcsInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
};

export type MessageCreateManyWithoutRequesterInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
};

export type MessageCreateManyWithoutSubmitterInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
};

export type MessageCreateOneWithoutChunksInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
};

export type MessageCreateOneWithoutCommentsInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
};

export type MessageEdge = {
  __typename: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessageLabel = {
  __typename: 'MessageLabel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integrationsAccess: Array<Message_Label_Integration_Access>;
  messageCount?: Maybe<Scalars['Float']>;
  messages?: Maybe<Array<Message>>;
  name: Scalars['String'];
  sources: Array<Message_Label_Source>;
  updatedAt: Scalars['DateTime'];
};

export type MessageLabelBulkLinkUnlinkInput = {
  connect?: InputMaybe<Array<Scalars['ID']>>;
  disconnect?: InputMaybe<Array<Scalars['ID']>>;
};

export type MessageLabelCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  integrationsAccess?: InputMaybe<MessageLabelSetIntegrationsAccessInput>;
  name: Scalars['String'];
};

export type MessageLabelCreateManyWithoutMessagesInput = {
  connect?: InputMaybe<Array<MessageLabelWhereUniqueInput>>;
};

export type MessageLabelCreateOneInput = {
  connect: MessageLabelWhereUniqueInput;
};

export enum MessageLabelOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IntegrationsAccessAsc = 'integrationsAccess_ASC',
  NameAsc = 'name_ASC',
  SourcesAsc = 'sources_ASC'
}

export type MessageLabelSetIntegrationsAccessInput = {
  set?: InputMaybe<Array<Message_Label_Integration_Access>>;
};

export type MessageLabelUpdateDataInput = {
  integrationsAccess?: InputMaybe<MessageLabelSetIntegrationsAccessInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type MessageLabelUpdateManyWithoutMessagesInput = {
  connect?: InputMaybe<Array<MessageLabelWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<MessageLabelWhereUniqueInput>>;
};

export type MessageLabelWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
};

export type MessageLabelWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum MessageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MessageSubscriptionPayload = {
  __typename: 'MessageSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Message>;
};

export enum MessageSuggestionDecision {
  Accepted = 'ACCEPTED',
  ChangedDiscovery = 'CHANGED_DISCOVERY',
  Rejected = 'REJECTED'
}

export type MessageSummaryStreamSubscriptionPayload = {
  __typename: 'MessageSummaryStreamSubscriptionPayload';
  id: Scalars['String'];
  summary: Scalars['String'];
};

export type MessageTemplate = {
  __typename: 'MessageTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messageContent?: Maybe<Scalars['String']>;
  messageFormFields: Array<MessageTemplateFormField>;
  messageTitle?: Maybe<Scalars['String']>;
  messageTitleLabel?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MessageTemplateCreateInput = {
  id: Scalars['ID'];
  messageContent?: InputMaybe<Scalars['String']>;
  messageTemplateType: Message_Template_Type;
  messageTitle?: InputMaybe<Scalars['String']>;
  messageTitleLabel?: InputMaybe<Scalars['String']>;
  position: Scalars['Float'];
  title: Scalars['String'];
};

export type MessageTemplateCreateOneInput = {
  connect?: InputMaybe<MessageTemplateWhereUniqueInput>;
};

export type MessageTemplateFormField = {
  __typename: 'MessageTemplateFormField';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  position: Scalars['Float'];
  required: Scalars['Boolean'];
  selectableElements: Array<MessageTemplateFormFieldSelectElement>;
  type: Template_Form_Type;
  updatedAt: Scalars['DateTime'];
};

export type MessageTemplateFormFieldCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  position: Scalars['Float'];
  required: Scalars['Boolean'];
  selectableElements?: InputMaybe<MessageTemplateFormFieldSelectElementCreateManyInput>;
  type: Template_Form_Type;
};

export enum MessageTemplateFormFieldOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MessageTemplateFormFieldSelectElement = {
  __typename: 'MessageTemplateFormFieldSelectElement';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messageLabel: MessageLabel;
  name: Scalars['String'];
  position: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type MessageTemplateFormFieldSelectElementCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  messageLabel: MessageLabelCreateOneInput;
  name: Scalars['String'];
  position: Scalars['Float'];
};

export type MessageTemplateFormFieldSelectElementCreateManyInput = {
  create: Array<MessageTemplateFormFieldSelectElementCreateInput>;
};

export type MessageTemplateFormFieldSelectElementUpdateDataInput = {
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
};

export type MessageTemplateFormFieldSelectElementUpdateManyInput = {
  create?: InputMaybe<MessageTemplateFormFieldSelectElementCreateInput>;
  delete?: InputMaybe<MessageTemplateFormFieldSelectElementWhereUniqueInput>;
  update?: InputMaybe<MessageTemplateFormFieldSelectElementUpdateWithWhereUniqueInput>;
};

export type MessageTemplateFormFieldSelectElementUpdateWithWhereUniqueInput = {
  data: MessageTemplateFormFieldSelectElementUpdateDataInput;
  where: MessageTemplateFormFieldSelectElementWhereUniqueInput;
};

export type MessageTemplateFormFieldSelectElementWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageTemplateFormFieldUpdateDataInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  required?: InputMaybe<Scalars['Boolean']>;
  selectableElements?: InputMaybe<MessageTemplateFormFieldSelectElementUpdateManyInput>;
  type?: InputMaybe<Template_Form_Type>;
};

export type MessageTemplateFormFieldUpdateOneInput = {
  connect?: InputMaybe<MessageTemplateFormFieldWhereUniqueInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
};

export type MessageTemplateFormFieldWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum MessageTemplateOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MessageTemplateUpdateDataInput = {
  messageContent?: InputMaybe<Scalars['String']>;
  messageTitle?: InputMaybe<Scalars['String']>;
  messageTitleLabel?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MessageTemplateUpdateOneWithoutApiTokensInput = {
  connect?: InputMaybe<MessageTemplateWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MessageTemplateWhereInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type MessageTemplateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageUpdateDataInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  assignee?: InputMaybe<PersonWhereUniqueInput>;
  content?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<MessageLabelUpdateManyWithoutMessagesInput>;
  processed?: InputMaybe<Scalars['Boolean']>;
  read?: InputMaybe<Scalars['Boolean']>;
  requester?: InputMaybe<PersonUpdateOneRequiredWithoutRequested_MessagesInput>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['Boolean']>;
};

export type MessageUpdateManyDataInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  assignee?: InputMaybe<PersonWhereUniqueInput>;
  labels?: InputMaybe<MessageLabelUpdateManyWithoutMessagesInput>;
  linkToDiscoveryId?: InputMaybe<Scalars['String']>;
  processed?: InputMaybe<Scalars['Boolean']>;
  requester?: InputMaybe<PersonUpdateOneRequiredWithoutRequested_MessagesInput>;
};

export type MessageWhereInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']>;
  assignee?: InputMaybe<PersonWhereInput>;
  channel?: InputMaybe<Channel>;
  channel_in?: InputMaybe<Array<Channel>>;
  chunks_every?: InputMaybe<ChunkWhereInput>;
  chunks_some?: InputMaybe<ChunkWhereInput>;
  computedContent_contains?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  integration_id?: InputMaybe<Scalars['String']>;
  integration_url?: InputMaybe<Scalars['String']>;
  labels_empty?: InputMaybe<Scalars['Boolean']>;
  labels_exact_and?: InputMaybe<Array<Scalars['String']>>;
  labels_exact_or?: InputMaybe<Array<Scalars['String']>>;
  labels_include_and?: InputMaybe<Array<Scalars['String']>>;
  labels_include_or?: InputMaybe<Array<Scalars['String']>>;
  labels_not_exact_and?: InputMaybe<Array<Scalars['String']>>;
  labels_not_exact_or?: InputMaybe<Array<Scalars['String']>>;
  labels_some?: InputMaybe<MessageLabelWhereUniqueInput>;
  processed?: InputMaybe<Scalars['Boolean']>;
  read?: InputMaybe<Scalars['Boolean']>;
  requester?: InputMaybe<PersonWhereInput>;
  submitter?: InputMaybe<PersonWhereInput>;
  suggestedChunksFilter?: InputMaybe<Custom_Inbox_Filter_Suggestion>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MessageWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  assignPersonsToCustomInbox?: Maybe<CustomInbox>;
  bulkLinkUnlinkMessageLabelView?: Maybe<Array<Maybe<MessageLabel>>>;
  changeDefaultDiscoverystate?: Maybe<Scalars['Boolean']>;
  connectDiscoveryDuplicateOf?: Maybe<Discovery>;
  connectRelatedDiscoveries?: Maybe<DiscoveryRelationshipMutationResponse>;
  createAccountPersonalAccessToken?: Maybe<Scalars['String']>;
  createAndLinkLinearIssue?: Maybe<LinearIssue>;
  createApitoken: ApiToken;
  createChunk?: Maybe<Chunk>;
  createCompany?: Maybe<Company>;
  createCompanySegment?: Maybe<CompanySegment>;
  createContributorAttribute?: Maybe<ContributorAttribute>;
  createCustomInbox?: Maybe<CustomInbox>;
  createDiscovery?: Maybe<Discovery>;
  createDiscoveryComment?: Maybe<Comment>;
  createDiscoveryfield?: Maybe<Discoveryfield>;
  createDiscoverystate?: Maybe<Discoverystate>;
  createFilterview?: Maybe<Filterview>;
  createInvite?: Maybe<Invite>;
  createMessage?: Maybe<Message>;
  createMessageComment?: Maybe<Comment>;
  createMessageLabel?: Maybe<MessageLabel>;
  createMessageTemplate?: Maybe<MessageTemplate>;
  createMessageTemplateFormField?: Maybe<MessageTemplateFormField>;
  createNotificationRule?: Maybe<NotificationRule>;
  createPerson?: Maybe<Person>;
  createPrivateApp: PrivateApp;
  createRoadmap?: Maybe<Roadmap>;
  createRoadmapFilterRow?: Maybe<RoadmapFilterRow>;
  createRoadmapProject?: Maybe<RoadmapProject>;
  createRoadmapProjectComment?: Maybe<Comment>;
  createRoadmapProjectCustomField?: Maybe<RoadmapProjectCustomField>;
  createRoadmapProjectCustomFieldOption?: Maybe<RoadmapProjectCustomFieldOption>;
  createRoadmapTheme?: Maybe<RoadmapTheme>;
  createRoadmapView?: Maybe<RoadmapView>;
  createRoadmapViewPersonRight?: Maybe<RoadmapViewPersonRight>;
  createRoadmapVote?: Maybe<RoadmapVote>;
  createTag?: Maybe<Tag>;
  createTeammateRole?: Maybe<TeammateRole>;
  createUsergroup?: Maybe<Usergroup>;
  createVirtualCompany?: Maybe<Company>;
  createVirtualPerson?: Maybe<Person>;
  createWebhook: Webhook;
  createWidgetFeedbackCollector?: Maybe<WidgetFeedbackCollector>;
  delegateRole?: Maybe<Person>;
  deleteAccountPersonalAccessToken?: Maybe<Scalars['String']>;
  deleteApitoken?: Maybe<ApiToken>;
  deleteChunk?: Maybe<Chunk>;
  deleteComment?: Maybe<Comment>;
  deleteCompany?: Maybe<Company>;
  deleteCompanySegment?: Maybe<CompanySegment>;
  deleteContributorAttribute?: Maybe<ContributorAttribute>;
  deleteCustomInbox?: Maybe<CustomInbox>;
  deleteDiscovery?: Maybe<Discovery>;
  deleteDiscoveryfield?: Maybe<Discoveryfield>;
  deleteDiscoverystate?: Maybe<Discoverystate>;
  deleteFilterview?: Maybe<Filterview>;
  deleteInvite?: Maybe<Invite>;
  deleteManyDiscoveries?: Maybe<BulkMutationResponseLegacy>;
  deleteManyMessageLabels?: Maybe<BulkMutationResponseLegacy>;
  deleteManyMessages?: Maybe<Scalars['Int']>;
  deleteMessage?: Maybe<Message>;
  deleteMessageLabel?: Maybe<MessageLabel>;
  deleteMessageTemplate?: Maybe<MessageTemplate>;
  deleteMessageTemplateFormField?: Maybe<MessageTemplateFormField>;
  deleteNotificationRule?: Maybe<NotificationRule>;
  deletePerson?: Maybe<Person>;
  deletePrivateApp: PrivateApp;
  deleteRoadmapFilterRow?: Maybe<RoadmapFilterRow>;
  deleteRoadmapProject?: Maybe<RoadmapProject>;
  deleteRoadmapProjectCustomField?: Maybe<RoadmapProjectCustomField>;
  deleteRoadmapProjectCustomFieldOption?: Maybe<RoadmapProjectCustomFieldOption>;
  deleteRoadmapTheme?: Maybe<RoadmapTheme>;
  deleteRoadmapView?: Maybe<RoadmapView>;
  deleteRoadmapViewPersonRight?: Maybe<RoadmapViewPersonRight>;
  deleteRoadmapVote?: Maybe<RoadmapVote>;
  deleteTag?: Maybe<Tag>;
  deleteTeammateRole?: Maybe<TeammateRole>;
  deleteUsergroup?: Maybe<Usergroup>;
  deleteWebhook: Webhook;
  deleteWidgetFeedbackCollector?: Maybe<WidgetFeedbackCollector>;
  disableTeammate?: Maybe<Person>;
  disconnectDiscoveryDuplicateOf?: Maybe<Discovery>;
  disconnectRelatedDiscoveries?: Maybe<DiscoveryRelationshipMutationResponse>;
  importManyMessages?: Maybe<Scalars['Boolean']>;
  linkCustomInbox?: Maybe<CustomInbox>;
  linkLinearIssue?: Maybe<LinearIssue>;
  linkMessageLabelView?: Maybe<MessageLabel>;
  mergeDiscoveries: Array<MergedDiscoveryResult>;
  moveManyChunks?: Maybe<Array<BulkMutationResponseElement>>;
  persistManySuggestedChunks: PersistManySuggestedChunksBulkResponse;
  persistSuggestedDiscovery: Discovery;
  readAllNotifications?: Maybe<Scalars['Boolean']>;
  readNotification?: Maybe<Notification>;
  rejectManySuggestedChunksOnDiscovery: RejectManySuggestedChunksOnDiscoveryBulkResponse;
  sendRoadmapViewInvite?: Maybe<RoadmapViewPersonRight>;
  summarizeDiscovery?: Maybe<Discovery>;
  summarizeMessage?: Maybe<Message>;
  unlinkCustomInbox?: Maybe<CustomInbox>;
  unlinkLinearIssue?: Maybe<LinearIssue>;
  unlinkMessageLabelView?: Maybe<MessageLabel>;
  updateApitoken: ApiToken;
  updateChunk?: Maybe<Chunk>;
  updateComment?: Maybe<Comment>;
  updateCompany?: Maybe<Company>;
  updateCompanySegment?: Maybe<CompanySegment>;
  updateContributorAttribute?: Maybe<ContributorAttribute>;
  updateCustomInbox?: Maybe<CustomInbox>;
  updateDiscovery?: Maybe<Discovery>;
  updateDiscoveryfield?: Maybe<Discoveryfield>;
  updateDiscoverystate?: Maybe<Discoverystate>;
  updateExternalLink?: Maybe<ExternalLink>;
  updateFilterview?: Maybe<Filterview>;
  updateManyDiscoveries?: Maybe<BulkMutationResponseLegacy>;
  updateManyMessage: Array<Maybe<Message>>;
  updateManyMessageLabels?: Maybe<Array<Maybe<MessageLabel>>>;
  updateManyRoadmapProjects?: Maybe<BulkMutationResponseLegacy>;
  updateMe: Account;
  updateMessage?: Maybe<Message>;
  updateMessageLabel?: Maybe<MessageLabel>;
  updateMessageTemplate?: Maybe<MessageTemplate>;
  updateMessageTemplateFormField?: Maybe<MessageTemplateFormField>;
  updateNotificationRule?: Maybe<NotificationRule>;
  updatePerson?: Maybe<Person>;
  updateProject?: Maybe<Project>;
  updateRoadmap?: Maybe<Roadmap>;
  updateRoadmapFilterRow?: Maybe<RoadmapFilterRow>;
  updateRoadmapProject?: Maybe<RoadmapProject>;
  updateRoadmapProjectCustomField?: Maybe<RoadmapProjectCustomField>;
  updateRoadmapProjectCustomFieldOption?: Maybe<RoadmapProjectCustomFieldOption>;
  updateRoadmapTheme?: Maybe<RoadmapTheme>;
  updateRoadmapView?: Maybe<RoadmapView>;
  updateRoadmapViewPersonRight?: Maybe<RoadmapViewPersonRight>;
  updateRoadmapVote?: Maybe<RoadmapVote>;
  updateTag?: Maybe<Tag>;
  updateTeammate?: Maybe<Person>;
  updateTeammateRole?: Maybe<TeammateRole>;
  updateTextSelection?: Maybe<TextSelection>;
  updateUsergroup?: Maybe<Usergroup>;
  updateWidgetFeedbackCollector?: Maybe<WidgetFeedbackCollector>;
  upsertManyContributorAttributes?: Maybe<Array<ContributorAttribute>>;
  watchDiscovery?: Maybe<Discovery>;
};


export type MutationAssignPersonsToCustomInboxArgs = {
  id: Scalars['ID'];
  personsIds: Array<Scalars['ID']>;
};


export type MutationBulkLinkUnlinkMessageLabelViewArgs = {
  data: MessageLabelBulkLinkUnlinkInput;
};


export type MutationChangeDefaultDiscoverystateArgs = {
  id: Scalars['ID'];
};


export type MutationConnectDiscoveryDuplicateOfArgs = {
  discoveryId: Scalars['ID'];
  duplicateOfDiscoveryId: Scalars['ID'];
};


export type MutationConnectRelatedDiscoveriesArgs = {
  firstDiscoveryId: Scalars['ID'];
  secondDiscoveryId: Scalars['ID'];
};


export type MutationCreateAndLinkLinearIssueArgs = {
  copyAttachments: Scalars['Boolean'];
  data: CreateLinearIssueInput;
  discoveryId: Scalars['String'];
};


export type MutationCreateApitokenArgs = {
  data: ApiTokenCreateInput;
};


export type MutationCreateChunkArgs = {
  data: ChunkCreateInput;
};


export type MutationCreateCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationCreateCompanySegmentArgs = {
  data: CompanySegmentCreateInput;
};


export type MutationCreateContributorAttributeArgs = {
  data: ContributorAttributeCreateInput;
};


export type MutationCreateCustomInboxArgs = {
  data: CustomInboxCreateInput;
};


export type MutationCreateDiscoveryArgs = {
  data: DiscoveryCreateInput;
};


export type MutationCreateDiscoveryCommentArgs = {
  data: CommentCreateInput;
  discoveryId: Scalars['ID'];
};


export type MutationCreateDiscoveryfieldArgs = {
  data: DiscoveryfieldCreateInput;
};


export type MutationCreateDiscoverystateArgs = {
  data: DiscoverystateCreateInput;
};


export type MutationCreateFilterviewArgs = {
  data: FilterviewCreateInput;
};


export type MutationCreateInviteArgs = {
  data: InviteCreateInput;
};


export type MutationCreateMessageArgs = {
  data: MessageCreateInput;
};


export type MutationCreateMessageCommentArgs = {
  data: CommentCreateInput;
  messageId: Scalars['ID'];
};


export type MutationCreateMessageLabelArgs = {
  data: MessageLabelCreateInput;
};


export type MutationCreateMessageTemplateArgs = {
  data: MessageTemplateCreateInput;
};


export type MutationCreateMessageTemplateFormFieldArgs = {
  data: MessageTemplateFormFieldCreateInput;
  messageTemplateId: Scalars['ID'];
};


export type MutationCreateNotificationRuleArgs = {
  data: NotificationRuleCreateInput;
};


export type MutationCreatePersonArgs = {
  data: PersonCreateInput;
};


export type MutationCreatePrivateAppArgs = {
  data: PrivateAppCreateInput;
};


export type MutationCreateRoadmapArgs = {
  data: RoadmapCreateInput;
};


export type MutationCreateRoadmapFilterRowArgs = {
  data: RoadmapFilterRowCreateInput;
  viewId: Scalars['ID'];
};


export type MutationCreateRoadmapProjectArgs = {
  data: RoadmapProjectCreateInput;
  roadmapId: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateRoadmapProjectCommentArgs = {
  data: CommentCreateInput;
  roadmapProjectId: Scalars['ID'];
};


export type MutationCreateRoadmapProjectCustomFieldArgs = {
  data: RoadmapProjectCustomFieldCreateInput;
  roadmapId: Scalars['ID'];
};


export type MutationCreateRoadmapProjectCustomFieldOptionArgs = {
  data: RoadmapProjectCustomFieldOptionCreateInput;
  roadmapProjectCustomFieldId: Scalars['ID'];
};


export type MutationCreateRoadmapThemeArgs = {
  data: RoadmapThemeCreateInput;
};


export type MutationCreateRoadmapViewArgs = {
  data: RoadmapViewCreateInput;
  roadmapId: Scalars['ID'];
};


export type MutationCreateRoadmapViewPersonRightArgs = {
  data: RoadmapViewPersonRightCreateInput;
  viewId: Scalars['ID'];
};


export type MutationCreateRoadmapVoteArgs = {
  data?: InputMaybe<RoadmapVoteCreateInput>;
  projectId: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateTagArgs = {
  data: TagCreateInput;
};


export type MutationCreateTeammateRoleArgs = {
  data: TeammateRoleCreateInput;
};


export type MutationCreateUsergroupArgs = {
  data: UsergroupCreateInput;
};


export type MutationCreateVirtualCompanyArgs = {
  data: VirtualCompanyCreateInput;
};


export type MutationCreateVirtualPersonArgs = {
  data: VirtualPersonCreateInput;
};


export type MutationCreateWebhookArgs = {
  data: WebhookCreateInput;
};


export type MutationCreateWidgetFeedbackCollectorArgs = {
  data: WidgetFeedbackCollectorCreateInput;
};


export type MutationDelegateRoleArgs = {
  where: PersonWhereUniqueInput;
};


export type MutationDeleteApitokenArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChunkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanySegmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContributorAttributeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomInboxArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscoveryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscoveryfieldArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscoverystateArgs = {
  id: Scalars['ID'];
  merge_id: Scalars['ID'];
};


export type MutationDeleteFilterviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteManyDiscoveriesArgs = {
  where: DiscoveryWhereInput;
};


export type MutationDeleteManyMessageLabelsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteManyMessagesArgs = {
  where: MessageWhereInput;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageLabelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageTemplateFormFieldArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePersonArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePrivateAppArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadmapFilterRowArgs = {
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteRoadmapProjectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadmapProjectCustomFieldArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadmapProjectCustomFieldOptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadmapThemeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadmapViewArgs = {
  viewId: Scalars['ID'];
};


export type MutationDeleteRoadmapViewPersonRightArgs = {
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteRoadmapVoteArgs = {
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeammateRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsergroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWidgetFeedbackCollectorArgs = {
  id: Scalars['ID'];
};


export type MutationDisableTeammateArgs = {
  id: Scalars['ID'];
};


export type MutationDisconnectDiscoveryDuplicateOfArgs = {
  discoveryId: Scalars['ID'];
};


export type MutationDisconnectRelatedDiscoveriesArgs = {
  firstDiscoveryId: Scalars['ID'];
  secondDiscoveryId: Scalars['ID'];
};


export type MutationImportManyMessagesArgs = {
  data: Array<MessageCreateMany>;
};


export type MutationLinkCustomInboxArgs = {
  id: Scalars['ID'];
};


export type MutationLinkLinearIssueArgs = {
  discoveryId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lineaIssueId: Scalars['String'];
};


export type MutationLinkMessageLabelViewArgs = {
  id: Scalars['ID'];
};


export type MutationMergeDiscoveriesArgs = {
  deleteMergedDiscoveries: Scalars['Boolean'];
  mergeIntoDiscoveryId: Scalars['ID'];
  mergeProps: MergeDiscoveriesPropsInput;
  toMergeDiscoveriesIds: Array<Scalars['ID']>;
};


export type MutationMoveManyChunksArgs = {
  data: ChunkMoveManyDataInput;
  ids: Array<Scalars['ID']>;
};


export type MutationPersistManySuggestedChunksArgs = {
  data: Array<PersistSuggestedChunkInput>;
};


export type MutationPersistSuggestedDiscoveryArgs = {
  data: PersistSuggestedDiscoveryInput;
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationRejectManySuggestedChunksOnDiscoveryArgs = {
  data: Array<RejectedSuggestedChunksOnDiscovery>;
};


export type MutationSendRoadmapViewInviteArgs = {
  id: Scalars['ID'];
};


export type MutationSummarizeDiscoveryArgs = {
  id: Scalars['ID'];
  outputLanguageIsoCode?: InputMaybe<Scalars['String']>;
  useMocking?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSummarizeMessageArgs = {
  id: Scalars['ID'];
  outputLanguageIsoCode?: InputMaybe<Scalars['String']>;
  useMocking?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUnlinkCustomInboxArgs = {
  id: Scalars['ID'];
};


export type MutationUnlinkLinearIssueArgs = {
  discoveryId: Scalars['String'];
  issueId: Scalars['String'];
};


export type MutationUnlinkMessageLabelViewArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateApitokenArgs = {
  data: ApiTokenUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateChunkArgs = {
  data: ChunkUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateWithoutDiscoveryDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateCompanyArgs = {
  data: CompanyUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateCompanySegmentArgs = {
  data?: InputMaybe<CompanySegmentUpdateDataInput>;
  id: Scalars['ID'];
};


export type MutationUpdateContributorAttributeArgs = {
  data: ContributorAttributeUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateCustomInboxArgs = {
  data: CustomInboxUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateDiscoveryArgs = {
  data: DiscoveryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateDiscoveryfieldArgs = {
  data: DiscoveryfieldUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateDiscoverystateArgs = {
  data: DiscoverystateUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateExternalLinkArgs = {
  data: ExternalLinkUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateFilterviewArgs = {
  data: FilterviewUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateManyDiscoveriesArgs = {
  data: DiscoveryUpdateManyDataInput;
  where: DiscoveryWhereInput;
};


export type MutationUpdateManyMessageArgs = {
  data: MessageUpdateManyDataInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateManyMessageLabelsArgs = {
  data: MessageLabelUpdateDataInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateManyRoadmapProjectsArgs = {
  data: RoadmapProjectUpdateManyDataInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateMeArgs = {
  data: AccountUpdateDataInput;
};


export type MutationUpdateMessageArgs = {
  data: MessageUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateMessageLabelArgs = {
  data: MessageLabelUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateMessageTemplateArgs = {
  data: MessageTemplateUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateMessageTemplateFormFieldArgs = {
  data: MessageTemplateFormFieldUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateNotificationRuleArgs = {
  data: NotificationRuleUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdatePersonArgs = {
  data: PersonUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
};


export type MutationUpdateRoadmapArgs = {
  data: RoadmapUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRoadmapFilterRowArgs = {
  data: RoadmapFilterRowUpdateInput;
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateRoadmapProjectArgs = {
  data: RoadmapProjectUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRoadmapProjectCustomFieldArgs = {
  data: RoadmapProjectCustomFieldUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRoadmapProjectCustomFieldOptionArgs = {
  data: RoadmapProjectCustomFieldOptionUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRoadmapThemeArgs = {
  data: RoadmapThemeUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRoadmapViewArgs = {
  data: RoadmapViewUpdateInput;
  viewId: Scalars['ID'];
};


export type MutationUpdateRoadmapViewPersonRightArgs = {
  data: RoadmapViewPersonRightUpdateInput;
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateRoadmapVoteArgs = {
  data?: InputMaybe<RoadmapVoteUpdateDataInput>;
  id: Scalars['ID'];
  viewId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateTagArgs = {
  data: TagUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeammateArgs = {
  data: TeammateUpdatePayload;
  where: PersonWhereUniqueInput;
};


export type MutationUpdateTeammateRoleArgs = {
  data: TeammateRoleUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTextSelectionArgs = {
  data: TextSelectionUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsergroupArgs = {
  data: UsergroupUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpdateWidgetFeedbackCollectorArgs = {
  data: WidgetFeedbackCollectorUpdateDataInput;
  id: Scalars['ID'];
};


export type MutationUpsertManyContributorAttributesArgs = {
  appliesTo: Attribute_Applies_To;
  create?: InputMaybe<Array<InputMaybe<ContributorAttributeCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ContributorAttributeWhereUniqueInput>>>;
  source: Contributor_Attribute_Source;
  update?: InputMaybe<Array<InputMaybe<ContributorAttributeUpdateDataInput>>>;
};


export type MutationWatchDiscoveryArgs = {
  id: Scalars['ID'];
  watching: Scalars['Boolean'];
};

export enum Notification_Rule_Action {
  ReopenIfClosed = 'REOPEN_IF_CLOSED',
  Standard = 'STANDARD'
}

export enum Notification_Rule_Trigger {
  DiscoverystateLinked = 'DISCOVERYSTATE_LINKED',
  MessageCommentCreate = 'MESSAGE_COMMENT_CREATE',
  MessageIsLinked = 'MESSAGE_IS_LINKED',
  MessageIsRead = 'MESSAGE_IS_READ',
  MessageIsReceived = 'MESSAGE_IS_RECEIVED'
}

export type Notification = {
  __typename: 'Notification';
  activity?: Maybe<Activity>;
  createdAt: Scalars['DateTime'];
  elasticSearchNotificationId: Scalars['String'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationConnection = {
  __typename: 'NotificationConnection';
  edges: Array<Maybe<NotificationEdge>>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename: 'NotificationEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export enum NotificationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReadAsc = 'read_ASC',
  ReadDesc = 'read_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type NotificationRule = {
  __typename: 'NotificationRule';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  triggerAction?: Maybe<Notification_Rule_Action>;
  triggerForState?: Maybe<Discoverystate>;
  triggerForStateType?: Maybe<Discoverystate_Type>;
  triggerWhen?: Maybe<Notification_Rule_Trigger>;
  updatedAt: Scalars['DateTime'];
};

export type NotificationRuleCreateInput = {
  apiToken: ApiTokenCreateOneInput;
  id: Scalars['ID'];
  triggerAction: Notification_Rule_Action;
  triggerForState?: InputMaybe<DiscoverystateCreateOneWithoutDiscoveriesInput>;
  triggerForStateType?: InputMaybe<Discoverystate_Type>;
  triggerWhen: Notification_Rule_Trigger;
};

export type NotificationRuleUpdateDataInput = {
  triggerAction?: InputMaybe<Notification_Rule_Action>;
  triggerForState?: InputMaybe<DiscoverystateUpdateOneWithoutDiscoveriesInput>;
  triggerForStateType?: InputMaybe<Discoverystate_Type>;
  triggerWhen?: InputMaybe<Notification_Rule_Trigger>;
};

export type NotificationRuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type NotificationWhereInput = {
  elasticSearchActivityId_gte?: InputMaybe<Scalars['String']>;
};

export enum Offset_Applies_To {
  Content = 'CONTENT',
  Summary = 'SUMMARY'
}

export enum Onboarding_Step {
  Step_0 = 'STEP_0',
  Step_1 = 'STEP_1',
  Step_2 = 'STEP_2',
  Step_3 = 'STEP_3',
  Step_4 = 'STEP_4',
  Step_5 = 'STEP_5',
  Step_6 = 'STEP_6'
}

export enum Operator_Type {
  BIs = 'B_IS',
  DEmpty = 'D_EMPTY',
  DGithubColumnName = 'D_GITHUB_COLUMN_NAME',
  DGithubMilestoneName = 'D_GITHUB_MILESTONE_NAME',
  DGithubRepositoryName = 'D_GITHUB_REPOSITORY_NAME',
  DGithubStateName = 'D_GITHUB_STATE_NAME',
  DGithubTitle = 'D_GITHUB_TITLE',
  DGitlabBoardName = 'D_GITLAB_BOARD_NAME',
  DGitlabBoardStateName = 'D_GITLAB_BOARD_STATE_NAME',
  DGitlabListName = 'D_GITLAB_LIST_NAME',
  DGitlabMilestoneName = 'D_GITLAB_MILESTONE_NAME',
  DGitlabProjectName = 'D_GITLAB_PROJECT_NAME',
  DGitlabStateName = 'D_GITLAB_STATE_NAME',
  DGitlabTitle = 'D_GITLAB_TITLE',
  DJiraEpicName = 'D_JIRA_EPIC_NAME',
  DJiraProjectName = 'D_JIRA_PROJECT_NAME',
  DJiraSprintName = 'D_JIRA_SPRINT_NAME',
  DJiraStateName = 'D_JIRA_STATE_NAME',
  DJiraTitle = 'D_JIRA_TITLE',
  DLinearStateName = 'D_LINEAR_STATE_NAME',
  DLinearTitle = 'D_LINEAR_TITLE',
  DNotEmpty = 'D_NOT_EMPTY',
  DTrelloBoardName = 'D_TRELLO_BOARD_NAME',
  DTrelloListName = 'D_TRELLO_LIST_NAME',
  DTrelloTitle = 'D_TRELLO_TITLE',
  MAll = 'M_ALL',
  MAny = 'M_ANY',
  MEmpty = 'M_EMPTY',
  MExactly = 'M_EXACTLY',
  MNone = 'M_NONE',
  MNotEmpty = 'M_NOT_EMPTY',
  SEqual = 'S_EQUAL',
  SGreaterThan = 'S_GREATER_THAN',
  SIs = 'S_IS',
  SLowerThan = 'S_LOWER_THAN',
  SNotEqual = 'S_NOT_EQUAL',
  TContains = 'T_CONTAINS',
  TEmpty = 'T_EMPTY',
  TExact = 'T_EXACT',
  TNotContain = 'T_NOT_CONTAIN',
  TNotEmpty = 'T_NOT_EMPTY'
}

export type OrganizationUpdateOneWithoutPersonsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export enum Person_Type {
  Collaborator = 'COLLABORATOR',
  Customer = 'CUSTOMER',
  OrganizationDefault = 'ORGANIZATION_DEFAULT'
}

export enum Private_App_Type {
  PublicApi = 'PUBLIC_API',
  Webhook = 'WEBHOOK'
}

export enum Prop_Ow_Rule {
  Always = 'ALWAYS',
  IfEmpty = 'IF_EMPTY',
  Never = 'NEVER'
}

export enum Public_Api_Scope {
  CreateFeedback = 'CREATE_FEEDBACK',
  ReadAttribute = 'READ_ATTRIBUTE',
  ReadCustomer = 'READ_CUSTOMER',
  ReadDiscovery = 'READ_DISCOVERY',
  ReadFeedback = 'READ_FEEDBACK',
  WriteAttribute = 'WRITE_ATTRIBUTE',
  WriteCustomer = 'WRITE_CUSTOMER'
}

export type PageAggregate = {
  __typename: 'PageAggregate';
  count?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PersistManySuggestedChunksBulkResponse = {
  __typename: 'PersistManySuggestedChunksBulkResponse';
  chunks: Array<PersistSuggestedChunkReponse>;
  success: Scalars['Boolean'];
};

export type PersistSuggestedChunkInput = {
  discoveryId: Scalars['String'];
  id: Scalars['String'];
  suggestedDiscoveryId: Scalars['String'];
};

export type PersistSuggestedChunkReponse = {
  __typename: 'PersistSuggestedChunkReponse';
  data?: Maybe<Chunk>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PersistSuggestedDiscoveryInput = {
  assigneeId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discoverystateId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Person = {
  __typename: 'Person';
  attributeValues?: Maybe<Array<ContributorAttributeValue>>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  details?: Maybe<Scalars['String']>;
  discoveries_number: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  emailValidated: Scalars['Boolean'];
  externalUid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationSyncs: Array<IntegrationSync>;
  isEditable?: Maybe<Scalars['Boolean']>;
  linked_messages_number: Scalars['Float'];
  messages_number: Scalars['Float'];
  name: Scalars['String'];
  organization?: Maybe<Company>;
  phone?: Maybe<Scalars['String']>;
  requested_messages?: Maybe<Array<Message>>;
  right: Right;
  seatType?: Maybe<Seat_Type>;
  submitted_messages?: Maybe<Array<Message>>;
  teammateRole?: Maybe<TeammateRole>;
  type: Person_Type;
  updatedAt: Scalars['DateTime'];
  usergroups?: Maybe<Array<Usergroup>>;
};

export type PersonConnection = {
  __typename: 'PersonConnection';
  edges: Array<Maybe<PersonEdge>>;
  pageInfo: PageInfo;
};

export type PersonCreateInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  externalUid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  organization?: InputMaybe<CompanyCreateOneWithoutPersonsInput>;
  usergroups?: InputMaybe<UsergroupCreateManyWithoutTagged_PersonsInput>;
};

export type PersonCreateManyInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
  create?: InputMaybe<Array<PersonCreateInput>>;
};

export type PersonCreateManyWithoutMentioned_CommentsInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
};

export type PersonCreateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
};

export type PersonCreateManyWithoutUsergroupsInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
};

export type PersonCreateOneInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
};

export type PersonCreateOneWithoutCommentsInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
};

export type PersonCreateOneWithoutRequested_MessagesInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
};

export type PersonCreateOneWithout_OrganizationAsDefaultInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
};

export type PersonEdge = {
  __typename: 'PersonEdge';
  cursor: Scalars['String'];
  node: Person;
};

export enum PersonOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAsc = 'deleted_ASC',
  DeletedDesc = 'deleted_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PersonUpdateDataInput = {
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutPersonsInput>;
  teammateRole?: InputMaybe<TeammateRoleConnectInput>;
  usergroups?: InputMaybe<UsergroupUpdateManyWithoutTagged_PersonsInput>;
};

export type PersonUpdateManyInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<PersonWhereUniqueInput>>;
};

export type PersonUpdateManyWithoutUsergroupsInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<PersonWhereUniqueInput>>;
};

export type PersonUpdateOneInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type PersonUpdateOneRequiredWithoutRequested_MessagesInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>;
};

export type PersonUpdateWithoutAccountDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type PersonWhereInput = {
  OR?: InputMaybe<Array<PersonWhereInput>>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  nameSearchIndex?: InputMaybe<Scalars['String']>;
  nameSearchIndex_contains?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<CompanyWhereInput>;
  pOrganizationAsDefault?: InputMaybe<CompanyWhereInput>;
  requested_messages_some?: InputMaybe<MessageWhereInput>;
  type?: InputMaybe<Person_Type>;
  type_not?: InputMaybe<Person_Type>;
  usergroups_some?: InputMaybe<UsergroupWhereInput>;
};

export type PersonWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PrivateApp = {
  __typename: 'PrivateApp';
  createdAt: Scalars['DateTime'];
  creator: Person;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastUseAt?: Maybe<Scalars['DateTime']>;
  readableScopes?: Maybe<Scalars['String']>;
  scopes: Array<Public_Api_Scope>;
  title: Scalars['String'];
  token: Scalars['String'];
  type?: Maybe<Private_App_Type>;
  updatedAt: Scalars['DateTime'];
};

export type PrivateAppCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  scopes: Array<Public_Api_Scope>;
  title: Scalars['String'];
  type?: InputMaybe<Private_App_Type>;
};

export type PrivateAppWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Project = {
  __typename: 'Project';
  allowedIdentityProviders: Array<Cloud_Identity_Provider>;
  authorizations: Authorizations;
  completedOnboardingSteps: Array<Onboarding_Step>;
  createdAt: Scalars['DateTime'];
  defaultMessageLabelCreationBehaviourFromIntegration: Array<Message_Label_Default_Creation_Behaviour>;
  harvestr_mail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityProviderProtocol?: Maybe<Identity_Provider_Protocol>;
  name: Scalars['String'];
  onboardingIsCompleted: Scalars['Boolean'];
  pictureUrl?: Maybe<Scalars['String']>;
  samlIdentityProviderMetadata?: Maybe<Scalars['String']>;
  teamInvite?: Maybe<ProjectTeamInvite>;
  updatedAt: Scalars['DateTime'];
};

export type ProjectCreateOneWithoutDiscoveriesInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
};

export type ProjectSubscriptionPayload = {
  __typename: 'ProjectSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Project>;
};

export type ProjectTeamInvite = {
  __typename: 'ProjectTeamInvite';
  active: Scalars['Boolean'];
  allowedDomains: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  project: Project;
  updatedAt: Scalars['DateTime'];
};

export type ProjectTeamInviteUpdateDataInput = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectTeamInviteUpdateOneInput = {
  update?: InputMaybe<ProjectTeamInviteUpdateDataInput>;
};

export type ProjectUpdateAllowedIdentityProvidersInput = {
  set?: InputMaybe<Array<Cloud_Identity_Provider>>;
};

export type ProjectUpdateCompletedOnboardingStepsInput = {
  set?: InputMaybe<Array<Onboarding_Step>>;
};

export type ProjectUpdateDefaultMessageLabelCreationBehaviourFromIntegrationInput = {
  set?: InputMaybe<Array<Message_Label_Default_Creation_Behaviour>>;
};

export type ProjectUpdateInput = {
  allowedIdentityProviders?: InputMaybe<ProjectUpdateAllowedIdentityProvidersInput>;
  completedOnboardingSteps?: InputMaybe<ProjectUpdateCompletedOnboardingStepsInput>;
  defaultMessageLabelCreationBehaviourFromIntegration?: InputMaybe<ProjectUpdateDefaultMessageLabelCreationBehaviourFromIntegrationInput>;
  identityProviderProtocol?: InputMaybe<Identity_Provider_Protocol>;
  name?: InputMaybe<Scalars['String']>;
  pictureUrl?: InputMaybe<Scalars['String']>;
  samlIdentityProviderMetadata?: InputMaybe<Scalars['String']>;
  teamInvite?: InputMaybe<ProjectTeamInviteUpdateOneInput>;
};

export type ProjectWhereUniqueInput = {
  harvestr_mail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PublicApiTokenWhereInput = {
  type?: InputMaybe<Private_App_Type>;
};

export type PublicLink = {
  __typename: 'PublicLink';
  createdAt: Scalars['DateTime'];
  creator?: Maybe<Person>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type PublicLinkCreateManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<PublicLinkWhereUniqueInput>>;
};

export type PublicLinkCreateOneWithoutDiscoveryInput = {
  connect?: InputMaybe<PublicLinkWhereUniqueInput>;
};

export type PublicLinkCreateWithoutDiscoveryInput = {
  confirm?: InputMaybe<Scalars['Boolean']>;
};

export type PublicLinkUpdateOneWithoutDiscoveryInput = {
  create?: InputMaybe<PublicLinkCreateWithoutDiscoveryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
};

export type PublicLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query = {
  __typename: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  acceptedSuggestionCount: Scalars['Int'];
  activitiesConnection: ActivityConnection;
  apitoken: ApiToken;
  apitokens: Array<ApiToken>;
  chunk: Chunk;
  chunks: Array<Chunk>;
  companies: Array<Company>;
  companiesConnection?: Maybe<CompanyConnection>;
  companiesCount?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companySegment?: Maybe<CompanySegment>;
  companySegments: Array<CompanySegment>;
  components: Array<Discovery>;
  contributorAttribute: ContributorAttribute;
  contributorAttributeValues: Array<ContributorAttributeValue>;
  contributorAttributes: Array<ContributorAttribute>;
  customInbox: CustomInbox;
  customInboxes: Array<CustomInbox>;
  discoveries: Array<Discovery>;
  discoveriesCount?: Maybe<Scalars['Int']>;
  discovery: Discovery;
  discoveryfield: Discoveryfield;
  discoveryfields: Array<Discoveryfield>;
  discoverystate?: Maybe<Discoverystate>;
  discoverystates?: Maybe<Array<Discoverystate>>;
  filterRows?: Maybe<Array<FilterRow>>;
  filterview?: Maybe<Filterview>;
  filterviews: Array<Filterview>;
  getFirstComponentId?: Maybe<Scalars['String']>;
  invite?: Maybe<Invite>;
  invites?: Maybe<Array<Invite>>;
  me: Account;
  message: Message;
  messageLabel?: Maybe<MessageLabel>;
  messageLabels?: Maybe<Array<Maybe<MessageLabel>>>;
  messageLabelsCount?: Maybe<Scalars['Int']>;
  messageTemplate?: Maybe<MessageTemplate>;
  messageTemplateFormField?: Maybe<MessageTemplateFormField>;
  messageTemplates?: Maybe<Array<MessageTemplate>>;
  messages?: Maybe<Array<Message>>;
  messagesConnection?: Maybe<MessageConnection>;
  messagesCount?: Maybe<Scalars['Int']>;
  myCustomInboxViews: Array<CustomInbox>;
  myMessageLabelViews?: Maybe<Array<Maybe<MessageLabel>>>;
  notification?: Maybe<Notification>;
  notificationsConnection: NotificationConnection;
  person?: Maybe<Person>;
  persons: Array<Person>;
  personsConnection?: Maybe<PersonConnection>;
  personsCount?: Maybe<Scalars['Int']>;
  personsOrganizationDefault?: Maybe<Array<Person>>;
  preloadRestData?: Maybe<RequiredRestFlatFieldsPayload>;
  privateApp: PrivateApp;
  privateApps: Array<PrivateApp>;
  project: Project;
  refreshDiscoveryScores: Array<DiscoveryScore>;
  roadmap?: Maybe<Roadmap>;
  roadmapComment?: Maybe<Comment>;
  roadmapFilterRow?: Maybe<RoadmapFilterRow>;
  roadmapFilterRows?: Maybe<Array<RoadmapFilterRow>>;
  roadmapProject?: Maybe<RoadmapProject>;
  roadmapProjectComments?: Maybe<Array<Comment>>;
  roadmapProjectCustomField?: Maybe<RoadmapProjectCustomField>;
  roadmapProjectCustomFieldOption?: Maybe<RoadmapProjectCustomFieldOption>;
  roadmapProjectCustomFields?: Maybe<Array<RoadmapProjectCustomField>>;
  roadmapProjects: Array<RoadmapProject>;
  roadmapTheme?: Maybe<RoadmapTheme>;
  roadmapThemes?: Maybe<Array<RoadmapTheme>>;
  roadmapView?: Maybe<RoadmapView>;
  roadmapViewPersonRight?: Maybe<RoadmapViewPersonRight>;
  roadmapViews?: Maybe<Array<RoadmapView>>;
  roadmapVote?: Maybe<RoadmapVote>;
  roadmaps?: Maybe<Array<Roadmap>>;
  suggestedChunks: Array<SuggestedChunk>;
  suggestedChunksCount: Scalars['Int'];
  suggestedDiscoveries: Array<SuggestedDiscovery>;
  suggestedDiscoveriesCount: Scalars['Int'];
  suggestedDiscovery?: Maybe<SuggestedDiscovery>;
  suggestedTheme?: Maybe<SuggestedTheme>;
  suggestedThemes: Array<SuggestedTheme>;
  tag?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  teammateRole?: Maybe<TeammateRole>;
  teammateRoles?: Maybe<Array<Maybe<TeammateRole>>>;
  textSelection?: Maybe<TextSelection>;
  usergroup?: Maybe<Usergroup>;
  usergroups?: Maybe<Array<Usergroup>>;
  virtualCompanies?: Maybe<Array<VirtualCompanyAsRequester>>;
  virtualContacts: Array<VirtualContact>;
  webhook: Webhook;
  webhooks: Array<Webhook>;
  widgetFeedbackCollector?: Maybe<WidgetFeedbackCollector>;
  widgetFeedbackCollectors?: Maybe<Array<WidgetFeedbackCollector>>;
};


export type QueryActivitiesConnectionArgs = {
  discoveryId?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Float'];
  offset: Scalars['Int'];
  roadmapProjectId?: InputMaybe<Scalars['ID']>;
  type: ActivityResourceType;
};


export type QueryApitokenArgs = {
  id: Scalars['ID'];
};


export type QueryApitokensArgs = {
  orderBy?: InputMaybe<ApiTokenOrderByInput>;
  where?: InputMaybe<ApiTokenWhereInput>;
};


export type QueryChunkArgs = {
  id: Scalars['ID'];
};


export type QueryChunksArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ChunkOrderByInput>;
  where?: InputMaybe<ChunkWhereInput>;
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompanyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: CompanyWhereInput;
};


export type QueryCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompanyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: CompanyWhereInput;
};


export type QueryCompaniesCountArgs = {
  where: CompanyWhereInput;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanySegmentArgs = {
  id: Scalars['ID'];
};


export type QueryCompanySegmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompanySegmentOrderByInput>;
  where: CompanySegmentWhereInput;
};


export type QueryComponentsArgs = {
  orderBy?: InputMaybe<DiscoveryOrderByInput>;
  where?: InputMaybe<ComponentWhereInput>;
};


export type QueryContributorAttributeArgs = {
  id: Scalars['ID'];
};


export type QueryContributorAttributesArgs = {
  orderBy?: InputMaybe<ContributorAttributeOrderByInput>;
  where?: InputMaybe<ContributorAttributeWhereInput>;
};


export type QueryCustomInboxArgs = {
  id: Scalars['ID'];
};


export type QueryCustomInboxesArgs = {
  orderBy?: InputMaybe<CustomInboxOrderByInput>;
  where?: InputMaybe<CustomInboxWhereInput>;
};


export type QueryDiscoveriesArgs = {
  orderBy?: InputMaybe<DiscoveryOrderByInput>;
  where?: InputMaybe<DiscoveryWhereInput>;
};


export type QueryDiscoveriesCountArgs = {
  where?: InputMaybe<DiscoveryWhereInput>;
};


export type QueryDiscoveryArgs = {
  id: Scalars['ID'];
};


export type QueryDiscoveryfieldArgs = {
  id: Scalars['ID'];
};


export type QueryDiscoveryfieldsArgs = {
  orderBy?: InputMaybe<DiscoveryfieldOrderByInput>;
  where?: InputMaybe<DiscoveryfieldWhereInput>;
};


export type QueryDiscoverystateArgs = {
  id: Scalars['ID'];
};


export type QueryDiscoverystatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DiscoverystateOrderByInput>;
  where: DiscoverystateWhereInput;
};


export type QueryFilterRowsArgs = {
  orderBy?: InputMaybe<FilterRowOrderByInput>;
  where: FilterRowWhereInput;
};


export type QueryFilterviewArgs = {
  id: Scalars['ID'];
};


export type QueryFilterviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FilterviewOrderByInput>;
  where: FilterviewWhereInput;
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QueryInvitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InviteOrderByInput>;
  where: InviteWhereInput;
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessageLabelArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMessageLabelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MessageLabelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageLabelWhereInput>;
};


export type QueryMessageLabelsCountArgs = {
  where?: InputMaybe<MessageLabelWhereInput>;
};


export type QueryMessageTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryMessageTemplateFormFieldArgs = {
  id: Scalars['ID'];
};


export type QueryMessageTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MessageTemplateOrderByInput>;
  where: MessageTemplateWhereInput;
};


export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryMessagesCountArgs = {
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryMyCustomInboxViewsArgs = {
  orderBy?: InputMaybe<CustomInboxOrderByInput>;
  where?: InputMaybe<CustomInboxWhereInput>;
};


export type QueryMyMessageLabelViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MessageLabelOrderByInput>;
  where?: InputMaybe<MessageLabelWhereInput>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NotificationOrderByInput>;
  where: NotificationWhereInput;
};


export type QueryPersonArgs = {
  id: Scalars['ID'];
};


export type QueryPersonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: PersonWhereInput;
};


export type QueryPersonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: PersonWhereInput;
};


export type QueryPersonsCountArgs = {
  where: PersonWhereInput;
};


export type QueryPreloadRestDataArgs = {
  where?: InputMaybe<PersonWhereInput>;
};


export type QueryPrivateAppArgs = {
  id: Scalars['ID'];
};


export type QueryPrivateAppsArgs = {
  where?: InputMaybe<PublicApiTokenWhereInput>;
};


export type QueryRefreshDiscoveryScoresArgs = {
  where: RefreshDiscoveryScoresWhereInput;
};


export type QueryRoadmapArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapCommentArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapFilterRowArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapFilterRowsArgs = {
  roadmapId: Scalars['ID'];
};


export type QueryRoadmapProjectArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapProjectCommentsArgs = {
  roadmapProjectId: Scalars['ID'];
};


export type QueryRoadmapProjectCustomFieldArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapProjectCustomFieldOptionArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapProjectCustomFieldsArgs = {
  roadmapId?: InputMaybe<Scalars['ID']>;
};


export type QueryRoadmapProjectsArgs = {
  discoveryId?: InputMaybe<Scalars['ID']>;
  viewId?: InputMaybe<Scalars['ID']>;
};


export type QueryRoadmapThemeArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapViewArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapViewPersonRightArgs = {
  id: Scalars['ID'];
};


export type QueryRoadmapViewsArgs = {
  roadmapId: Scalars['ID'];
};


export type QueryRoadmapVoteArgs = {
  id: Scalars['ID'];
};


export type QuerySuggestedChunksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuggestedChunkWhereInput>;
};


export type QuerySuggestedChunksCountArgs = {
  where?: InputMaybe<SuggestedChunkWhereInput>;
};


export type QuerySuggestedDiscoveriesArgs = {
  hasSuggestions?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<SuggestedChunksOnSuggestedDiscoveryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuggestedDiscoveryWhereInput>;
};


export type QuerySuggestedDiscoveriesCountArgs = {
  hasSuggestions?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SuggestedDiscoveryWhereCountInput>;
};


export type QuerySuggestedDiscoveryArgs = {
  discoveryId: Scalars['ID'];
};


export type QuerySuggestedThemeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySuggestedThemesArgs = {
  orderBy?: InputMaybe<SuggestedThemeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuggestedThemeWhereInput>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: TagWhereInput;
};


export type QueryTeammateRoleArgs = {
  id: Scalars['ID'];
};


export type QueryTeammateRolesArgs = {
  orderBy?: InputMaybe<TeammateRoleOrderByInput>;
};


export type QueryTextSelectionArgs = {
  id: Scalars['ID'];
};


export type QueryUsergroupArgs = {
  id: Scalars['ID'];
};


export type QueryUsergroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UsergroupOrderByInput>;
  where: UsergroupWhereInput;
};


export type QueryVirtualCompaniesArgs = {
  query: Scalars['String'];
};


export type QueryVirtualContactsArgs = {
  query: Scalars['String'];
};


export type QueryWebhookArgs = {
  id: Scalars['ID'];
};


export type QueryWidgetFeedbackCollectorArgs = {
  id: Scalars['ID'];
};


export type QueryWidgetFeedbackCollectorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WidgetFeedbackCollectorOrderByInput>;
  where?: InputMaybe<WidgetFeedbackCollectorWhereInput>;
};

export enum Right {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Viewer = 'VIEWER'
}

export enum Roadmap_Filterrow_Type {
  RoadmapCustomField = 'ROADMAP_CUSTOM_FIELD'
}

export enum Roadmap_Project_Custom_Field_Type {
  Multi = 'MULTI',
  Single = 'SINGLE'
}

export enum Roadmap_Project_Display_Attribute {
  Assignee = 'ASSIGNEE',
  Attachements = 'ATTACHEMENTS',
  Caption = 'CAPTION',
  DiscoveriesProgress = 'DISCOVERIES_PROGRESS',
  PrivateDescription = 'PRIVATE_DESCRIPTION',
  PublicDescription = 'PUBLIC_DESCRIPTION',
  Thumbnail = 'THUMBNAIL'
}

export enum Roadmap_Theme_Template {
  DarkColored = 'DARK_COLORED',
  DarkFlat = 'DARK_FLAT',
  LightColored = 'LIGHT_COLORED',
  LightFlat = 'LIGHT_FLAT'
}

export enum Roadmap_View_Display_Attribute {
  GroupsDescription = 'GROUPS_DESCRIPTION',
  ViewDescription = 'VIEW_DESCRIPTION',
  ViewLastUpdate = 'VIEW_LAST_UPDATE'
}

export enum Roadmap_View_Right {
  CanView = 'CAN_VIEW',
  CanVote = 'CAN_VOTE',
  FullAccess = 'FULL_ACCESS',
  NoAccess = 'NO_ACCESS'
}

export enum Roadmap_View_Type {
  Kanban = 'KANBAN'
}

export enum Roadmap_Vote_Value {
  Bad = 'BAD',
  Good = 'GOOD',
  Null = 'NULL'
}

export enum Role_Type {
  Contributor = 'CONTRIBUTOR',
  Editor = 'EDITOR'
}

export enum Row_Height {
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type RefreshDiscoveryScoresWhereInput = {
  discoveryfieldIds: Array<Scalars['ID']>;
};

export type RejectManySuggestedChunksOnDiscoveryBulkResponse = {
  __typename: 'RejectManySuggestedChunksOnDiscoveryBulkResponse';
  responses: Array<RejectSuggestedChunkOnDiscoveryResponse>;
  success: Scalars['Boolean'];
};

export type RejectSuggestedChunkOnDiscoveryResponse = {
  __typename: 'RejectSuggestedChunkOnDiscoveryResponse';
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RejectedSuggestedChunksOnDiscovery = {
  discoveryId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  suggestedDiscoveryId?: InputMaybe<Scalars['String']>;
};

export type RequiredRestFlatFieldsPayload = {
  __typename: 'RequiredRestFlatFieldsPayload';
  collaborators?: Maybe<Array<Person>>;
  companies?: Maybe<Array<Company>>;
  persons?: Maybe<Array<Person>>;
};


export type RequiredRestFlatFieldsPayloadCollaboratorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: PersonWhereInput;
};


export type RequiredRestFlatFieldsPayloadCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompanyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: CompanyWhereInput;
};


export type RequiredRestFlatFieldsPayloadPersonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where: PersonWhereInput;
};

export type Roadmap = {
  __typename: 'Roadmap';
  createdAt: Scalars['DateTime'];
  customFields: Array<RoadmapProjectCustomField>;
  defaultTheme?: Maybe<RoadmapTheme>;
  defaultThemeTemplate?: Maybe<Roadmap_Theme_Template>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organization: Project;
  position: Scalars['Float'];
  projects: Array<RoadmapProject>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  views: Array<RoadmapView>;
};


export type RoadmapCustomFieldsArgs = {
  first?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RoadmapProjectCustomFieldOrderByInput>;
};


export type RoadmapProjectsArgs = {
  first?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RoadmapProjectOrderByInput>;
};


export type RoadmapViewsArgs = {
  first?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RoadmapViewOrderByInput>;
};

export type RoadmapCreateInput = {
  defaultTheme?: InputMaybe<RoadmapDefaultThemeUpdateOneInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type RoadmapDefaultThemeUpdateOneInput = {
  connect?: InputMaybe<RoadmapThemeWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type RoadmapFilterRow = {
  __typename: 'RoadmapFilterRow';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  operator: Operator_Type;
  roadmapProjectCustomField?: Maybe<RoadmapProjectCustomField>;
  roadmapProjectCustomFieldOptionsList?: Maybe<Array<RoadmapProjectCustomFieldOption>>;
  type: Roadmap_Filterrow_Type;
  updatedAt: Scalars['DateTime'];
  view: RoadmapView;
};

export type RoadmapFilterRowCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  operator?: InputMaybe<Operator_Type>;
  roadmapProjectCustomField?: InputMaybe<RoadmapProjectCustomFieldCreateOneInput>;
  roadmapProjectCustomFieldOptionsList?: InputMaybe<RoadmapProjectCustomFieldOptionCreateManyInput>;
  type?: InputMaybe<Roadmap_Filterrow_Type>;
};

export type RoadmapFilterRowCreateManyInput = {
  connect?: InputMaybe<Array<RoadmapFilterRowWhereUniqueInput>>;
  create?: InputMaybe<Array<RoadmapFilterRowCreateInput>>;
};

export type RoadmapFilterRowUpdateInput = {
  operator?: InputMaybe<Operator_Type>;
  roadmapProjectCustomField?: InputMaybe<RoadmapProjectCustomFieldUpdateOneInput>;
  roadmapProjectCustomFieldOptionsList?: InputMaybe<RoadmapProjectCustomFieldOptionUpdateManyInput>;
  type?: InputMaybe<Roadmap_Filterrow_Type>;
};

export type RoadmapFilterRowUpdateManyWithoutViewInput = {
  create?: InputMaybe<Array<RoadmapFilterRowCreateInput>>;
  delete?: InputMaybe<Array<RoadmapFilterRowWhereUniqueInput>>;
  update?: InputMaybe<Array<RoadmapFilterRowUpdateWithWhereUniqueInput>>;
};

export type RoadmapFilterRowUpdateWithWhereUniqueInput = {
  data: RoadmapFilterRowUpdateInput;
  where: RoadmapFilterRowWhereUniqueInput;
};

export type RoadmapFilterRowWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RoadmapProject = {
  __typename: 'RoadmapProject';
  attachments: Array<Attachment>;
  caption?: Maybe<Scalars['String']>;
  comments: Array<Comment>;
  coverImage?: Maybe<Scalars['String']>;
  coverImageThumbnail?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discoveries: Array<Discovery>;
  id: Scalars['ID'];
  linkedCustomFieldOptions: Array<RoadmapProjectCustomFieldOption>;
  owners: Array<Person>;
  position: Scalars['Float'];
  privateDescription?: Maybe<Scalars['String']>;
  publicDescription?: Maybe<Scalars['String']>;
  roadmap: Roadmap;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  votes: Array<RoadmapVote>;
};


export type RoadmapProjectCommentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentOrderByInput>;
};


export type RoadmapProjectDiscoveriesArgs = {
  first?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DiscoveryOrderByInput>;
};


export type RoadmapProjectLinkedCustomFieldOptionsArgs = {
  orderBy?: InputMaybe<RoadmapProjectCustomFieldOrderByInput>;
};


export type RoadmapProjectOwnersArgs = {
  orderBy?: InputMaybe<PersonOrderByInput>;
};


export type RoadmapProjectVotesArgs = {
  orderBy?: InputMaybe<RoadmapVoteOrderByInput>;
};

export type RoadmapProjectCreateInput = {
  attachments?: InputMaybe<AttachmentCreateManyWithoutAttached_Roadmap_CardsInput>;
  caption?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  coverImageThumbnail?: InputMaybe<Scalars['String']>;
  discoveries?: InputMaybe<DiscoveryCreateManyInput>;
  id?: InputMaybe<Scalars['ID']>;
  linkedCustomFieldOptions?: InputMaybe<RoadmapProjectCustomFieldOptionCreateManyWithout_ProjectsInput>;
  owners?: InputMaybe<PersonCreateManyInput>;
  position?: InputMaybe<Scalars['Float']>;
  privateDescription?: InputMaybe<Scalars['String']>;
  publicDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type RoadmapProjectCreateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectWhereUniqueInput>>;
  create?: InputMaybe<Array<RoadmapProjectCreateInput>>;
};

export type RoadmapProjectCreateOneWithoutCommentsInput = {
  connect?: InputMaybe<RoadmapProjectWhereUniqueInput>;
};

export type RoadmapProjectCustomField = {
  __typename: 'RoadmapProjectCustomField';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  fieldOptions: Array<RoadmapProjectCustomFieldOption>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  roadmap: Roadmap;
  type: Roadmap_Project_Custom_Field_Type;
  updatedAt: Scalars['DateTime'];
};


export type RoadmapProjectCustomFieldFieldOptionsArgs = {
  orderBy?: InputMaybe<RoadmapProjectCustomFieldOptionOrderByInput>;
};

export type RoadmapProjectCustomFieldCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Float']>;
  type: Roadmap_Project_Custom_Field_Type;
};

export type RoadmapProjectCustomFieldCreateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldWhereUniqueInput>>;
};

export type RoadmapProjectCustomFieldCreateOneInput = {
  connect?: InputMaybe<RoadmapProjectCustomFieldWhereUniqueInput>;
};

export type RoadmapProjectCustomFieldOption = {
  __typename: 'RoadmapProjectCustomFieldOption';
  archived: Scalars['Boolean'];
  color?: Maybe<Color>;
  createdAt: Scalars['DateTime'];
  customField: RoadmapProjectCustomField;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type RoadmapProjectCustomFieldOptionCreateInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Color>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Float']>;
};

export type RoadmapProjectCustomFieldOptionCreateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
};

export type RoadmapProjectCustomFieldOptionCreateManyWithout_ProjectsInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
};

export enum RoadmapProjectCustomFieldOptionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoadmapProjectCustomFieldOptionSubscriptionPayload = {
  __typename: 'RoadmapProjectCustomFieldOptionSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<RoadmapProjectCustomFieldOption>;
};

export type RoadmapProjectCustomFieldOptionUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Color>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
};

export type RoadmapProjectCustomFieldOptionUpdateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
};

export type RoadmapProjectCustomFieldOptionUpdateManyWithout_ProjectsInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<RoadmapProjectCustomFieldOptionWhereUniqueInput>>;
};

export type RoadmapProjectCustomFieldOptionWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum RoadmapProjectCustomFieldOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoadmapProjectCustomFieldSubscriptionPayload = {
  __typename: 'RoadmapProjectCustomFieldSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<RoadmapProjectCustomField>;
};

export type RoadmapProjectCustomFieldUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Roadmap_Project_Custom_Field_Type>;
};

export type RoadmapProjectCustomFieldUpdateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectCustomFieldWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<RoadmapProjectCustomFieldWhereUniqueInput>>;
};

export type RoadmapProjectCustomFieldUpdateOneInput = {
  connect?: InputMaybe<RoadmapProjectCustomFieldWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type RoadmapProjectCustomFieldUpdateOneRequiredInput = {
  connect?: InputMaybe<RoadmapProjectCustomFieldWhereUniqueInput>;
};

export type RoadmapProjectCustomFieldWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RoadmapProjectCustomFieldWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RoadmapProjectDiscoveryUpdateManyInput = {
  connect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<DiscoveryWhereUniqueInput>>;
};

export enum RoadmapProjectOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoadmapProjectSubscriptionPayload = {
  __typename: 'RoadmapProjectSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<RoadmapProject>;
};

export type RoadmapProjectUpdateInput = {
  attachments?: InputMaybe<AttachmentUpdateManyWithoutAttached_Roadmap_CardsInput>;
  caption?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  coverImageThumbnail?: InputMaybe<Scalars['String']>;
  discoveries?: InputMaybe<DiscoveryUpdateManyInput>;
  linkedCustomFieldOptions?: InputMaybe<RoadmapProjectCustomFieldOptionUpdateManyWithout_ProjectsInput>;
  owners?: InputMaybe<PersonUpdateManyInput>;
  position?: InputMaybe<Scalars['Float']>;
  privateDescription?: InputMaybe<Scalars['String']>;
  publicDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RoadmapProjectUpdateManyDataInput = {
  discoveries?: InputMaybe<RoadmapProjectDiscoveryUpdateManyInput>;
};

export type RoadmapProjectUpdateManyInput = {
  connect?: InputMaybe<Array<RoadmapProjectWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<RoadmapProjectWhereUniqueInput>>;
};

export type RoadmapProjectWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RoadmapProjectWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RoadmapTheme = {
  __typename: 'RoadmapTheme';
  bannerBg?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dgBg?: Maybe<Scalars['String']>;
  dgBgGradient?: Maybe<Scalars['String']>;
  dgFooterBg?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedViews: Array<RoadmapView>;
  logoHref?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  organization: Project;
  organizationId: Scalars['String'];
  ow_azure?: Maybe<Scalars['String']>;
  ow_blue?: Maybe<Scalars['String']>;
  ow_green?: Maybe<Scalars['String']>;
  ow_grey?: Maybe<Scalars['String']>;
  ow_lblue?: Maybe<Scalars['String']>;
  ow_lgreen?: Maybe<Scalars['String']>;
  ow_lpink?: Maybe<Scalars['String']>;
  ow_lpurple?: Maybe<Scalars['String']>;
  ow_lyellow?: Maybe<Scalars['String']>;
  ow_orange?: Maybe<Scalars['String']>;
  ow_pink?: Maybe<Scalars['String']>;
  ow_purple?: Maybe<Scalars['String']>;
  ow_red?: Maybe<Scalars['String']>;
  ow_yellow?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  themeTemplate?: Maybe<Roadmap_Theme_Template>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vpBg?: Maybe<Scalars['String']>;
  vpBgGradient?: Maybe<Scalars['String']>;
  vpBgImgAlignHorizontal?: Maybe<Scalars['String']>;
  vpBgImgAlignVertical?: Maybe<Scalars['String']>;
  vpBgImgUrl?: Maybe<Scalars['String']>;
  vpCardPrimaryFont?: Maybe<Scalars['String']>;
  vpCardSecondaryFont?: Maybe<Scalars['String']>;
  vpFontFamily?: Maybe<Scalars['String']>;
  vpPrimaryFont?: Maybe<Scalars['String']>;
  vpSecondaryFont?: Maybe<Scalars['String']>;
  vpThemeColor?: Maybe<Scalars['String']>;
};

export type RoadmapThemeCreateInput = {
  bannerBg?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dgBg?: InputMaybe<Scalars['String']>;
  dgBgGradient?: InputMaybe<Scalars['String']>;
  dgFooterBg?: InputMaybe<Scalars['String']>;
  faviconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  logoHref?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  ow_azure?: InputMaybe<Scalars['String']>;
  ow_blue?: InputMaybe<Scalars['String']>;
  ow_green?: InputMaybe<Scalars['String']>;
  ow_grey?: InputMaybe<Scalars['String']>;
  ow_lblue?: InputMaybe<Scalars['String']>;
  ow_lgreen?: InputMaybe<Scalars['String']>;
  ow_lpink?: InputMaybe<Scalars['String']>;
  ow_lpurple?: InputMaybe<Scalars['String']>;
  ow_lyellow?: InputMaybe<Scalars['String']>;
  ow_orange?: InputMaybe<Scalars['String']>;
  ow_pink?: InputMaybe<Scalars['String']>;
  ow_purple?: InputMaybe<Scalars['String']>;
  ow_red?: InputMaybe<Scalars['String']>;
  ow_yellow?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  themeTemplate?: InputMaybe<Roadmap_Theme_Template>;
  title: Scalars['String'];
  vpBg?: InputMaybe<Scalars['String']>;
  vpBgGradient?: InputMaybe<Scalars['String']>;
  vpBgImgAlignHorizontal?: InputMaybe<Scalars['String']>;
  vpBgImgAlignVertical?: InputMaybe<Scalars['String']>;
  vpBgImgUrl?: InputMaybe<Scalars['String']>;
  vpCardPrimaryFont?: InputMaybe<Scalars['String']>;
  vpCardSecondaryFont?: InputMaybe<Scalars['String']>;
  vpFontFamily?: InputMaybe<Scalars['String']>;
  vpPrimaryFont?: InputMaybe<Scalars['String']>;
  vpSecondaryFont?: InputMaybe<Scalars['String']>;
  vpThemeColor?: InputMaybe<Scalars['String']>;
};

export type RoadmapThemeSubscriptionPayload = {
  __typename: 'RoadmapThemeSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<RoadmapTheme>;
};

export type RoadmapThemeUpdateInput = {
  bannerBg?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dgBg?: InputMaybe<Scalars['String']>;
  dgBgGradient?: InputMaybe<Scalars['String']>;
  dgFooterBg?: InputMaybe<Scalars['String']>;
  faviconUrl?: InputMaybe<Scalars['String']>;
  logoHref?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  ow_azure?: InputMaybe<Scalars['String']>;
  ow_blue?: InputMaybe<Scalars['String']>;
  ow_green?: InputMaybe<Scalars['String']>;
  ow_grey?: InputMaybe<Scalars['String']>;
  ow_lblue?: InputMaybe<Scalars['String']>;
  ow_lgreen?: InputMaybe<Scalars['String']>;
  ow_lpink?: InputMaybe<Scalars['String']>;
  ow_lpurple?: InputMaybe<Scalars['String']>;
  ow_lyellow?: InputMaybe<Scalars['String']>;
  ow_orange?: InputMaybe<Scalars['String']>;
  ow_pink?: InputMaybe<Scalars['String']>;
  ow_purple?: InputMaybe<Scalars['String']>;
  ow_red?: InputMaybe<Scalars['String']>;
  ow_yellow?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  themeTemplate?: InputMaybe<Roadmap_Theme_Template>;
  title?: InputMaybe<Scalars['String']>;
  vpBg?: InputMaybe<Scalars['String']>;
  vpBgGradient?: InputMaybe<Scalars['String']>;
  vpBgImgAlignHorizontal?: InputMaybe<Scalars['String']>;
  vpBgImgAlignVertical?: InputMaybe<Scalars['String']>;
  vpBgImgUrl?: InputMaybe<Scalars['String']>;
  vpCardPrimaryFont?: InputMaybe<Scalars['String']>;
  vpCardSecondaryFont?: InputMaybe<Scalars['String']>;
  vpFontFamily?: InputMaybe<Scalars['String']>;
  vpPrimaryFont?: InputMaybe<Scalars['String']>;
  vpSecondaryFont?: InputMaybe<Scalars['String']>;
  vpThemeColor?: InputMaybe<Scalars['String']>;
};

export type RoadmapThemeUpdateOneInput = {
  connect?: InputMaybe<RoadmapThemeWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type RoadmapThemeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RoadmapUpdateInput = {
  defaultTheme?: InputMaybe<RoadmapDefaultThemeUpdateOneInput>;
  defaultThemeTemplate?: InputMaybe<Roadmap_Theme_Template>;
  description?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RoadmapView = {
  __typename: 'RoadmapView';
  conjunction: Conjunction_Type;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayCardAttributes: Array<Roadmap_Project_Display_Attribute>;
  displayCardCustomFields: Array<RoadmapProjectCustomField>;
  displayCardHeader?: Maybe<RoadmapProjectCustomField>;
  displayProjectHolderAttributes: Array<Roadmap_Project_Display_Attribute>;
  displayProjectHolderCustomFields: Array<RoadmapProjectCustomField>;
  displayViewAttributes: Array<Roadmap_View_Display_Attribute>;
  filterRows: Array<RoadmapFilterRow>;
  groupBy: RoadmapProjectCustomField;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Float'];
  publicAccess?: Maybe<Scalars['Boolean']>;
  publicCanVote?: Maybe<Scalars['Boolean']>;
  publicSearchEngineIndexing?: Maybe<Scalars['Boolean']>;
  rightGuests?: Maybe<Roadmap_View_Right>;
  rightOwners?: Maybe<Roadmap_View_Right>;
  rightPersons: Array<RoadmapViewPersonRight>;
  roadmap: Roadmap;
  roadmapTheme?: Maybe<RoadmapTheme>;
  showEmptyGroups: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  subgroupBy?: Maybe<RoadmapProjectCustomField>;
  title: Scalars['String'];
  type: Roadmap_View_Type;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type RoadmapViewCreateInput = {
  conjunction?: InputMaybe<Conjunction_Type>;
  description?: InputMaybe<Scalars['String']>;
  displayCardAttributes?: InputMaybe<SetRoadmapProjectDisplayableAttribute>;
  displayCardCustomFields?: InputMaybe<RoadmapProjectCustomFieldCreateManyInput>;
  displayCardHeader?: InputMaybe<RoadmapProjectCustomFieldCreateOneInput>;
  displayProjectHolderAttributes?: InputMaybe<SetRoadmapProjectDisplayableAttribute>;
  displayProjectHolderCustomFields?: InputMaybe<RoadmapProjectCustomFieldCreateManyInput>;
  displayViewAttributes?: InputMaybe<SetRoadmapViewDisplayableAttribute>;
  filterRows?: InputMaybe<RoadmapFilterRowCreateManyInput>;
  groupBy: RoadmapProjectCustomFieldCreateOneInput;
  id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Float']>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
  publicCanVote?: InputMaybe<Scalars['Boolean']>;
  publicSearchEngineIndexing?: InputMaybe<Scalars['Boolean']>;
  rightGuests?: InputMaybe<Roadmap_View_Right>;
  rightOwners?: InputMaybe<Roadmap_View_Right>;
  roadmapTheme?: InputMaybe<RoadmapThemeUpdateOneInput>;
  showEmptyGroups?: InputMaybe<Scalars['Boolean']>;
  subgroupBy?: InputMaybe<RoadmapProjectCustomFieldCreateOneInput>;
  title: Scalars['String'];
  type?: InputMaybe<Roadmap_View_Type>;
};

export enum RoadmapViewOrderByInput {
  ConjunctionAsc = 'conjunction_ASC',
  ConjunctionDesc = 'conjunction_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoadmapViewPersonRight = {
  __typename: 'RoadmapViewPersonRight';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteSentAt?: Maybe<Scalars['DateTime']>;
  person?: Maybe<Person>;
  publicToken?: Maybe<Scalars['String']>;
  right: Roadmap_View_Right;
  updatedAt: Scalars['DateTime'];
  view: RoadmapView;
};

export type RoadmapViewPersonRightCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  inviteSentAt?: InputMaybe<Scalars['DateTime']>;
  person?: InputMaybe<PersonCreateOneInput>;
  publicToken?: InputMaybe<Scalars['String']>;
  right: Roadmap_View_Right;
};

export type RoadmapViewPersonRightCreateWithoutViewInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  inviteSentAt?: InputMaybe<Scalars['DateTime']>;
  person?: InputMaybe<PersonCreateOneInput>;
  publicToken?: InputMaybe<Scalars['String']>;
  right: Roadmap_View_Right;
};

export type RoadmapViewPersonRightUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  inviteSentAt?: InputMaybe<Scalars['DateTime']>;
  person?: InputMaybe<PersonUpdateOneInput>;
  publicToken?: InputMaybe<Scalars['String']>;
  right?: InputMaybe<Roadmap_View_Right>;
};

export type RoadmapViewPersonRightUpdateManyWithoutViewInput = {
  create?: InputMaybe<Array<RoadmapViewPersonRightCreateWithoutViewInput>>;
  delete?: InputMaybe<Array<RoadmapViewPersonRightWhereUniqueInput>>;
  update?: InputMaybe<Array<RoadmapViewPersonRightUpdateWithWhereUniqueInput>>;
};

export type RoadmapViewPersonRightUpdateWithWhereUniqueInput = {
  data: RoadmapViewPersonRightUpdateInput;
  where: RoadmapViewPersonRightWhereUniqueInput;
};

export type RoadmapViewPersonRightWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RoadmapViewSubscriptionPayload = {
  __typename: 'RoadmapViewSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<RoadmapView>;
};

export type RoadmapViewUpdateInput = {
  conjunction?: InputMaybe<Conjunction_Type>;
  description?: InputMaybe<Scalars['String']>;
  displayCardAttributes?: InputMaybe<SetRoadmapProjectDisplayableAttribute>;
  displayCardCustomFields?: InputMaybe<RoadmapProjectCustomFieldUpdateManyInput>;
  displayCardHeader?: InputMaybe<RoadmapProjectCustomFieldUpdateOneInput>;
  displayProjectHolderAttributes?: InputMaybe<SetRoadmapProjectDisplayableAttribute>;
  displayProjectHolderCustomFields?: InputMaybe<RoadmapProjectCustomFieldUpdateManyInput>;
  displayViewAttributes?: InputMaybe<SetRoadmapViewDisplayableAttribute>;
  filterRows?: InputMaybe<RoadmapFilterRowUpdateManyWithoutViewInput>;
  groupBy?: InputMaybe<RoadmapProjectCustomFieldUpdateOneRequiredInput>;
  position?: InputMaybe<Scalars['Float']>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
  publicCanVote?: InputMaybe<Scalars['Boolean']>;
  publicSearchEngineIndexing?: InputMaybe<Scalars['Boolean']>;
  rightGuests?: InputMaybe<Roadmap_View_Right>;
  rightOwners?: InputMaybe<Roadmap_View_Right>;
  rightPersons?: InputMaybe<RoadmapViewPersonRightUpdateManyWithoutViewInput>;
  roadmapTheme?: InputMaybe<RoadmapThemeUpdateOneInput>;
  showEmptyGroups?: InputMaybe<Scalars['Boolean']>;
  subgroupBy?: InputMaybe<RoadmapProjectCustomFieldUpdateOneInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Roadmap_View_Type>;
};

export type RoadmapViewWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RoadmapVote = {
  __typename: 'RoadmapVote';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  person?: Maybe<Person>;
  uniqueIdentifier?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Roadmap_Vote_Value;
};

export type RoadmapVoteCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  uniqueIdentifier?: InputMaybe<Scalars['String']>;
  value: Roadmap_Vote_Value;
};

export enum RoadmapVoteOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type RoadmapVoteUpdateDataInput = {
  uniqueIdentifier?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Roadmap_Vote_Value>;
};

export enum Seat_Type {
  External = 'EXTERNAL',
  Guest = 'GUEST',
  Owner = 'OWNER',
  PrimaryOwner = 'PRIMARY_OWNER'
}

export enum Sort_By {
  Assignee = 'ASSIGNEE',
  CalculatedScore = 'CALCULATED_SCORE',
  CreatedAt = 'CREATED_AT',
  CustomSelectField = 'CUSTOM_SELECT_FIELD',
  DiscoverystateLastUpdatedAt = 'DISCOVERYSTATE_LAST_UPDATED_AT',
  ExternalLink = 'EXTERNAL_LINK',
  FeedbackDate = 'FEEDBACK_DATE',
  FeedbackNumber = 'FEEDBACK_NUMBER',
  FieldBoolean = 'FIELD_BOOLEAN',
  FieldNumeric = 'FIELD_NUMERIC',
  FieldString = 'FIELD_STRING',
  Github = 'GITHUB',
  Gitlab = 'GITLAB',
  Jira = 'JIRA',
  Linear = 'LINEAR',
  Name = 'NAME',
  OrganizationGroup = 'ORGANIZATION_GROUP',
  Parent = 'PARENT',
  Projects = 'PROJECTS',
  State = 'STATE',
  Tag = 'TAG',
  Trello = 'TRELLO',
  UpdatedAt = 'UPDATED_AT',
  Usergroup = 'USERGROUP'
}

export enum Sort_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SetRoadmapProjectDisplayableAttribute = {
  set?: InputMaybe<Array<Roadmap_Project_Display_Attribute>>;
};

export type SetRoadmapViewDisplayableAttribute = {
  set?: InputMaybe<Array<Roadmap_View_Display_Attribute>>;
};

export type SetScoreExcludeDiscoverystateTypes = {
  set: Array<Discoverystate_Type>;
};

export type Submessage = {
  __typename: 'Submessage';
  attachments?: Maybe<Array<Attachment>>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integration_id?: Maybe<Scalars['String']>;
  message: Message;
  receivedAt?: Maybe<Scalars['DateTime']>;
  submitter: Person;
  type: Message_Type;
  updatedAt: Scalars['DateTime'];
};

export enum SubmessageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ReceivedAtAsc = 'receivedAt_ASC',
  ReceivedAtDesc = 'receivedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type Subscription = {
  __typename: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
  acceptedSuggestionCount: Scalars['Int'];
  apiToken?: Maybe<ApiTokenSubscriptionPayload>;
  chunk?: Maybe<ChunkSubscriptionPayload>;
  discovery?: Maybe<DiscoverySubscriptionPayload>;
  discoverySummaryStream?: Maybe<DiscoverySummaryStreamSubscriptionPayload>;
  discoveryfieldSyncState?: Maybe<DiscoveryfieldSyncStateSubscriptionPayload>;
  discoverystate?: Maybe<DiscoverystateSubscriptionPayload>;
  me: Account;
  message?: Maybe<MessageSubscriptionPayload>;
  messageSummaryStream?: Maybe<MessageSummaryStreamSubscriptionPayload>;
  notifications?: Maybe<Notification>;
  project?: Maybe<ProjectSubscriptionPayload>;
  roadmapProject?: Maybe<RoadmapProjectSubscriptionPayload>;
  roadmapProjectCustomField?: Maybe<RoadmapProjectCustomFieldSubscriptionPayload>;
  roadmapProjectCustomFieldOption?: Maybe<RoadmapProjectCustomFieldOptionSubscriptionPayload>;
  roadmapView?: Maybe<RoadmapViewSubscriptionPayload>;
  suggestedChunk?: Maybe<SuggestedChunkSubscriptionPayload>;
  suggestedDiscovery?: Maybe<SuggestedDiscoverySubscriptionPayload>;
  suggestedTheme?: Maybe<SuggestedThemeSubscriptionPayload>;
  tag?: Maybe<TagSubscriptionPayload>;
};


export type SubscriptionApiTokenArgs = {
  mutationIn?: InputMaybe<Array<SubscriptionMutationType>>;
  sourceIn: Array<Token_Source>;
};


export type SubscriptionChunkArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
  where: ChunkWhereInput;
};


export type SubscriptionDiscoveryArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
  where?: InputMaybe<DiscoveryWhereInput>;
};


export type SubscriptionDiscoverySummaryStreamArgs = {
  where: DiscoveryWhereUniqueInput;
};


export type SubscriptionDiscoveryfieldSyncStateArgs = {
  where?: InputMaybe<DiscoveryfieldWhereInput>;
};


export type SubscriptionDiscoverystateArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};


export type SubscriptionMessageArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
  where: MessageWhereInput;
};


export type SubscriptionMessageSummaryStreamArgs = {
  where: MessageWhereUniqueInput;
};


export type SubscriptionRoadmapProjectArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};


export type SubscriptionRoadmapProjectCustomFieldArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};


export type SubscriptionRoadmapProjectCustomFieldOptionArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};


export type SubscriptionRoadmapViewArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};


export type SubscriptionSuggestedChunkArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
  where?: InputMaybe<SuggestedChunkWhereInput>;
};


export type SubscriptionSuggestedDiscoveryArgs = {
  mutation_in: Array<SubscriptionMutationType>;
  where?: InputMaybe<SuggestedDiscoveryWhereInput>;
};


export type SubscriptionSuggestedThemeArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
  where?: InputMaybe<SuggestedThemeWhereInput>;
};


export type SubscriptionTagArgs = {
  mutation_in?: InputMaybe<Array<SubscriptionMutationType>>;
};

export enum SubscriptionMutationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export type SuggestedChunk = {
  __typename: 'SuggestedChunk';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integrityHash: Scalars['String'];
  sourceMessage: Message;
  suggestedChunksOnSuggestedDiscovery: Array<SuggestedChunksOnSuggestedDiscovery>;
  suggestedThemes: Array<SuggestedTheme>;
  textContent: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type SuggestedChunkSuggestedChunksOnSuggestedDiscoveryArgs = {
  orderBy?: InputMaybe<SuggestedChunksOnSuggestedDiscoveryOrderByInput>;
};

export enum SuggestedChunkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type SuggestedChunkSubscriptionPayload = {
  __typename: 'SuggestedChunkSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<SuggestedChunk>;
};

export type SuggestedChunkWhereInput = {
  discoveryId?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
  suggestedDiscoveryId?: InputMaybe<Scalars['String']>;
  suggestedThemeId?: InputMaybe<Scalars['String']>;
};

export type SuggestedChunksOnSuggestedDiscovery = {
  __typename: 'SuggestedChunksOnSuggestedDiscovery';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  relevanceScore: Scalars['Float'];
  suggestedChunk: SuggestedChunk;
  suggestedDiscovery: SuggestedDiscovery;
};

export enum SuggestedChunksOnSuggestedDiscoveryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RelevanceScoreAsc = 'relevanceScore_ASC',
  RelevanceScoreDesc = 'relevanceScore_DESC'
}

export type SuggestedDiscovery = {
  __typename: 'SuggestedDiscovery';
  createdAt: Scalars['DateTime'];
  discovery?: Maybe<Discovery>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  suggestedChunksCount: Scalars['Int'];
  suggestedChunksOnSuggestedDiscovery: Array<SuggestedChunksOnSuggestedDiscovery>;
  suggestedDiscoveryKind?: Maybe<SuggestedDiscoveryKind>;
  updatedAt: Scalars['DateTime'];
};


export type SuggestedDiscoverySuggestedChunksOnSuggestedDiscoveryArgs = {
  orderBy?: InputMaybe<SuggestedChunksOnSuggestedDiscoveryOrderByInput>;
  take?: InputMaybe<Scalars['Int']>;
};

export enum SuggestedDiscoveryKind {
  Persisted = 'PERSISTED',
  Suggested = 'SUGGESTED'
}

export enum SuggestedDiscoveryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type SuggestedDiscoverySubscriptionPayload = {
  __typename: 'SuggestedDiscoverySubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<SuggestedDiscovery>;
};

export type SuggestedDiscoveryWhereCountInput = {
  suggestedDiscoveryKind?: InputMaybe<SuggestedDiscoveryKind>;
};

export type SuggestedDiscoveryWhereInput = {
  discoveryId?: InputMaybe<Scalars['String']>;
  suggestedDiscoveryKind?: InputMaybe<SuggestedDiscoveryKind>;
};

export type SuggestedTheme = {
  __typename: 'SuggestedTheme';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messages: Array<Message>;
  name: Scalars['String'];
  slug: Scalars['String'];
  suggestedChunks: Array<SuggestedChunk>;
  updatedAt: Scalars['DateTime'];
};


export type SuggestedThemeMessagesArgs = {
  orderBy?: InputMaybe<MessageOrderByInput>;
};


export type SuggestedThemeSuggestedChunksArgs = {
  orderBy?: InputMaybe<SuggestedChunkOrderByInput>;
};

export enum SuggestedThemeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type SuggestedThemeSubscriptionPayload = {
  __typename: 'SuggestedThemeSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<SuggestedTheme>;
};

export type SuggestedThemeWhereInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum SyncConnectionState {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  NotFound = 'NOT_FOUND',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED'
}

export type SyncIdentifier = {
  __typename: 'SyncIdentifier';
  appliesTo: Attribute_Applies_To;
  createdAt: Scalars['DateTime'];
  nativeOw_avatarUrl?: Maybe<Prop_Ow_Rule>;
  nativeOw_company?: Maybe<Prop_Ow_Rule>;
  nativeOw_details?: Maybe<Prop_Ow_Rule>;
  nativeOw_email?: Maybe<Prop_Ow_Rule>;
  nativeOw_name?: Maybe<Prop_Ow_Rule>;
  nativeOw_phone?: Maybe<Prop_Ow_Rule>;
  streamName: Scalars['String'];
  uidKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuidIndex: Scalars['String'];
};

export type SyncIdentifierCreateInput = {
  appliesTo: Attribute_Applies_To;
  nativeOw_avatarUrl?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_company?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_details?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_email?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_name?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_phone?: InputMaybe<Prop_Ow_Rule>;
  streamName: Scalars['String'];
  uidKey?: InputMaybe<Scalars['String']>;
};

export type SyncIdentifierUpdateDataInput = {
  nativeOw_avatarUrl?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_company?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_details?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_email?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_name?: InputMaybe<Prop_Ow_Rule>;
  nativeOw_phone?: InputMaybe<Prop_Ow_Rule>;
  uidKey?: InputMaybe<Scalars['String']>;
};

export type SyncIdentifierUpdateManyInput = {
  upsert?: InputMaybe<Array<SyncIdentifierUpsertWithWhereUniqueNestedInput>>;
};

export type SyncIdentifierUpsertWithWhereUniqueNestedInput = {
  update?: InputMaybe<SyncIdentifierUpdateDataInput>;
  where: SyncIdentifierWhereUniqueInput;
};

export type SyncIdentifierWhereUniqueInput = {
  streamName: Scalars['String'];
};

export enum Template_Form_Type {
  Input = 'INPUT',
  Multiselect = 'MULTISELECT',
  Select = 'SELECT',
  Textarea = 'TEXTAREA'
}

export enum Token_Source {
  ChromeWidget = 'CHROME_WIDGET',
  Figma = 'FIGMA',
  FreshdeskToken = 'FRESHDESK_TOKEN',
  Github = 'GITHUB',
  Gitlab = 'GITLAB',
  Hubspot = 'HUBSPOT',
  IntercomWidget = 'INTERCOM_WIDGET',
  Jira = 'JIRA',
  Linear = 'LINEAR',
  Mail = 'MAIL',
  Salesforce = 'SALESFORCE',
  Slack = 'SLACK',
  Trello = 'TRELLO',
  WidgetFeedbackCollector = 'WIDGET_FEEDBACK_COLLECTOR',
  Zapier = 'ZAPIER',
  Zendesk = 'ZENDESK'
}

export type Tag = {
  __typename: 'Tag';
  color: Color;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  finishedNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startedNumber?: Maybe<Scalars['Int']>;
  tagged_discoveries: Array<Discovery>;
  unstartedNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type TagConnectManyInput = {
  connect: Array<TagWhereUniqueInput>;
};

export type TagCreateInput = {
  color: Color;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tagged_discoveries?: InputMaybe<DiscoveryCreateManyWithoutTagsInput>;
};

export type TagCreateManyInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  create?: InputMaybe<Array<TagCreateInput>>;
};

export type TagCreateManyWithoutTagged_DiscoveriesInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  create?: InputMaybe<Array<TagCreateWithoutTagged_DiscoveriesInput>>;
};

export type TagCreateWithoutTagged_DiscoveriesInput = {
  color: Color;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum TagOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TagSubscriptionPayload = {
  __typename: 'TagSubscriptionPayload';
  mutation?: Maybe<SubscriptionMutationType>;
  node?: Maybe<Tag>;
};

export type TagUpdateDataInput = {
  color?: InputMaybe<Color>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tagged_discoveries?: InputMaybe<DiscoveryUpdateManyWithoutTagsInput>;
};

export type TagUpdateManyInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
};

export type TagUpdateManyWithoutTagged_DiscoveriesInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
};

export type TagWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TagWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TeammateRole = {
  __typename: 'TeammateRole';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invites?: Maybe<Array<Maybe<Invite>>>;
  manageOwnComment?: Maybe<Scalars['Boolean']>;
  manageOwnFeedback?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  persons?: Maybe<Array<Maybe<Person>>>;
  planRight: Role_Type;
  position: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  viewCustomerSection?: Maybe<Scalars['Boolean']>;
  viewInboxSection?: Maybe<Scalars['Boolean']>;
  viewProductSection?: Maybe<Scalars['Boolean']>;
  viewRoadmapSection?: Maybe<Scalars['Boolean']>;
};

export type TeammateRoleConnectInput = {
  connect?: InputMaybe<TeammateRoleWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type TeammateRoleConnectOnlyInput = {
  connect?: InputMaybe<TeammateRoleWhereUniqueInput>;
};

export type TeammateRoleCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  manageOwnComment?: InputMaybe<Scalars['Boolean']>;
  manageOwnFeedback?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  persons?: InputMaybe<PersonUpdateManyInput>;
  planRight: Role_Type;
  position?: InputMaybe<Scalars['Int']>;
  viewCustomerSection?: InputMaybe<Scalars['Boolean']>;
  viewInboxSection?: InputMaybe<Scalars['Boolean']>;
  viewProductSection?: InputMaybe<Scalars['Boolean']>;
  viewRoadmapSection?: InputMaybe<Scalars['Boolean']>;
};

export enum TeammateRoleOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlanRightAsc = 'planRight_ASC',
  PlanRightDesc = 'planRight_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TeammateRoleUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  manageOwnComment?: InputMaybe<Scalars['Boolean']>;
  manageOwnFeedback?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  persons?: InputMaybe<PersonUpdateManyInput>;
  planRight?: InputMaybe<Role_Type>;
  position?: InputMaybe<Scalars['Int']>;
  viewCustomerSection?: InputMaybe<Scalars['Boolean']>;
  viewInboxSection?: InputMaybe<Scalars['Boolean']>;
  viewProductSection?: InputMaybe<Scalars['Boolean']>;
  viewRoadmapSection?: InputMaybe<Scalars['Boolean']>;
};

export type TeammateRoleWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TeammateUpdatePayload = {
  seatType?: InputMaybe<Seat_Type>;
};

export type TextSelection = {
  __typename: 'TextSelection';
  appliesTo: Offset_Applies_To;
  chunk: Chunk;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fullSelection?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  length: Scalars['Float'];
  moreOffsets?: Maybe<Array<TextSelectionMoreOffset>>;
  offset_start: Scalars['Float'];
  subMessageNumber?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type TextSelectionCreateInput = {
  appliesTo?: InputMaybe<Offset_Applies_To>;
  content: Scalars['String'];
  fullSelection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  length: Scalars['Int'];
  moreOffsets?: InputMaybe<TextSelectionMoreOffsetCreateManyInput>;
  offset_start: Scalars['Int'];
  subMessageNumber?: InputMaybe<Scalars['Int']>;
};

export type TextSelectionCreateManyInput = {
  create?: InputMaybe<Array<TextSelectionCreateInput>>;
};

export type TextSelectionMoreOffset = {
  __typename: 'TextSelectionMoreOffset';
  id: Scalars['ID'];
  length: Scalars['Float'];
  offsetStart: Scalars['Float'];
  subMessageNumber: Scalars['Float'];
};

export type TextSelectionMoreOffsetCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  length: Scalars['Int'];
  offsetStart: Scalars['Int'];
  subMessageNumber: Scalars['Int'];
};

export type TextSelectionMoreOffsetCreateManyInput = {
  create?: InputMaybe<Array<TextSelectionMoreOffsetCreateInput>>;
};

export type TextSelectionUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['Int']>;
  offset_start?: InputMaybe<Scalars['Int']>;
};

export type TextSelectionUpdateManyInput = {
  create?: InputMaybe<Array<TextSelectionCreateInput>>;
  delete?: InputMaybe<Array<TextSelectionWhereUniqueInput>>;
};

export type TextSelectionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TokenTrigger = {
  __typename: 'TokenTrigger';
  apiToken: ApiToken;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  triggerId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TokenTriggerCreateWithoutApiTokenInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  triggerId: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};

export type TokenTriggerUpdateOneWithoutApiTokenInput = {
  upsert?: InputMaybe<TokenTriggerUpsertWithoutApiTokenInput>;
};

export type TokenTriggerUpdateWithoutApiTokenDataInput = {
  name?: InputMaybe<Scalars['String']>;
  triggerId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type TokenTriggerUpsertWithoutApiTokenInput = {
  create?: InputMaybe<TokenTriggerCreateWithoutApiTokenInput>;
  update?: InputMaybe<TokenTriggerUpdateWithoutApiTokenDataInput>;
};

export type TokenTriggerWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TrelloCard = {
  __typename: 'TrelloCard';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  trelloBadgesAttachments?: Maybe<Scalars['Float']>;
  trelloBadgesComments?: Maybe<Scalars['Float']>;
  trelloBoardId: Scalars['String'];
  trelloBoardName: Scalars['String'];
  trelloClose: Scalars['Boolean'];
  trelloDesc?: Maybe<Scalars['String']>;
  trelloDue?: Maybe<Scalars['DateTime']>;
  trelloId: Scalars['String'];
  trelloIdShort?: Maybe<Scalars['Float']>;
  trelloLabels?: Maybe<Scalars['JSON']>;
  trelloListId: Scalars['String'];
  trelloListName: Scalars['String'];
  trelloListPosition: Scalars['Float'];
  trelloName: Scalars['String'];
  trelloPos?: Maybe<Scalars['Float']>;
  trelloShortLink?: Maybe<Scalars['String']>;
  trelloShortUrl?: Maybe<Scalars['String']>;
  trelloUrl: Scalars['String'];
  trelloWebhookId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TrelloCardCreateManyWithoutDiscoveryInput = {
  connect?: InputMaybe<Array<TrelloCardWhereUniqueInput>>;
  create?: InputMaybe<Array<TrelloCardCreateWithoutDiscoveryInput>>;
};

export type TrelloCardCreateWithOptionsInput = {
  copyAttachments?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<TrelloCardCreateWithoutDiscoveryInput>;
};

export type TrelloCardCreateWithoutDiscoveryInput = {
  id?: InputMaybe<Scalars['ID']>;
  trelloBadgesAttachments?: InputMaybe<Scalars['Int']>;
  trelloBadgesComments?: InputMaybe<Scalars['Int']>;
  trelloBoardId: Scalars['String'];
  trelloBoardName: Scalars['String'];
  trelloClose: Scalars['Boolean'];
  trelloDesc?: InputMaybe<Scalars['String']>;
  trelloDue?: InputMaybe<Scalars['DateTime']>;
  trelloId: Scalars['ID'];
  trelloIdShort?: InputMaybe<Scalars['Int']>;
  trelloLabels?: InputMaybe<Scalars['JSON']>;
  trelloListId: Scalars['String'];
  trelloListName: Scalars['String'];
  trelloListPosition: Scalars['Float'];
  trelloName: Scalars['String'];
  trelloPos?: InputMaybe<Scalars['Float']>;
  trelloShortLink?: InputMaybe<Scalars['String']>;
  trelloShortUrl?: InputMaybe<Scalars['String']>;
  trelloUrl: Scalars['String'];
  trelloWebhookId?: InputMaybe<Scalars['String']>;
};

export enum TrelloCardOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type TrelloCardUpdateManyWithOptionsInput = {
  create?: InputMaybe<TrelloCardCreateWithOptionsInput>;
  delete?: InputMaybe<TrelloCardWhereUniqueInput>;
};

export type TrelloCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Unit_Position {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type Usergroup = {
  __typename: 'Usergroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  persons_count: Scalars['Float'];
  tagged_persons: Array<Person>;
  updatedAt: Scalars['DateTime'];
};

export type UsergroupCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tagged_persons?: InputMaybe<PersonCreateManyWithoutUsergroupsInput>;
};

export type UsergroupCreateManyInput = {
  connect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
};

export type UsergroupCreateManyWithoutTagged_PersonsInput = {
  connect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
};

export enum UsergroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type UsergroupUpdateDataInput = {
  name?: InputMaybe<Scalars['String']>;
  tagged_persons?: InputMaybe<PersonUpdateManyWithoutUsergroupsInput>;
};

export type UsergroupUpdateManyInput = {
  connect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
};

export type UsergroupUpdateManyWithoutTagged_PersonsInput = {
  connect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<UsergroupWhereUniqueInput>>;
};

export type UsergroupWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type UsergroupWhereUniqueInput = {
  id: Scalars['ID'];
};

export type VirtualCompanyAsRequester = {
  __typename: 'VirtualCompanyAsRequester';
  externalUid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  source: Channel;
};

export type VirtualCompanyCreateInput = {
  integrationElementId: Scalars['String'];
  integrationSource: Channel;
};

export type VirtualContact = {
  __typename: 'VirtualContact';
  avatarName?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  externalUid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationId: Scalars['String'];
  name: Scalars['String'];
  source: Channel;
};

export type VirtualPersonCreateInput = {
  integrationElementId: Scalars['String'];
  integrationSource: Channel;
};

export type Webhook = {
  __typename: 'Webhook';
  createdAt: Scalars['DateTime'];
  creator?: Maybe<Person>;
  events: Array<WebhookEvent>;
  hmacSecretKey?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organization?: Maybe<Project>;
  privateApp: PrivateApp;
  status: WebhookStatus;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type WebhookCreateInput = {
  events: Array<WebhookEvent>;
  hmacSecretKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  url: Scalars['String'];
};

export enum WebhookEvent {
  ComponentCreated = 'COMPONENT_CREATED',
  ComponentUpdated = 'COMPONENT_UPDATED',
  DiscoveryCreated = 'DISCOVERY_CREATED',
  DiscoveryUpdated = 'DISCOVERY_UPDATED',
  FeedbackCreated = 'FEEDBACK_CREATED',
  FeedbackUpdated = 'FEEDBACK_UPDATED',
  MessageCreated = 'MESSAGE_CREATED',
  MessageUpdated = 'MESSAGE_UPDATED'
}

export enum WebhookStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  TooManyErrors = 'TOO_MANY_ERRORS'
}

export type WidgetFeedbackCollector = {
  __typename: 'WidgetFeedbackCollector';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  launcherHorizontalOffsetUnit: Css_Position_Unit;
  launcherHorizontalOffsetValue: Scalars['Float'];
  launcherStyle: Hfc_Launcher_Style;
  launcherText?: Maybe<Scalars['String']>;
  launcherVerticalOffsetUnit: Css_Position_Unit;
  launcherVerticalOffsetValue: Scalars['Float'];
  messageTemplate: MessageTemplate;
  primaryColor: Scalars['String'];
  privacy: Hfc_Privacy;
  secondaryColor: Scalars['String'];
  title: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  widgetInstallType: Hfc_Widget_Install_Type;
  widgetOpenStyle: Hfc_Widget_Open_Style;
  widgetPosition: Hfc_Widget_Position;
};

export type WidgetFeedbackCollectorCreateInput = {
  launcherHorizontalOffsetUnit?: InputMaybe<Css_Position_Unit>;
  launcherHorizontalOffsetValue?: InputMaybe<Scalars['Float']>;
  launcherStyle?: InputMaybe<Hfc_Launcher_Style>;
  launcherText?: InputMaybe<Scalars['String']>;
  launcherVerticalOffsetUnit?: InputMaybe<Css_Position_Unit>;
  launcherVerticalOffsetValue?: InputMaybe<Scalars['Float']>;
  messageTemplate: MessageTemplateCreateOneInput;
  primaryColor: Scalars['String'];
  privacy: Hfc_Privacy;
  secondaryColor: Scalars['String'];
  title: Scalars['String'];
  widgetInstallType?: InputMaybe<Hfc_Widget_Install_Type>;
  widgetOpenStyle?: InputMaybe<Hfc_Widget_Open_Style>;
  widgetPosition?: InputMaybe<Hfc_Widget_Position>;
};

export enum WidgetFeedbackCollectorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type WidgetFeedbackCollectorUpdateDataInput = {
  apiToken?: InputMaybe<ApiTokenCreateOneInput>;
  launcherHorizontalOffsetUnit?: InputMaybe<Css_Position_Unit>;
  launcherHorizontalOffsetValue?: InputMaybe<Scalars['Float']>;
  launcherStyle?: InputMaybe<Hfc_Launcher_Style>;
  launcherText?: InputMaybe<Scalars['String']>;
  launcherVerticalOffsetUnit?: InputMaybe<Css_Position_Unit>;
  launcherVerticalOffsetValue?: InputMaybe<Scalars['Float']>;
  messageTemplate?: InputMaybe<MessageTemplateCreateOneInput>;
  primaryColor?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<Hfc_Privacy>;
  secondaryColor?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  widgetInstallType?: InputMaybe<Hfc_Widget_Install_Type>;
  widgetOpenStyle?: InputMaybe<Hfc_Widget_Open_Style>;
  widgetPosition?: InputMaybe<Hfc_Widget_Position>;
};

export type WidgetFeedbackCollectorWhereInput = {
  title?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type WidgetFeedbackCollectorWhereUniqueInput = {
  id: Scalars['ID'];
};
