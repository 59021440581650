import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Color } from "@harvestr-client/shared/model/api";
import {
    CollaboratorInlineData,
    HxMenuEl,
    imageTypes
} from "@harvestr-client/shared/model/app";
import { hxColorHexMap } from "@harvestr-client/shared/ng/util-display-converter";
import {
    searchHelper,
    SubSink
} from "@harvestr-client/shared/shared/util-helper";
import { Editor, EditorOptions, Extension } from "@tiptap/core";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { Color as ColorCfg } from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import { Underline } from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { SuggestionOptions } from "@tiptap/suggestion";
import css from "highlight.js/lib/languages/css";
import js from "highlight.js/lib/languages/javascript";
import ts from "highlight.js/lib/languages/typescript";
import html from "highlight.js/lib/languages/xml";
import { capitalize } from "lodash";
import { lowlight } from "lowlight/lib/core";
import { AngularRenderer } from "ngx-tiptap";
import { debounceTime, Subject, tap } from "rxjs";
import tippy, { Instance, Props } from "tippy.js";
import {
    MentionAsTagAttr,
    MentionContainerComponent
} from "../mention-container/mention-container.component";
import { SlashcommandsContainerComponent } from "../slashcommands-container/slashcommands-container.component";
import {
    buildSlashCommandsMenuItemsList,
    EditorSlashCommandElData
} from "../slashcommands-container/slashcommands.plugin";

lowlight.registerLanguage("html", html);
lowlight.registerLanguage("css", css);
lowlight.registerLanguage("js", js);
lowlight.registerLanguage("ts", ts);

const colorSelectionEls = [
    Color.Grey,
    Color.Blue,
    Color.Purple,
    Color.Pink,
    Color.Red,
    Color.Orange,
    Color.Green
].map(color => {
    const el: HxMenuEl<{ name: Color; hex: string }> = {
        type: "DEFAULT",
        id: color,
        icon: {
            name: "bullet",
            color: color
        },
        value: capitalize(color.toLowerCase()),
        el: { name: color, hex: hxColorHexMap[color] }
    };
    return el;
});

const TypographyConfig = Typography.configure({
    copyright: false,
    registeredTrademark: false,
    trademark: false,
    servicemark: false,
    laquo: false,
    raquo: false,
    multiplication: false,
    superscriptTwo: false,
    superscriptThree: false,
    oneHalf: false,
    oneQuarter: false,
    threeQuarters: false
});

const LinkConfigure = Link.configure({
    openOnClick: false
});
const mentionHtmlTagClass = "mention-inline-tag";

@Component({
    selector: "hx-wysiwyg",
    templateUrl: "./hx-wysiwyg.component.html",
    styleUrls: ["./hx-wysiwyg.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HxWysiwygComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    @Input() contentControl: FormControl<string | null> | undefined;
    @Input() toolbarPosition: "TOP" | "BUBBLE" | "NONE" = "TOP";
    @Input() withImageUpload = false;
    @Input() mode: "FULL" | "SIMPLE" | "COMMENT" | "HUBSPOT" = "FULL";
    @Input() stickyTop = 0;
    @Input() placeholder = "";
    @Input() focusOnCreate = false;
    @Input() disableImageUploadExternalWindow = false;
    @Input() collaboratorListEls:
        | HxMenuEl<CollaboratorInlineData>[]
        | undefined;
    // @Input() value = "";
    // @Output() valueChange = new EventEmitter<string>();
    @Output() fileUploaded = new EventEmitter<Event>();
    @HostBinding("class.editor-container") editorClass = true;

    colorSelectionEls = colorSelectionEls;
    editor: Editor | undefined;

    extensions: EditorOptions["extensions"] = [];

    isLoading = false;

    readonly acceptedImageUploadMimeTypes = imageTypes;
    private _activeToolbarButtonsChecker$$ = new Subject();

    constructor(
        private cd: ChangeDetectorRef,
        private injector: Injector
    ) {
        const activeToolbarButtonsCheckerDebouncer$ =
            this._activeToolbarButtonsChecker$$.pipe(
                debounceTime(100),
                tap(_ => {
                    this.cd.markForCheck();
                })
            );
        this.subs.add(activeToolbarButtonsCheckerDebouncer$.subscribe());
    }

    ngOnInit(): void {
        const CustomKeyboardShortcuts = Extension.create({
            addKeyboardShortcuts() {
                return {
                    "Mod-Enter": () => {
                        // ? prevents linebreak on mod+enter content save
                        this.editor.commands.blur();
                        return true;
                    }
                };
            }
        });
        switch (this.mode) {
            case "SIMPLE":
                this.extensions = [
                    StarterKit.configure({
                        blockquote: false,
                        bulletList: false,
                        code: false,
                        codeBlock: false,
                        heading: false,
                        listItem: false,
                        orderedList: false
                    }),
                    TypographyConfig,
                    Underline,
                    CustomKeyboardShortcuts
                ];
                break;
            case "COMMENT":
                this.extensions = [
                    StarterKit.configure({
                        heading: false,
                        codeBlock: false
                    }),
                    Image,
                    Underline,
                    TextStyle,
                    TypographyConfig,
                    ColorCfg.configure({
                        types: ["textStyle"]
                    }),
                    CodeBlockLowlight.configure({
                        lowlight,
                        defaultLanguage: "plaintext",
                        languageClassPrefix: "language-"
                    }),
                    LinkConfigure,
                    Mention.configure({
                        HTMLAttributes: {
                            class: mentionHtmlTagClass
                        },
                        suggestion: this.handleSuggestion(),
                        renderLabel({ options, node }) {
                            // * when mention is selected: render mention as tag
                            // console.log("renderLabel:", options, node);
                            const attributes:
                                | Partial<MentionAsTagAttr>
                                | undefined = node.attrs;
                            return `${options.suggestion.char}${
                                attributes?.label ?? attributes?.id
                            }`;
                        }
                    }),
                    CustomKeyboardShortcuts
                ];
                break;
            case "HUBSPOT":
                this.extensions = [
                    StarterKit.configure({
                        codeBlock: false,
                        heading: false
                    }),
                    HorizontalRule,
                    TaskList,
                    TaskItem.configure({
                        nested: true
                    }),
                    Image,
                    Underline,
                    TextStyle,
                    TypographyConfig,
                    ColorCfg.configure({
                        types: ["textStyle"]
                    }),
                    Heading.configure({
                        levels: [1, 2, 3, 4]
                    }),
                    CodeBlockLowlight.configure({
                        lowlight,
                        defaultLanguage: "plaintext",
                        languageClassPrefix: "language-"
                    }),
                    LinkConfigure,
                    CustomKeyboardShortcuts
                ];
                break;
            default:
                this.extensions = [
                    StarterKit.configure({
                        codeBlock: false,
                        heading: false
                    }),
                    HorizontalRule,
                    TaskList,
                    TaskItem.configure({
                        nested: true
                    }),
                    Image,
                    Underline,
                    TextStyle,
                    TypographyConfig,
                    ColorCfg.configure({
                        types: ["textStyle"]
                    }),
                    Heading.configure({
                        levels: [1, 2, 3, 4]
                    }),
                    CodeBlockLowlight.configure({
                        lowlight,
                        defaultLanguage: "plaintext",
                        languageClassPrefix: "language-"
                    }),
                    LinkConfigure,
                    CustomKeyboardShortcuts
                    // TODO: discovery new layout
                    // Placeholder.configure({
                    //     placeholder: "Type / for commands",
                    //     considerAnyAsEmpty: true,
                    //     showOnlyCurrent: true,
                    //     includeChildren: true
                    // })
                    // SlashCommandsCustomExtension.configure({
                    //     HTMLAttributes: {
                    //         class: mentionHtmlTagClass
                    //     },
                    //     suggestion: this.handleSlashcommands()
                    // })
                ];
                break;
        }
        if (this.placeholder) {
            this.extensions.push(
                Placeholder.configure({
                    placeholder: this.placeholder
                })
            );
        }
        this.editor = new Editor({
            autofocus: this.focusOnCreate ? "end" : false,
            extensions: this.extensions,
            onTransaction: () => {
                this._activeToolbarButtonsChecker$$.next(true);
            }
        });
    }

    handleSlashcommands(): Omit<
        SuggestionOptions<HxMenuEl<EditorSlashCommandElData>>,
        "editor"
    > {
        return {
            items: buildSlashCommandsMenuItemsList,
            render: () => {
                let renderer: AngularRenderer<
                    SlashcommandsContainerComponent,
                    SlashcommandsContainerComponent
                >;
                let popup: Instance<Props>[];
                return {
                    onStart: props => {
                        renderer = new AngularRenderer(
                            SlashcommandsContainerComponent,
                            this.injector,
                            { props }
                        );
                        renderer.updateProps({ props });
                        // * wait for initial content to be rendered before positionning menu
                        renderer.instance.init$$.subscribe(_ => {
                            popup = tippy("body", {
                                getReferenceClientRect:
                                    props.clientRect as () => DOMRect,
                                appendTo: () => document.body,
                                content: renderer.dom,
                                showOnCreate: true,
                                interactive: true,
                                trigger: "manual"
                            });
                        });
                    },
                    onUpdate(props) {
                        renderer.updateProps({ props });
                        renderer.instance.markForCheck();
                        popup[0].setProps({
                            getReferenceClientRect:
                                props.clientRect as () => DOMRect
                        });
                    },
                    onKeyDown(props) {
                        return renderer.instance.onKeyDown(props);
                    },
                    onExit() {
                        popup[0].destroy();
                        renderer.destroy();
                    }
                };
            }
        };
    }

    handleSuggestion(): Omit<SuggestionOptions<any>, "editor"> {
        return {
            items: this._buildMentionList.bind(this),
            render: () => {
                let renderer: AngularRenderer<
                    MentionContainerComponent,
                    MentionContainerComponent
                >;
                let popup: Instance<Props>[];
                return {
                    onStart: props => {
                        renderer = new AngularRenderer(
                            MentionContainerComponent,
                            this.injector,
                            { props }
                        );
                        renderer.updateProps({ props });
                        // * wait for initial content to be rendered before positionning menu
                        renderer.instance.init$$.subscribe(_ => {
                            popup = tippy("body", {
                                getReferenceClientRect:
                                    props.clientRect as () => DOMRect,
                                appendTo: () => document.body,
                                content: renderer.dom,
                                showOnCreate: true,
                                interactive: true,
                                trigger: "manual"
                            });
                        });
                    },
                    onUpdate(props) {
                        renderer.updateProps({ props });
                        renderer.instance.markForCheck();
                        popup[0].setProps({
                            getReferenceClientRect:
                                props.clientRect as () => DOMRect
                        });
                    },
                    onKeyDown(props) {
                        return renderer.instance.onKeyDown(props);
                    },
                    onExit() {
                        popup[0].destroy();
                        renderer.destroy();
                    }
                };
            }
        };
    }

    private _buildMentionList(props: { query: string; editor: Editor }) {
        const query = props.query;
        const regexp = searchHelper.getSearchRegex(query || "");
        // ? search query filter
        return (this.collaboratorListEls || [])
            .filter(val => {
                return searchHelper.filterByQuery({
                    regexp,
                    searchable: [val.value || "", val.el?.email || ""]
                });
            })
            .slice(0, 50);
    }

    onEditorColor(color?: Color) {
        if (!this.editor) {
            return;
        }
        if (color) {
            this.editor.chain().focus().setColor(hxColorHexMap[color]).run();
        } else {
            this.editor.chain().focus().setColor(hxColorHexMap.BLACK).run();
            this.editor.chain().focus().unsetColor().run();
        }
    }

    onEmbedImageLink() {
        if (!this.editor) {
            return;
        }
        // ex: https://source.unsplash.com/8xznAGy4HcY/800x400
        const url = window.prompt("URL");
        if (url) {
            this.editor.chain().focus().setImage({ src: url }).run();
        }
    }

    onHeaderSwitch(i: number) {
        if (!this.editor) {
            return;
        }
        this.editor
            .chain()
            .focus()
            .setHeading({ level: i as 1 | 2 | 3 })
            .run();
    }

    onHeaderCancel() {
        if (!this.editor) {
            return;
        }
        this.editor.chain().focus().setNode("paragraph").run();
        //this.editor.chain().focus().clearNodes().run();
    }

    setLink() {
        if (!this.editor) {
            return;
        }
        const previousUrl = this.editor.getAttributes("link")["href"];
        const url = window.prompt("URL", previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === "") {
            this.editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .unsetLink()
                .run();

            return;
        }

        // update link
        this.editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
    }

    ngOnDestroy(): void {
        if (this.editor) {
            this.editor.destroy();
        }
        this.subs.unsubscribe();
    }
}
